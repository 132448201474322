import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import {
  IAttachmentMedicalReport,
  IMedicalReport,
  IMedicalReportStatus,
  INextStatusMedicalReport,
  INoteMedicalReport} from '@interfaces/medical-report/medical-report.interface';
import { IMedicalCompanies } from '@interfaces/medical-report/medical-report-companies.interface';
import { IAudits } from '@interfaces/audits/audits.interface';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class MedicalReportService {
  constructor(private httpClient: HttpClient) {}

  getMedicalCompaniesCode(code: string) {
    return this.httpClient
    .get<IResponse<IMedicalCompanies>>(`${ROUTES_PUBLIC.MEDICAL_REPORT}/medical_companies/${code}`);
  }

  getMedicalCompanies() {
    return this.httpClient
    .get<IResponse<IMedicalCompanies[]>>(`${ROUTES_PUBLIC.MEDICAL_REPORT}/medical_companies`);
  }

  getMedicalCompaniesId(id: number) {
    return this.httpClient
    .get<IResponse<IMedicalCompanies>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/medical_companies/${id}`);
  }

  saveMedicalCompanies(data: IMedicalCompanies) {
    return this.httpClient
    .post<IResponse<IMedicalCompanies>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/medical_companies`, data);
  }

  updateMedicalCompanies(data: IMedicalCompanies) {
    const id = data.id;
    delete data.id;
    return this.httpClient
    .put<IResponse<IMedicalCompanies>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/medical_companies/${id}`, data);
  }

  removeMedicalCompanies(id: number) {
    return this.httpClient
    .delete<IResponse<IMedicalCompanies>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/medical_companies/${id}`);
  }

  getMedicalRecordStatus() {
    return this.httpClient
    .get<IResponse<IMedicalReportStatus[]>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/statuses`);
  }

  saveMedicalRecordStatus(data: IMedicalReportStatus) {
    delete data.id;
    return this.httpClient.post<IResponse<IMedicalReportStatus>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/statuses`, data);
  }

  updateMedicalStatus(data: IMedicalReportStatus) {
    const id = data.id;
    delete data.id;
    return this.httpClient
    .put<IResponse<IMedicalReportStatus>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/statuses/${id}`, data);
  }

  deleteMedicalStatus(id: number) {
    return this.httpClient
    .delete<IResponse<IAttachmentMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/statuses/${id}`);
  }

  saveMedicalReport(data: IMedicalReport) {
    delete data.id;
    return this.httpClient.post<IResponse<IMedicalReport>>(`${ROUTES_PUBLIC.MEDICAL_REPORT}/reports`, data);
  }

  updateMedicalReportStatus(data: INextStatusMedicalReport) {
    const id = data.id;
    delete data.id;
    return this.httpClient
    .post<IResponse<IMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/reports/change_state/${id}`, data);
  }

  updateMedicalReport(payload: Partial<IMedicalReport>, id: number): Observable<IResponse<IMedicalReport>> {
    const URL = `${ROUTES_PRIVATE.MEDICAL_REPORT}/reports/${id}`;
    return this.httpClient.patch<IResponse<IMedicalReport>>(URL, payload);
  }

  getMedicalRecordAdmin() {
    return this.httpClient
    .get<IResponse<IMedicalReport[]>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/reports`);
  }

  getMedicalRecordAdminId(id: number) {
    return this.httpClient
    .get<IResponse<IMedicalReport[]>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/reports/${id}`);
  }

  getMedicalRecordAdminAttachmentId(id: number) {
    return this.httpClient
    .get<IResponse<IAttachmentMedicalReport[]>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/report/attachments/${id}`);
  }

  getMedicalRecordAdminAttachment() {
    return this.httpClient
    .get<IResponse<IAttachmentMedicalReport[]>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/report/attachments`);
  }

  updateTagsReviewByQa(id: number, reviewByQa: boolean) {
    return this.httpClient
    .put<IResponse<IAttachmentMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/attachments/review_by_qa/${id}`,
    { reviewed_by_qa: reviewByQa });
  }

  updateTagsToReviewByQa(id: number, toReviewByQa: boolean) {
    return this.httpClient
    .put<IResponse<IAttachmentMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/attachments/to_review_by_qa/${id}`,
    { to_review_by_qa: toReviewByQa });
  }

  updateTagsToSent(id: number, selectedToSend: boolean) {
    return this.httpClient
    .put<IResponse<IAttachmentMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/attachments/to_send/${id}`,
    { selected_to_send: selectedToSend });
  }

  updateIsNotarize(id: number, notarize: boolean) {
    return this.httpClient
    .put<IResponse<IAttachmentMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/attachments/notarize/${id}`,
    { is_notarized: notarize });
  }

  saveAttachmentCreated(data: IAttachmentMedicalReport) {
    return this.httpClient
    .post<IResponse<IAttachmentMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/attachments`, data);
  }

  saveAttachmentCreatedMultiple(data: IAttachmentMedicalReport) {
    return this.httpClient
    .post<IResponse<IAttachmentMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/attachments/multiple`, data);
  }

  saveAttachmentNotarized(data: IAttachmentMedicalReport) {
    return this.httpClient
    .post<IResponse<IAttachmentMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/attachments`, data);
  }

  deleteAttachmentCreated(id: number) {
    return this.httpClient
    .delete<IResponse<IAttachmentMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/attachments/${id}`);
  }

  getPossibleStatesIdMedicalReport(id: number) {
    return this.httpClient
    .get<IResponse<IMedicalReportStatus[]>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/reports/possible_states/${id}`);
  }

  getHistoryIdMedicalReport(id: number) {
    return this.httpClient
    .get<IResponse<IAudits[]>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/reports/status_history/${id}`);
  }

  saveNoteMedicalReport(data: INoteMedicalReport) {
    const id = data.id;
    delete data.id;
    return this.httpClient
    .post<IResponse<INoteMedicalReport>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/reports/note/${id}`, data);
  }

  updateNoteMedicalReport(data: INoteMedicalReport) {
    const id = data.id;
    delete data.id;
    return this.httpClient
    .put<IResponse<INoteMedicalReport>>(`${ROUTES_PRIVATE.DEFAULT}/notes/${id}`, data);
  }

  getNoteIdMedicalReport(id: number) {
    return this.httpClient
    .get<IResponse<INoteMedicalReport[]>>(`${ROUTES_PRIVATE.MEDICAL_REPORT}/report/notes/${id}`);
  }

  deleteNoteIdMedicalReport(id: number) {
    return this.httpClient
    .delete<IResponse<INoteMedicalReport[]>>(`${ROUTES_PRIVATE.DEFAULT}/notes/${id}`);
  }

  getMedicalReportByCode(code: number = null): Observable<IResponse<IMedicalReport>> {
    const URL = `${ROUTES_PUBLIC.MEDICAL_REPORT}/reports/get_by_code`;
    return this.httpClient.get<IResponse<IMedicalReport>>(URL, {params: code ? {code} : null});
  }
}
