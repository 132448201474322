import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { UserApplicationsGetAction } from './user-applications-actions';
import { Injectable } from '@angular/core';
import { UserApplicationsService } from '@services/user/user-applications.service';
import { IUserApplications } from '@interfaces/user/user-applications.interface';

export interface UserApplicationsStateModel {
  list: IUserApplications[];
}

export const StateUserApplications = 'user_applications';

@State<UserApplicationsStateModel>({
  name: StateUserApplications,
  defaults: {
    list: []
  }
})
@Injectable()
export class UserApplicationsState {
  @Selector() static list(state: UserApplicationsStateModel) {
    return state.list;
  }

  constructor(private userApplicationsService: UserApplicationsService) {}

  @Action(UserApplicationsGetAction)
  UserApplicationsGet(ctx: StateContext<UserApplicationsStateModel>) {
    return this.userApplicationsService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }
}
