// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  origin_dev: null,
  url: 'https://jobsdev.transportationamerica.com',
  url_api: 'https://jobsdevapi.transportationamerica.com',
  // origin_dev: 'https://lsfbrow.jobs.test.me',
  // url: 'http://localhost:4200',
  // url_api: 'https://public.test',
  grant_type: 'password',
  client_id: '2',
  client_secret: 'ihxmAt97HuHWbxplXUQypCQn8aJRlHBXi0geVUeg',
  url_api_nacinality: 'https://restcountries.com',
  scope: '*'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
