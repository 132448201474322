import { IParameters, IParametersTypes } from '@interfaces/parameters.interface';

export class ParametersTempAddAction {
  static readonly type = '[ParametersTemp] Add';
  constructor(public data: IParameters) {}
}
export class ParametersTempUpdateAction {
  static readonly type = '[ParametersTemp] Update';
  constructor(public data: IParameters) {}
}
export class ParametersGetAllAction {
  static readonly type = '[ParametersTemp] List';
}
export class ParametersGetByIdAction {
  static readonly type = '[ParametersTemp] Show ById';
  constructor(public id: number) {}
}
export class ParametersTypeAddAction {
  static readonly type = '[ParametersType] Add';
  constructor(public data: IParametersTypes) {}
}
export class ParametersTypeUpdateAction {
  static readonly type = '[ParametersType] Update';
  constructor(public data: IParametersTypes) {}
}
export class ParametersTypeSetDataAction {
  static readonly type = '[ParametersType] Set Type Data';
  constructor(public data: IParametersTypes) {}
}
export class ParametersTypeGetAllAction {
  static readonly type = '[ParametersTemp] Type List';
}
export class ParametersTempSetShowDisabledAction {
  static readonly type = '[ShowDisabledPT] Show';
  constructor(public show: boolean) {}
}
export class ParametersTypeSetShowDisabledAction {
  static readonly type = '[ShowDisabledPType] Show';
  constructor(public show: boolean) {}
}
export class ClearCurrentParameter {
  static readonly type = '[ParametersTemp] Clear Current Parameter';
}

export class ParametersTypeGetTreeAction {
  static readonly type = '[ParametersTypeTree] List Types Tree';
}

export class ParametersKeysAllAction {
  static readonly type = '[ParametersKeys] List Parameters Keys';
}
export const ActionsToProcessParameters = {
  ParametersTempAddAction,
  ParametersTempUpdateAction,
  ParametersGetAllAction,
  ParametersGetByIdAction,
  ParametersTypeAddAction,
  ParametersTypeUpdateAction,
  ParametersTypeSetDataAction,
  ParametersTypeGetAllAction,
  ParametersTempSetShowDisabledAction,
  ParametersTypeSetShowDisabledAction,
  ClearCurrentParameter,
  ParametersTypeGetTreeAction,
  ParametersKeysAllAction
};
