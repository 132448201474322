import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BehaviorSubject, of } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IQuestionsTypes } from '@interfaces/questions-types.interface';
import {
  QuestionsTypesAddAction,
  QuestionsTypesEditAction,
  QuestionsTypesGetAction,
  QuestionsTypesRemoveAction,
  QuestionsTypesSetShowDisabledAction,
  QuestionsTypesUpdateAction, QuestionTypeGetTreeAction, LoadQuestionsTypeTree
} from './questions-types.actions';
import { QuestionsTypesService } from '@services/questions-types.service';

export interface QuestionsTypesStateModel {
  list: IQuestionsTypes[];
  show_disable: boolean;
  edit: IQuestionsTypes;
  list_types_tree: IQuestionsTypes[];
  selectedId: number;
}

export const StateQuestionsTypes = 'Questions_types';

@State<QuestionsTypesStateModel>({
  name: StateQuestionsTypes,
  defaults: {
    list: [],
    show_disable: false,
    edit: null,
    list_types_tree: [],
    selectedId: null
  }
})
@Injectable()
export class QuestionsTypesState {
  private selectedIdSubject = new BehaviorSubject<number | null>(null);
  @Selector() static list(state: QuestionsTypesStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: QuestionsTypesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: QuestionsTypesStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: QuestionsTypesStateModel) {
    return state.edit;
  }
  @Selector() static listQuestionsTypeTree(state: QuestionsTypesStateModel) {
    return state.list_types_tree;
  }
  constructor(private questionsTypesService: QuestionsTypesService) {}
  @Action(LoadQuestionsTypeTree)
  loadQuestionsTypeTree(ctx: StateContext<QuestionsTypesStateModel>, action: LoadQuestionsTypeTree) {
    ctx.patchState({
      selectedId: action.selectedId
    });
    this.selectedIdSubject.next(action.selectedId);
  }
  @Action(QuestionsTypesGetAction)
  QuestionsTypesGet(ctx: StateContext<QuestionsTypesStateModel>) {
    return this.questionsTypesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(QuestionsTypesEditAction)
  QuestionsTypesEdit(ctx: StateContext<QuestionsTypesStateModel>, { data }: QuestionsTypesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(QuestionsTypesAddAction)
  QuestionsTypesAdd(ctx: StateContext<QuestionsTypesStateModel>, { data }: QuestionsTypesAddAction) {
    return this.questionsTypesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(QuestionsTypesUpdateAction)
  QuestionsTypesUpdate(ctx: StateContext<QuestionsTypesStateModel>, { data }: QuestionsTypesUpdateAction) {
    return this.questionsTypesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(QuestionsTypesRemoveAction)
  QuestionsTypesRemove(ctx: StateContext<QuestionsTypesStateModel>, { id }: QuestionsTypesRemoveAction) {
    return this.questionsTypesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(QuestionsTypesSetShowDisabledAction)
  QuestionsTypesSetShowDisabled(
    ctx: StateContext<QuestionsTypesStateModel>,
    { show }: QuestionsTypesSetShowDisabledAction
  ) {
    ctx.patchState({
      show_disable: show
    });
  }

  @Action(QuestionTypeGetTreeAction)
  getTreeTemplateTypes(ctx: StateContext<QuestionsTypesStateModel>, { category }: QuestionTypeGetTreeAction) {
    return this.selectedIdSubject.pipe(
      filter(selectedId => {
        return selectedId !== null;
      }),
      switchMap(selectedId => {
        return this.questionsTypesService.getTree(category, selectedId).pipe(
          tap(result => {
            ctx.patchState({
              list_types_tree: result.data
            });
            this.selectedIdSubject.next(null);
            this.selectedIdSubject.complete();
          })
        );
      })
    );
  }
}
