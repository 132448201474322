import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { ICronJobsExecution } from '@interfaces/cron-jobs-execution.interface';

@Injectable({
  providedIn: 'root'
})
export class JobsExecutionService {

  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<ICronJobsExecution[]>>(`${ROUTES_PRIVATE.CRON_JOBS_EXECUTION}`);
  }


}
