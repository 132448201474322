import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { extensionsFiles } from '@data/extension-files';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function copyJson(data) {
  return JSON.parse(JSON.stringify(data));
}

export function fileIsSupportPreview(filename = ''): boolean {
  const filenameSplit = filename.split('.');
  const ext = filenameSplit.length > 1 ? filenameSplit[filenameSplit.length - 1] : null;
  const extSupport = ['jpg', 'jpeg', 'png', 'pdf'];
  return extSupport.includes(ext);
}

export function isRequired(control) {
  if (!control) {
    return false;
  }
  if (control.validator) {
    const validator = control.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  return false;
}

export function isPresent(obj): boolean {
  return obj !== undefined && obj !== null;
}

export function extensionIsSupportUpload(extension) {
  const result = Object.entries(extensionsFiles).map(([key, value]) => ({ key, value }));
  const idx = result.map(i => i.value).indexOf(extension);
  return idx >= 0;
}

export function extensionIsSupportUploadParams(extension, valid: string[]) {
  const result = Object.entries(valid).map(([key, value]) => ({ key, value }));
  const idx = result.map(i => i.value).indexOf(extension);
  return idx >= 0;
}

export function acceptedExtensionUploadUnique(ext) {
  return Object.entries(extensionsFiles).filter(i => i[1] === ext)[0][1];
}

export function acceptedExtensionUpload(ext: string, extLoad: string) {
  const result = Object.entries(extensionsFiles).find(([, value]) => value === ext && ext === extLoad);
  return result !== undefined;
}


export function isAdminEnv() {
  const hostname = location.hostname;
  const subdomainAdmin = 'admin';
  return (
    hostname.substring(0, 5) === subdomainAdmin ||
    (!environment.production && environment.origin_dev && environment.origin_dev?.substring(0, 5) === subdomainAdmin)
  );
}

export function getSubdomain() {
  const origin = environment.origin_dev ?? location.origin;
  const urlWithOutProtocol = origin.replace(/https?:\/\//, '');
  const [subdomain] = urlWithOutProtocol.split('.');
  return subdomain;
}

export function setDisabledAllControlsForm(form: FormGroup, disabled = true) {
  for (let i = 0; i < Object.keys(form.controls).length; i++) {
    const element = Object.keys(form.controls)[i];
    if (disabled) {
      form.get(element).disable();
    } else {
      form.get(element).enable();
    }
  }
  return form;
}

export function isDisabled(control: AbstractControl) {
  return control ? control.disabled : false;
}

export function phoneConflictValidator(otherControl: AbstractControl) {
  return (control: AbstractControl): ValidationErrors | null => {
    const phone = control.value;
    const emergencyPhone = otherControl.value;

    if (phone === emergencyPhone) {
      return { phoneConflict: true };
    }

    return null;
  };
}

export function bindAbstractControlWithUrl(
  {control, router, destroy, key}: { control: AbstractControl, router: Router, destroy: Subject<boolean>, key: string }
): void {
  control.valueChanges
    .pipe(takeUntil(destroy))
    .subscribe({
      next: (code: number): void => {
        router.navigate([], {
          queryParamsHandling: 'merge',
          queryParams: { [key]: code || null}
        }).then();
      }
    });
}

export function generateRandomPassword(min: number, max: number): string {
  const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numberChars = '0123456789';
  const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';
  const allChars: string = upperCaseChars + lowerCaseChars + numberChars + specialChars;

  let password = '';
  const length: number = Math.floor(Math.random() * (max - min + 1)) + min;

  for (let i = 0; i < length; i++) {
    const randomIndex: number = Math.floor(Math.random() * allChars.length);
    password += allChars[randomIndex];
  }

  return password;
}
