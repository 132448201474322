import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IUserSkills } from '@interfaces/user/user-skills.intercace';

@Injectable({ providedIn: 'root' })
export class UserSkillsService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IUserSkills[]>>(`${ROUTES_PUBLIC.USER_SKILLS}`);
  }

  save(data: IUserSkills) {
    return this.httpClient.post<IResponse<IUserSkills>>(`${ROUTES_PUBLIC.USER_SKILLS}`, data);
  }

  update(data: IUserSkills) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IUserSkills>>(`${ROUTES_PUBLIC.USER_SKILLS}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IUserSkills>>(`${ROUTES_PUBLIC.USER_SKILLS}/${id}`);
  }
}
