import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { isAdminEnv } from '@helpers/helper';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (isAdminEnv()) {
      this.router.navigate(['auth']);
    }
    return true;
  }
}
