import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ICompanyIncident } from '@interfaces/companies-incident';
import { CompaniesIncidentService } from '@services/companies-incident.service';
import {
  CompaniesIncidentAddAction,
  CompaniesIncidentEditAction,
  CompaniesIncidentGetAction,
  CompaniesIncidentQueryAction,
  CompaniesIncidentRemoveAction,
  CompaniesIncidentSetShowDisabledAction,
  CompaniesIncidentUpdateAction,
  DamagesIncidentGetAction,
  EmergencyIncidentGetAction
} from './companies-incident-actions';
import { IEmergencyIncident } from '@interfaces/emergency-incident.interface';
import { IDamageIncident } from '@interfaces/damage-incident.interface';

export interface CompaniesIncidentStateModel {
  listCompany: ICompanyIncident[];
  listEmergency: IEmergencyIncident[];
  listDamages: IDamageIncident[];
  edit: ICompanyIncident;
  show_disable: boolean;
}

export const CompaniesIncident = 'companies_incident';

@State<CompaniesIncidentStateModel>({
  name: CompaniesIncident,
  defaults: {
    listCompany: [],
    listEmergency: [],
    listDamages: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class CompaniesIncidentState {

  @Selector() static list(state: CompaniesIncidentStateModel) {
    return state.listCompany;
  }
  @Selector() static listEmergency(state: CompaniesIncidentStateModel) {
    return state.listEmergency;
  }
  @Selector() static listDamages(state: CompaniesIncidentStateModel) {
    return state.listDamages;
  }
  @Selector() static edit(state: CompaniesIncidentStateModel) {
    return state.edit;
  }
  @Selector() static showDisabled(state: CompaniesIncidentStateModel) {
    return state.show_disable;
  }

  constructor(private companiesIncidentService: CompaniesIncidentService) {}


  @Action(CompaniesIncidentGetAction)
  CompaniesListGet(ctx: StateContext<CompaniesIncidentStateModel>) {
    return this.companiesIncidentService.getListCompany().pipe(
      tap(result => {
        ctx.patchState({
          listCompany: result.data
        });
      })
    );
  }

  @Action(EmergencyIncidentGetAction)
  EmergencyListGet(ctx: StateContext<CompaniesIncidentStateModel>) {
    return this.companiesIncidentService.getListEmergency().pipe(
      tap(result => {
        ctx.patchState({
          listEmergency: result.data
        });
      })
    );
  }

  @Action(DamagesIncidentGetAction)
  DamageListGet(ctx: StateContext<CompaniesIncidentStateModel>) {
    return this.companiesIncidentService.getListDamages().pipe(
      tap(result => {
        ctx.patchState({
          listDamages: result.data
        });
      })
    );
  }

  @Action(CompaniesIncidentEditAction)
  AttachmentsTypesEdit(ctx: StateContext<CompaniesIncidentStateModel>, { data }: CompaniesIncidentEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(CompaniesIncidentAddAction)
  AttachmentsTypesAdd(ctx: StateContext<CompaniesIncidentStateModel>, { data }: CompaniesIncidentAddAction) {
    return this.companiesIncidentService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(CompaniesIncidentUpdateAction)
  AttachmentsTypesUpdate(ctx: StateContext<CompaniesIncidentStateModel>, { data }: CompaniesIncidentUpdateAction) {
    return this.companiesIncidentService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(CompaniesIncidentRemoveAction)
  AttachmentsTypesRemove(ctx: StateContext<CompaniesIncidentStateModel>, { id }: CompaniesIncidentRemoveAction) {
    return this.companiesIncidentService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(CompaniesIncidentSetShowDisabledAction)
  AttachmentsSetShowDisabled(
    ctx: StateContext<CompaniesIncidentStateModel>,
    { show }: CompaniesIncidentSetShowDisabledAction
  ) {
    ctx.patchState({
      show_disable: show
    });
  }

  @Action(CompaniesIncidentQueryAction)
  AttachmentsTypesGetQuery(ctx: StateContext<CompaniesIncidentStateModel>, { data }: CompaniesIncidentQueryAction) {
    return this.companiesIncidentService.getListCompany().pipe(
      tap(result => {
        let filteredList = result.data;

        if (data.Email || data.Description) {
          filteredList = result.data.filter(item => {
            const emailMatches = !data.Email || item.Email.toLowerCase().includes(data.Email.toLowerCase());
            const nameMatches = !data.Description ||
            item.Description.toLowerCase().includes(data.Description.toLowerCase());
            return emailMatches && nameMatches;
          });
        }
        ctx.patchState({
          listCompany: filteredList
        });
      })
    );
  }
}
