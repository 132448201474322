import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IJobsTypes } from '@interfaces/jobs_types.interface';
import {
  JobsTypesAddAction,
  JobsTypesEditAction,
  JobsTypesGetAction,
  JobsTypesRemoveAction,
  JobsTypesSetShowDisabledAction,
  JobsTypesUpdateAction
} from './jobs-types.actions';
import { JobsTypesService } from '@services/jobs-type.service';

export interface JobsTypesStateModel {
  list: IJobsTypes[];
  show_disable: boolean;
  edit: IJobsTypes;
}

export const StateJobsTypes = 'Jobs_types';

@State<JobsTypesStateModel>({
  name: StateJobsTypes,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class JobsTypesState {
  @Selector() static list(state: JobsTypesStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: JobsTypesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: JobsTypesStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: JobsTypesStateModel) {
    return state.edit;
  }

  constructor(private jobsTypesService: JobsTypesService) {}

  @Action(JobsTypesGetAction)
  JobsTypesGet(ctx: StateContext<JobsTypesStateModel>) {
    return this.jobsTypesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(JobsTypesEditAction)
  JobsTypesEdit(ctx: StateContext<JobsTypesStateModel>, { data }: JobsTypesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(JobsTypesAddAction)
  JobsTypesAdd(ctx: StateContext<JobsTypesStateModel>, { data }: JobsTypesAddAction) {
    return this.jobsTypesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(JobsTypesUpdateAction)
  JobsTypesUpdate(ctx: StateContext<JobsTypesStateModel>, { data }: JobsTypesUpdateAction) {
    return this.jobsTypesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(JobsTypesRemoveAction)
  JobsTypesRemove(ctx: StateContext<JobsTypesStateModel>, { id }: JobsTypesRemoveAction) {
    return this.jobsTypesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(JobsTypesSetShowDisabledAction)
  JobsTypesSetShowDisabled(ctx: StateContext<JobsTypesStateModel>, { show }: JobsTypesSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }
}
