import { IEmployeesData, IEmployeesSearch, IUploadFile } from '@interfaces/employees.interface';

export class EmployeesGetAction {
  static readonly type = '[Employees] List';
  constructor(public data: IEmployeesSearch, public url?: string, public page?: number) {}
}

export class EmployeesEditTermedAction {
  static readonly type = '[EmployeesEditTermedAction] Edit';
  constructor(public data: IEmployeesData) {}
}
export class EmployeesAddTermedAction {
  static readonly type = '[EmployeesAddTermedAction] Add';
  constructor(public data: { employee_id, termination_reason, rehired }) {}
}

export class EmployeesUpdateTermedAction {
  static readonly type = '[EmployeesUpdateTermedAction] Update';
  constructor(public data: { termed_employee_id, termination_reason, rehired }) {}
}
export class ClearEmployeesGetAction {
  static readonly type = '[Employees] Set reset employees';
}

export class UploadFileAddAction {
  static readonly type = '[UploadFileAdd] Add';
  constructor(public data: IUploadFile) {}
}

export class UploadFileClearMessageAction {
  static readonly type = '[UploadFileClearMessage] Clear';
}

export const ActionsToProcessEmployees = {
  EmployeesGetAction,
  EmployeesAddTermedAction,
  EmployeesUpdateTermedAction,
  UploadFileAddAction
};
