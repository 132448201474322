import { IIncident, IIncidentSearch } from '@interfaces/incident.interface';
import { Params } from '@angular/router';

export class IncidentGetAction {
  static readonly type = '[Incident] List';
  static readonly skipError = true;
  constructor(public params?: Params) {}
}

export class IncidentAddAction {
  static readonly type = '[Incident] Add';
  constructor(public data: IIncidentSearch) {}
}

export class IncidentEditAction {
  static readonly type = '[Incident] Edit';
  constructor(public data: IIncident) {}
}

export class IncidentUpdateAction {
  static readonly type = '[Incident] Update';
  constructor(public data: IIncident) {}
}

export class IncidentRemoveAction {
  static readonly type = '[Incident] Remove';
  constructor(public id: number) {}
}

export class IncidentSetShowDisabledAction {
  static readonly type = '[Incident] List disabled';
  constructor(public show: boolean) {}
}

export class IncidentResendMail {
  static readonly type = '[Incident] Resend Incident Mail';
  constructor(public incidentId: number) {}
}

export const ActionsToProcessIncident = {
  IncidentGetAction,
  IncidentAddAction,
  IncidentUpdateAction,
  IncidentRemoveAction,
  IncidentResendMail
};
