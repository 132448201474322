import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { IEmploymentEducation } from '@interfaces/employment/employment-education.interface';
import {
  EmployeEducationAddAction,
  EmployeEducationEditAction,
  EmployeEducationRemoveAction,
  EmployeEducationUpdateAction,
  UserEducationGetAction
} from './employee-education-actions';
import { EmployeeEducationService } from '@services/employee/employee-educations.service';

export interface EmployeEducationStateModel {
  list: IEmploymentEducation[];
  edit: IEmploymentEducation;
}

export const StateEmployeEducation = 'employe_education';

@State<EmployeEducationStateModel>({
  name: StateEmployeEducation,
  defaults: {
    list: [],
    edit: {}
  }
})
@Injectable()
export class EmployeEducationState {
  constructor(private employeEducationServ: EmployeeEducationService) {}

  @Selector() static listEducationActive(state: EmployeEducationStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static editEducation(state: EmployeEducationStateModel) {
    return state.edit;
  }

  @Action(UserEducationGetAction)
  EmployeEducationsGet(ctx: StateContext<EmployeEducationStateModel>) {
    return this.employeEducationServ.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(EmployeEducationEditAction)
  EmployeEducationEdit(ctx: StateContext<EmployeEducationStateModel>, { data }: EmployeEducationEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(EmployeEducationAddAction)
  EmployeEducationAdd(ctx: StateContext<EmployeEducationStateModel>, { data }: EmployeEducationAddAction) {
    return this.employeEducationServ.createEducation(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(EmployeEducationUpdateAction)
  EmployeEducationUpdate(ctx: StateContext<EmployeEducationStateModel>, { data }: EmployeEducationUpdateAction) {
    return this.employeEducationServ.updateEducation(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(EmployeEducationRemoveAction)
  EmployeEducationRemove(ctx: StateContext<EmployeEducationStateModel>, { id }: EmployeEducationRemoveAction) {
    return this.employeEducationServ.remove(id).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
