import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { StatesGetPublicAction } from './states-actions';
import { Injectable } from '@angular/core';
import { PublicService } from '@services/public.service';

export interface StatesStateModel {
  list: string[];
}

export const StateStates = 'states';

@State<StatesStateModel>({
  name: StateStates,
  defaults: {
    list: []
  }
})
@Injectable()
export class StatesState {
  @Selector() static list(state: StatesStateModel) {
    return state.list;
  }

  constructor(private publicService: PublicService) {}

  @Action(StatesGetPublicAction)
  StatesGetPublic(ctx: StateContext<StatesStateModel>) {
    return this.publicService.getStates().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data.map(i => i.state_name)
        });
      })
    );
  }
}
