import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  CategoriesGetAction,
  CategoriesEditAction,
  CategoriesAddAction,
  CategoriesUpdateAction,
  CategoriesGetPublicAction,
  CategoriesSetShowDisabledAction
} from './categories-actions';
import { Injectable } from '@angular/core';
import { CategoriesService } from '@services/categories.service';
import { ICategories } from '@interfaces/categories.interface';

export interface CategoriesStateModel {
  list: ICategories[];
  show_disable: boolean;
  edit: ICategories;
}

export const StateCategories = 'categories';

@State<CategoriesStateModel>({
  name: StateCategories,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class CategoriesState {
  @Selector() static list(state: CategoriesStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: CategoriesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: CategoriesStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: CategoriesStateModel) {
    return state.edit;
  }

  constructor(private categoriesService: CategoriesService) {}

  @Action(CategoriesGetPublicAction)
  CategoriesGetPublic(ctx: StateContext<CategoriesStateModel>) {
    return this.categoriesService.getPublic().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(CategoriesGetAction)
  CategoriesGet(ctx: StateContext<CategoriesStateModel>) {
    return this.categoriesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(CategoriesEditAction)
  CategoriesEdit(ctx: StateContext<CategoriesStateModel>, { data }: CategoriesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(CategoriesAddAction)
  CategoriesAdd(ctx: StateContext<CategoriesStateModel>, { data }: CategoriesAddAction) {
    return this.categoriesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(CategoriesUpdateAction)
  CategoriesUpdate(ctx: StateContext<CategoriesStateModel>, { data }: CategoriesUpdateAction) {
    return this.categoriesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(CategoriesSetShowDisabledAction)
  CategoriesSetShowDisabled(ctx: StateContext<CategoriesStateModel>, { show }: CategoriesSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }
}
