import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SCOPES } from '@data/app.constants';
import { Store } from '@ngxs/store';
import { AuthState } from '@redux/auth/auth-state';

@Injectable()
export class AuthPrivateGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate() {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    const scope = this.store.selectSnapshot(AuthState.scope);
    if (!isAuthenticated || (scope && scope != SCOPES.PRIVATE)) {
      this.router.navigate(['/']);
    }
    return isAuthenticated;
  }
}
