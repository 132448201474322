import { QuestionsApply } from '@interfaces/apply-job-questionnaries.interface';

export class JobsQuestionnariesAddAction {
  static readonly type = '[JobsQuestionnaries] Add';
  constructor(public data: QuestionsApply) {}
}

export const ActionsToProcessJobsQuestionnaries = {
  JobsQuestionnariesAddAction
};
