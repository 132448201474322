import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ILicenseClass, IResponse, IState } from '@interfaces/api.interface';
import { ROUTES_PUBLIC, SCOPES } from '@data/app.constants';
import { of } from 'rxjs';
import { StatesLicenses } from '@data/states_licenses';
import { ICountries } from '@interfaces/type-citizen.interface';
import { IEmploymentTypeEducation } from '@interfaces/employment/employment-education.interface';

@Injectable({ providedIn: 'root' })
export class PublicService {
  constructor(private httpClient: HttpClient) {}

  getNationality() {
    return this.httpClient.get<ICountries[]>(`${ROUTES_PUBLIC.NATIONALITY}`);
  }

  getStates() {
    const isEnvironmentDev = JSON.parse(SCOPES.ENVIRONMENT_DEV.toLowerCase());
    return !isEnvironmentDev
      ? of<IResponse<IState[]>>({
          success: true,
          data: StatesLicenses as IState[]
        })
      : this.httpClient.get<IResponse<IState[]>>(`${ROUTES_PUBLIC.STATES}`);
  }

  getLicencesClass() {
    return this.httpClient.get<IResponse<ILicenseClass[]>>(`${ROUTES_PUBLIC.LICENSES_CLASS}`);
  }

  getTypeEducation() {
    return this.httpClient.get<IResponse<IEmploymentTypeEducation[]>>(`${ROUTES_PUBLIC.TYPE_EDUCATION}`);
  }
}
