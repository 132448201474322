import { ICategories } from '@interfaces/categories.interface';

export class CategoriesGetPublicAction {
  static readonly type = '[PublicCategories] List Public';
}

export class CategoriesGetAction {
  static readonly type = '[Categories] List';
}

export class CategoriesAddAction {
  static readonly type = '[Categories] Add';
  constructor(public data: ICategories) {}
}

export class CategoriesEditAction {
  static readonly type = '[Categories] Edit';
  constructor(public data: ICategories) {}
}

export class CategoriesUpdateAction {
  static readonly type = '[Categories] Update';
  constructor(public data: ICategories) {}
}

export class CategoriesSetShowDisabledAction {
  static readonly type = '[Categories] List disabled';
  constructor(public show: boolean) {}
}

export const ActionsToProcessCategories = {
  CategoriesGetPublicAction,
  CategoriesGetAction,
  CategoriesAddAction,
  CategoriesEditAction,
  CategoriesUpdateAction
};
