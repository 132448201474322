import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IEmployeesData, IUploadFile } from '@interfaces/employees.interface';
import { Observable } from 'rxjs';
import { IPaginateResponse } from '@interfaces/api-paginate.interface';
import { IResponse, IResponseMessage } from '@interfaces/api.interface';


@Injectable({providedIn: 'root'})
export class EmployeesService {
  constructor(private httpClient: HttpClient) {
  }

  getEmployeesSearch(
    data: IEmployeesData, url: string = null, page: string = null
  ): Observable<IPaginateResponse<IEmployeesData[]>> {
    const URL = url || `${ROUTES_PRIVATE.EMPLOYEES_SEARCH}`;
    const param = page ? { page } : null;
    return this.httpClient.post<IPaginateResponse<IEmployeesData[]>>(URL, data, {params: param });
  }

  save(data: { employee_id, termination_reason, rehired }) {
    return this.httpClient.post<IResponse<IEmployeesData>>(`${ROUTES_PRIVATE.TERMED_EMPLOYEES}`, data);
  }

  postUploadFile(data: IUploadFile) {
    return this.httpClient.post<IResponseMessage<string>>(`${ROUTES_PRIVATE.UPLOAD_FILE}/superNiceSTS`, data);
  }

  update(data: { termed_employee_id, termination_reason, rehired }) {
    const id = data?.termed_employee_id;
    delete data.termed_employee_id;
    return this.httpClient.put<IResponse<IEmployeesData>>(`${ROUTES_PRIVATE.TERMED_EMPLOYEES}/${id}`, data);
  }
}
