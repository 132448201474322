import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  UsersGetAction,
  UsersEditAction,
  UsersAddAction,
  UsersUpdateAction,
  UsersRemoveAction,
  UsersSetShowDisabledAction,
  UsersSearchByEmailAction,
  UsersChangePasswordAction
} from './users-actions';
import { Injectable } from '@angular/core';
import { UsersService } from '@services/security/users.service';
import { IUsers } from '@interfaces/security/users.interface';

export interface UsersStateModel {
  list: IUsers[];
  show_disable: boolean;
  edit: IUsers;
}

export const StateUsers = 'users';

@State<UsersStateModel>({
  name: StateUsers,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class UsersState {
  @Selector() static list(state: UsersStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: UsersStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: UsersStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: UsersStateModel) {
    return state.edit;
  }

  constructor(private usersService: UsersService) {}

  @Action(UsersGetAction)
  UsersGet(ctx: StateContext<UsersStateModel>) {
    return this.usersService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(UsersEditAction)
  UsersEdit(ctx: StateContext<UsersStateModel>, { data }: UsersEditAction) {
    if (data) {
      if (data?.id) {
        return this.usersService.show(data.id).pipe(
          tap(result => {
            ctx.patchState({
              edit: result.data
            });
          })
        );
      } else {
        ctx.patchState({
          edit: data
        });
      }
    } else {
      ctx.patchState({
        edit: data
      });
    }
  }

  @Action(UsersAddAction)
  UsersAdd(ctx: StateContext<UsersStateModel>, { data }: UsersAddAction) {
    return this.usersService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(UsersUpdateAction)
  UsersUpdate(ctx: StateContext<UsersStateModel>, { data }: UsersUpdateAction) {
    return this.usersService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(UsersRemoveAction)
  UsersRemove(ctx: StateContext<UsersStateModel>, { id }: UsersRemoveAction) {
    return this.usersService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(UsersSetShowDisabledAction)
  UsersSetShowDisabled(ctx: StateContext<UsersStateModel>, { show }: UsersSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }

  @Action(UsersSearchByEmailAction)
  UsersSearchByEmail(ctx: StateContext<UsersStateModel>, { email }: UsersSearchByEmailAction) {
    return this.usersService.showByEmail(email).pipe(
      tap(result => {
        ctx.patchState({
          edit: result.data
        });
      })
    );
  }

  @Action(UsersChangePasswordAction)
  UsersChangePasswordAction(ctx: StateContext<UsersStateModel>, { id, password }: UsersChangePasswordAction) {
    return this.usersService.changePassword(id, password)
      .pipe(tap((): void => {
        ctx.patchState({ edit: null });
      }));
  }
}
