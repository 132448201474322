import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  RolesGetAction,
  RolesEditAction,
  RolesAddAction,
  RolesUpdateAction,
  RolesRemoveAction,
  RolesSetShowDisabledAction
} from './roles-actions';
import { Injectable } from '@angular/core';
import { RolesService } from '@services/security/roles.service';
import { IRoles } from '@interfaces/security/roles.interface';

export interface RolesStateModel {
  list: IRoles[];
  show_disable: boolean;
  edit: IRoles;
}

export const StateRoles = 'roles';

@State<RolesStateModel>({
  name: StateRoles,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class RolesState {
  @Selector() static list(state: RolesStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: RolesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: RolesStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: RolesStateModel) {
    return state.edit;
  }

  constructor(private rolesService: RolesService) {}

  @Action(RolesGetAction)
  RolesGet(ctx: StateContext<RolesStateModel>) {
    return this.rolesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(RolesEditAction)
  RolesEdit(ctx: StateContext<RolesStateModel>, { data }: RolesEditAction) {
    if (data) {
      if (data?.id) {
        return this.rolesService.show(data.id).pipe(
          tap(result => {
            ctx.patchState({
              edit: result.data
            });
          })
        );
      } else {
        ctx.patchState({
          edit: data
        });
      }
    } else {
      ctx.patchState({
        edit: data
      });
    }
  }

  @Action(RolesAddAction)
  RolesAdd(ctx: StateContext<RolesStateModel>, { data }: RolesAddAction) {
    return this.rolesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(RolesUpdateAction)
  RolesUpdate(ctx: StateContext<RolesStateModel>, { data }: RolesUpdateAction) {
    return this.rolesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(RolesRemoveAction)
  RolesRemove(ctx: StateContext<RolesStateModel>, { id }: RolesRemoveAction) {
    return this.rolesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(RolesSetShowDisabledAction)
  RolesSetShowDisabled(ctx: StateContext<RolesStateModel>, { show }: RolesSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }
}
