import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IUserApplications } from '@interfaces/user/user-applications.interface';

@Injectable({ providedIn: 'root' })
export class UserApplicationsService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IUserApplications[]>>(`${ROUTES_PUBLIC.APPLICATIONS}`);
  }
}
