import { Question } from "@interfaces/apply-job-questionnaries.interface";

export class QuestionTempAdd {
  static readonly type = '[QuestionsTemp] Add';
  constructor(public data) {}
}
export class QuestionTempUpdate {
  static readonly type = '[QuestionsTemp] Update';
  constructor(public data) {}
}
export class QuestionTempGetAll {
  static readonly type = '[QuestionsTemp] List';
  constructor(public category) {}
}
export class QuestionTempSetShowDisabledAction {
  static readonly type = '[QuestionsTemp] ShowDisabled';
  constructor(public show: boolean) {}
}
export class QuestionSetDataAction {
  static readonly type = '[TemplatesType] Set Type Data';
  constructor(public data: Question) {}
}
export class ClearCurrentQuestion {
  static readonly type = '[QuestionsTemp] Clear Current Question';
}

export const ActionsToProcessQuestionsTemp =
  {
    QuestionTempAdd,
    QuestionTempUpdate,
    QuestionTempGetAll,
    QuestionSetDataAction
  };
