import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { DashboardGetAction } from './dashboard-actions';
import { Injectable } from '@angular/core';
import { DashboardService } from '@services/dashboard.service';
import { IDashboard } from '@interfaces/dashboard.service';

export interface DashboardStateModel {
  list: IDashboard;
}

export const StateDashboard = 'dashboard';

@State<DashboardStateModel>({
  name: StateDashboard,
  defaults: {
    list: {}
  }
})
@Injectable()
export class DashboardState {
  @Selector() static list(state: DashboardStateModel) {
    return state.list;
  }

  constructor(private dashboardService: DashboardService) {}

  @Action(DashboardGetAction)
  DashboardGet(ctx: StateContext<DashboardStateModel>) {
    return this.dashboardService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }
}
