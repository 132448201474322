import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IAudits } from '@interfaces/audits/audits.interface';
import { MedicalReportService } from '@services/medical-report/medical-report.service';
import { HistoryAuditsMedicalRecordAction } from './audits.action';


export interface AuditsStateModel {
  history: IAudits[];
}

export const AuditsModel = 'audits_history';

@State<AuditsStateModel>({
  name: AuditsModel,
  defaults: {
    history: []
  }
})
@Injectable()
export class AuditsState {
  @Selector() static list(state: AuditsStateModel) {
    return state.history;
  }

  constructor(private medicalReportService: MedicalReportService) {}

  @Action(HistoryAuditsMedicalRecordAction)
  MedicalReportAdminHistoryAction(
    ctx: StateContext<AuditsStateModel>,
    { id }: HistoryAuditsMedicalRecordAction) {
    return this.medicalReportService.getHistoryIdMedicalReport(id).pipe(
      tap(result => {
        ctx.patchState({
          history: result.data
        });
      })
    );
  }
}
