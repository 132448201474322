import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../pages/public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'admin', loadChildren: () => import('../pages/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'auth', loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'employee', loadChildren: () => import('../pages/employee/employee.module').then(m => m.EmployeeModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];
