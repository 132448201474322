import { Action, Selector, State, StateContext } from '@ngxs/store';
import { mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MedicalReportService } from '@services/medical-report/medical-report.service';
import {
  IAttachmentMedicalReport,
  IMedicalReport,
  INoteMedicalReport} from '@interfaces/medical-report/medical-report.interface';
import {
  AttachmentIsNotarizeIdAction,
  AttachmentReviewByQaIdAction,
  AttachmentReviewReadyToSendIdAction,
  AttachmentToReviewByQaIdAction,
  MedicalReportAdminAttachmentGetIdAction,
  MedicalReportAdminCreateAttachmentAction,
  MedicalReportAdminCreateMultipleAttachmentAction,
  MedicalReportAdminDeleteAttachmentAction,
  MedicalReportAdminEditAction,
  MedicalReportAdminGetAction,
  MedicalReportAdminNotarizeAttachmentAction,
  MedicalReportAdminNoteAddAction,
  MedicalReportAdminNoteDeleteAction,
  MedicalReportAdminNoteListAction,
  MedicalReportAdminNoteUpdateAction,
  MedicalReportAdminUpdateStatusAction,
  MedicalReportIdEditAction,
  MedicalReportUpdateAction
} from './medical-report-admin.action';


export interface MedicalReportAdminModel {
  list: IMedicalReport[];
  edit: IMedicalReport;
  attachment: IAttachmentMedicalReport[];
  note: INoteMedicalReport;
  listNote: INoteMedicalReport[];
  messageResponse: string;
  medicalReportId: number;
}

export const MedicalReportState = 'medical_report_admin';

@State<MedicalReportAdminModel>({
  name: MedicalReportState,
  defaults: {
    list: [],
    edit: null,
    attachment: [],
    note: null,
    listNote: [],
    messageResponse: null,
    medicalReportId: null
  }
})
@Injectable()
export class MedicalReportAdminState {

  @Selector() static list(state: MedicalReportAdminModel) {
    return state.list;
  }

  @Selector() static edit(state: MedicalReportAdminModel) {
    return state.edit;
  }

  @Selector() static medicalReportIdSelected(state: MedicalReportAdminModel) {
    return state.medicalReportId;
  }

  @Selector() static listAttachment(state: MedicalReportAdminModel) {
    return state.attachment.filter((i) => i.active);
  }

  @Selector() static listNotes(state: MedicalReportAdminModel) {
    return state.listNote.filter((i) => i.active);
  }

  constructor(private medicalReportService: MedicalReportService) {}

  @Action(MedicalReportAdminGetAction)
  MedicalReportStatusGetAction(ctx: StateContext<MedicalReportAdminModel>) {
    return this.medicalReportService.getMedicalRecordAdmin().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(MedicalReportAdminEditAction)
  MedicalReportCompaniesEdit(
    ctx: StateContext<MedicalReportAdminModel>,
    { data }: MedicalReportAdminEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(MedicalReportIdEditAction)
  MedicalReportIdEditAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { id }: MedicalReportIdEditAction) {
    ctx.patchState({
      medicalReportId: id
    });
  }

  @Action(MedicalReportAdminAttachmentGetIdAction)
  MedicalReportAdminAttachmentGetIdAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { id }: MedicalReportAdminAttachmentGetIdAction) {
    return this.medicalReportService.getMedicalRecordAdminAttachmentId(id).pipe(
      tap(result => {
        ctx.patchState({
          attachment: result.data
        });
      })
    );
  }

  @Action(MedicalReportAdminUpdateStatusAction)
  MedicalReportAdminUpdateStatusAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { data }: MedicalReportAdminUpdateStatusAction) {
    return this.medicalReportService.updateMedicalReportStatus(data).pipe(
      tap((resp) => {
        ctx.patchState({
          // messageResponse: resp.data
        });
      })
    );
  }

  @Action(MedicalReportAdminNoteAddAction)
  MedicalReportAdminNoteAddAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { data }: MedicalReportAdminNoteAddAction) {
    return this.medicalReportService.saveNoteMedicalReport(data).pipe(
      tap(() => {
        ctx.patchState({
          note: null
        });
      })
    );
  }

  @Action(MedicalReportAdminNoteDeleteAction)
  MedicalReportAdminNoteDeleteAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { id }: MedicalReportAdminNoteDeleteAction) {
    return this.medicalReportService.deleteNoteIdMedicalReport(id).pipe(
      tap(() => {
        ctx.patchState({
          note: null
        });
      })
    );
  }

  @Action(MedicalReportAdminNoteListAction)
  MedicalReportAdminNoteListAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { id }: MedicalReportAdminNoteListAction) {
    return this.medicalReportService.getNoteIdMedicalReport(id).pipe(
      tap((result) => {
        ctx.patchState({
          listNote: result.data
        });
      })
    );
  }

  @Action(AttachmentReviewByQaIdAction)
  AttachmentReviewByQaIdAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { id, reviewByQa }: AttachmentReviewByQaIdAction) {
    return this.medicalReportService.updateTagsReviewByQa(id, reviewByQa).pipe(
      tap(() => {
        ctx.patchState({
          // edit: null
        });
      })
    );
  }

  @Action(AttachmentToReviewByQaIdAction)
  AttachmentToReviewByQaIdAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { id, toReviewByQa }: AttachmentToReviewByQaIdAction) {
    return this.medicalReportService.updateTagsToReviewByQa(id, toReviewByQa).pipe(
      tap(() => {
        ctx.patchState({
          // edit: null
        });
      })
    );
  }

  @Action(AttachmentReviewReadyToSendIdAction)
  AttachmentReviewReadyToSendIdAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { id, selectedToSend }: AttachmentReviewReadyToSendIdAction) {
    return this.medicalReportService.updateTagsToSent(id, selectedToSend).pipe(
      tap(() => {
        ctx.patchState({
          // edit: null
        });
      })
    );
  }

  @Action(AttachmentIsNotarizeIdAction)
  AttachmentIsNotarizeIdAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { id, notarize }: AttachmentIsNotarizeIdAction) {
    return this.medicalReportService.updateIsNotarize(id, notarize).pipe(
      tap(() => {
        ctx.patchState({
          // edit: null
        });
      })
    );
  }

  @Action(MedicalReportAdminCreateAttachmentAction)
  MedicalReportAdminCreateAttachmentAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { data }: MedicalReportAdminCreateAttachmentAction) {
    return this.medicalReportService.saveAttachmentCreated(data).pipe(
      tap(() => {
        ctx.patchState({
          // edit: null
        });
      })
    );
  }

  @Action(MedicalReportAdminCreateMultipleAttachmentAction)
  MedicalReportAdminCreateMultipleAttachmentAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { data }: MedicalReportAdminCreateMultipleAttachmentAction) {
    return this.medicalReportService.saveAttachmentCreatedMultiple(data).pipe(
      tap(() => {
        ctx.patchState({
          // edit: null
        });
      })
    );
  }

  @Action(MedicalReportAdminNotarizeAttachmentAction)
  MedicalReportAdminNotarizeAttachmentAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { data }: MedicalReportAdminNotarizeAttachmentAction) {
    return this.medicalReportService.saveAttachmentNotarized(data).pipe(
      tap(() => {
        ctx.patchState({
          // edit: null
        });
      })
    );
  }

  @Action(MedicalReportAdminDeleteAttachmentAction)
  MedicalReportAdminDeleteAttachmentAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { id }: MedicalReportAdminDeleteAttachmentAction) {
    return this.medicalReportService.deleteAttachmentCreated(id).pipe(
      tap(() => {
        ctx.patchState({
          // edit: null
        });
      })
    );
  }

  @Action(MedicalReportAdminNoteUpdateAction)
  MedicalReportAdminNoteUpdateAction(
    ctx: StateContext<MedicalReportAdminModel>,
    { data }: MedicalReportAdminNoteUpdateAction) {
    return this.medicalReportService.updateNoteMedicalReport(data).pipe(
      tap(() => {
        ctx.patchState({
          note: null
        });
      })
    );
  }

  @Action(MedicalReportUpdateAction)
  updateMedicalReport(
    {dispatch}: StateContext<MedicalReportAdminModel>,
    {id, payload}: MedicalReportUpdateAction
  ) {
    return this.medicalReportService
      .updateMedicalReport(payload, id)
      .pipe(mergeMap(() => dispatch(new MedicalReportAdminGetAction())));
  }
}
