import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IUserReferences } from '@interfaces/user/user-references';

@Injectable({ providedIn: 'root' })
export class UserReferencesService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IUserReferences[]>>(`${ROUTES_PUBLIC.USER_REFERENCES}`);
  }

  save(data: IUserReferences) {
    return this.httpClient.post<IResponse<IUserReferences>>(`${ROUTES_PUBLIC.USER_REFERENCES}`, data);
  }

  update(data: IUserReferences) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IUserReferences>>(`${ROUTES_PUBLIC.USER_REFERENCES}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IUserReferences>>(`${ROUTES_PUBLIC.USER_REFERENCES}/${id}`);
  }
}
