import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { JobsQuestionnariesAddAction } from './jobs-questionnaries.actions';
import { Questionnaire } from '@interfaces/apply-job-questionnaries.interface';
import { JobsQuestionnariesService } from '@services/jobs-questionnaries.service';

export interface JobsQuestionnariesStateModel {
  list: Questionnaire[];
  edit: Questionnaire;
}

export const StateJobsQuestionnaries = 'jobs_questionnaries';

@State<JobsQuestionnariesStateModel>({
  name: StateJobsQuestionnaries,
  defaults: {
    list: [],
    edit: {}
  }
})
@Injectable()
export class JobsQuestionnariesState {
  constructor(private jobsQuestionnariesService: JobsQuestionnariesService) {}

  @Action(JobsQuestionnariesAddAction)
  JobsQuestionnariesAdd(ctx: StateContext<JobsQuestionnariesStateModel>, { data }: JobsQuestionnariesAddAction) {
    return this.jobsQuestionnariesService.save(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
