import { ICompanyIncident } from '@interfaces/companies-incident';

export class CompaniesIncidentGetAction {
  static readonly type = '[CompaniesIncident] List';
}

export class EmergencyIncidentGetAction {
  static readonly type = '[EmergencyIncident] List';
}

export class DamagesIncidentGetAction {
  static readonly type = '[DamagesIncident] List';
}

export class CompaniesIncidentAddAction {
  static readonly type = '[CompaniesIncident] Add';
  constructor(public data: ICompanyIncident) {}
}

export class CompaniesIncidentEditAction {
  static readonly type = '[CompaniesIncident] Edit';
  constructor(public data: ICompanyIncident) {}
}

export class CompaniesIncidentUpdateAction {
  static readonly type = '[CompaniesIncident] Update';
  constructor(public data: ICompanyIncident) {}
}

export class CompaniesIncidentRemoveAction {
  static readonly type = '[CompaniesIncident] Remove';
  constructor(public id: number) {}
}

export class CompaniesIncidentSetShowDisabledAction {
  static readonly type = '[CompaniesIncident] List disabled';
  constructor(public show: boolean) {}
}

export class CompaniesIncidentQueryAction {
  static readonly type = '[CompaniesIncident] Query';
  constructor(public data: ICompanyIncident) {}
}

export const ActionsToProcessAttachmentsTypes = {
  CompaniesIncidentGetAction,
  CompaniesIncidentAddAction,
  CompaniesIncidentEditAction,
  CompaniesIncidentUpdateAction,
  CompaniesIncidentRemoveAction,
  CompaniesIncidentQueryAction
};
