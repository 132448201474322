import { IUserPersonalInfo } from '@interfaces/user/user-personal-info.interface';

export class GetUserPersonalInfoAction {
  static readonly type = '[UserPersonalInfo] Get';
}

export class UpdateUserPersonalInfoAction {
  static readonly type = '[UserPersonalInfo] Update';
  constructor(public payload: IUserPersonalInfo) {}
}

export const ActionsToUserPersonalInfo = {
  GetUserPersonalInfoAction,
  UpdateUserPersonalInfoAction
};
