import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { Questionnaire, QuestionsApply } from '@interfaces/apply-job-questionnaries.interface';

@Injectable({
  providedIn: 'root'
})
export class JobsQuestionnariesService {
  constructor(private http: HttpClient) {}

  save(data: QuestionsApply) {
    const testId = data.test_id;
    delete data.test_id;
    return this.http.post<IResponse<Questionnaire>>(`${ROUTES_PUBLIC.JOBS}/resolve/${testId}`, data);
  }
}
