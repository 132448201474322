import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IParameters, IParametersKeys, IParametersTypes } from '@interfaces/parameters.interface';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  constructor(private http: HttpClient) {}

  save(data: IParameters) {
    delete data.id;
    return this.http.post<IResponse<IParameters>>(`${ROUTES_PRIVATE.PARAMETERS_TEMP}`, data);
  }
  update(data: IParameters) {
    const id = data.id;
    delete data.id;
    return this.http.put<IResponse<IParameters>>(`${ROUTES_PRIVATE.PARAMETERS_TEMP}/${id}`, data);
  }
  getAll() {
    return this.http.get<IResponse<IParameters[]>>(`${ROUTES_PRIVATE.PARAMETERS_TEMP}`);
  }
  getById(id) {
    return this.http.get<IResponse<IParameters>>(`${ROUTES_PRIVATE.PARAMETERS_TEMP}/${id}`);
  }
  getTypesAll() {
    return this.http.get<IResponse<IParameters[]>>(`${ROUTES_PRIVATE.PARAMETERS_TYPES_TEMP}`);
  }
  getParametersKeysAll() {
    return this.http.get<IResponse<IParametersKeys>>(`${ROUTES_PRIVATE.PARAMETERS_KEYS}`);
  }
  saveType(data: IParametersTypes) {
    delete data.id;
    return this.http.post<IResponse<IParametersTypes>>(`${ROUTES_PRIVATE.PARAMETERS_TYPES_TEMP}`, data);
  }
  updateType(data: IParametersTypes) {
    const id = data.id;
    delete data.id;
    return this.http.put<IResponse<IParametersTypes>>(`${ROUTES_PRIVATE.PARAMETERS_TYPES_TEMP}/${id}`, data);
  }
  getTree() {
    return this.http.get<IResponse<IParametersTypes[]>>(`${ROUTES_PRIVATE.PARAMETERS_TYPES_TREE}`);
  }
}
