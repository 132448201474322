import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EmployeeWorkService } from '@services/employee/employee-work.service';
import {
  EmployeWorkAddAction,
  EmployeWorkEditAction,
  EmployeWorkRemoveAction,
  EmployeWorkUpdateAction
} from './employee-work-actions';
import { IUserWorks } from '@interfaces/user/user-works';

export interface EmployeWorkStateModel {
  list: IUserWorks[];
  edit: IUserWorks;
}

export const StateEmployeWork = 'employe_work';

@State<EmployeWorkStateModel>({
  name: StateEmployeWork,
  defaults: {
    list: [],
    edit: {}
  }
})
@Injectable()
export class EmployeWorkState {
  constructor(private employeWorkServ: EmployeeWorkService) {}

  @Selector() static listWorkActive(state: EmployeWorkStateModel) {
    return state.list.filter(i => i.id);
  }
  @Selector() static editWork(state: EmployeWorkStateModel) {
    return state.edit;
  }

  @Action(EmployeWorkEditAction)
  EmployeEducationEdit(ctx: StateContext<EmployeWorkStateModel>, { data }: EmployeWorkEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(EmployeWorkAddAction)
  EmployeEducationAdd(ctx: StateContext<EmployeWorkStateModel>, { data }: EmployeWorkAddAction) {
    return this.employeWorkServ.createWork(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(EmployeWorkUpdateAction)
  EmployeEducationUpdate(ctx: StateContext<EmployeWorkStateModel>, { data }: EmployeWorkUpdateAction) {
    return this.employeWorkServ.updateWork(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(EmployeWorkRemoveAction)
  EmployeEducationRemove(ctx: StateContext<EmployeWorkStateModel>, { id }: EmployeWorkRemoveAction) {
    return this.employeWorkServ.remove(id).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
