import { UserPersonalInfo } from "@interfaces/employment/employment-personal-info.interface";

export class TypeCitizenGetPublicAction {
  static readonly type = '[PublicTypeCitizen] List';
}

export class TypeCitizenRequestSaveAction {
  static readonly type = '[TypeCitizenRequestSaveAction] Request save';
}

export class TypeCitizenAddAction {
  static readonly type = '[TypeCitizenAddAction] Add';
  constructor(public payload: UserPersonalInfo) {}
}

export const ActionsToProcessStates = {
  TypeCitizenGetPublicAction,
  TypeCitizenAddAction
};
