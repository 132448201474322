import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IQuestionsTypes } from '@interfaces/questions-types.interface';
import { IParametersTypes } from '@interfaces/parameters.interface';

@Injectable({ providedIn: 'root' })
export class QuestionsTypesService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IQuestionsTypes[]>>(`${ROUTES_PRIVATE.QUESTIONS_TYPES}`);
  }

  save(data: IQuestionsTypes) {
    return this.httpClient.post<IResponse<IQuestionsTypes>>(`${ROUTES_PRIVATE.QUESTIONS_TYPES}`, data);
  }

  update(data: IQuestionsTypes) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IQuestionsTypes>>(`${ROUTES_PRIVATE.QUESTIONS_TYPES}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IQuestionsTypes>>(`${ROUTES_PRIVATE.QUESTIONS_TYPES}/${id}`);
  }

  getTree(category: number, selectedId?: number) {
    return this.httpClient.get<IResponse<IQuestionsTypes[]>>(
      `${ROUTES_PRIVATE.QUESTIONS_TYPES_TREE}${category}/${selectedId}`
    );
  }
}
