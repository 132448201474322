import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import {
  IApplicantTemplate,
  IAssignTemplates,
  ICompanyTemplate,
  ITemplates,
  ITemplatesTypes
} from '@interfaces/templates.interface';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  constructor(private http: HttpClient) {}

  save(data: ITemplates) {
    delete data.id;
    return this.http.post<IResponse<ITemplates>>(`${ROUTES_PRIVATE.TEMPLATES}`, data);
  }
  update(data: ITemplates) {
    const id = data.id;
    delete data.id;
    return this.http.put<IResponse<ITemplates>>(`${ROUTES_PRIVATE.TEMPLATES}/${id}`, data);
  }
  getAll() {
    return this.http.get<IResponse<ITemplates[]>>(`${ROUTES_PRIVATE.TEMPLATES}`);
  }
  getById(id) {
    return this.http.get<IResponse<ITemplates>>(`${ROUTES_PRIVATE.TEMPLATES}/${id}`);
  }
  getByType(type) {
    return this.http.get<IResponse<ITemplates[]>>(`${ROUTES_PRIVATE.TEMPLATES}/by_types/${type}`);
  }
  getTypesAll() {
    return this.http.get<IResponse<ITemplates[]>>(`${ROUTES_PRIVATE.TEMPLATE_TYPES}`);
  }
  saveType(data: ITemplatesTypes) {
    delete data.id;
    return this.http.post<IResponse<ITemplatesTypes>>(`${ROUTES_PRIVATE.TEMPLATE_TYPES}`, data);
  }
  updateType(data: ITemplatesTypes) {
    const id = data.id;
    delete data.id;
    return this.http.put<IResponse<ITemplatesTypes>>(`${ROUTES_PRIVATE.TEMPLATE_TYPES}/${id}`, data);
  }
  assignTemplate(data: IAssignTemplates) {
    return this.http.post<IResponse<ITemplatesTypes>>(`${ROUTES_PRIVATE.COMPANY_TEMPLATES}`, data);
  }
  getCompanyTemplates(companyId) {
    return this.http.get<IResponse<ICompanyTemplate[]>>(`${ROUTES_PRIVATE.TEMPLATES_BY_COMPANY}${companyId}`);
  }
  deleteCompanyTemplates(id) {
    return this.http.put<IResponse<ICompanyTemplate[]>>(`${ROUTES_PRIVATE.COMPANY_TEMPLATES}/${id}`, {});
  }
  deleteTemplates(id, data) {
    return this.http.put<IResponse<ICompanyTemplate[]>>(`${ROUTES_PRIVATE.TEMPLATES_DELETE}${id}`, data);
  }

  getApplicantTemplates(templateId) {
    return this.http.get<IResponse<IApplicantTemplate>>(`${ROUTES_PUBLIC.TEMPLATES_APPLICANT}${templateId}`);
  }

  getTemplatesByApplicant(id) {
    return this.http.get<IResponse<ITemplates[]>>(`${ROUTES_PRIVATE.TEMPLATES_APPLICANTS}/${id}`);
  }
}
