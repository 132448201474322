import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from '@data/app.constants';
import { IApplicants } from '@interfaces/admin-applicants.interface';
import { IPaginateResponse } from '@interfaces/api-paginate.interface';
import { IResponse } from '@interfaces/api.interface';
import { IApplicantInterested } from '@interfaces/apply-job-questionnaries.interface';
import { IAttachmentsSignature } from '@interfaces/attachments-types.interface';
import { IJobs, IJobsSearch, IJobWitchApplicants } from '@interfaces/jobs.interface';
import { IUserApplicationsAdd } from '@interfaces/user/user-applications.interface';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  constructor(private http: HttpClient) {}

  getPublicCurrents() {
    return this.http.get<IResponse<IJobs[]>>(`${ROUTES_PUBLIC.JOBS}/currents`);
  }

  getPublicDetail(code: string) {
    return this.http.get<IResponse<IJobs>>(`${ROUTES_PUBLIC.JOBS}/detail/${code}`);
  }

  getApplicantStatusDetail(code: string) {
    return this.http.get<IResponse<IApplicantInterested>>(`${ROUTES_PUBLIC.JOBS}/applicant/${code}`);
  }

  getPublicFilterers(filters: IJobsSearch) {
    return this.http.post<IResponse<IJobs[]>>(`${ROUTES_PUBLIC.JOBS}/filterers`, filters);
  }

  sendPublicApply(id: number) {
    return this.http.put<IResponse<string>>(`${ROUTES_PUBLIC.JOBS}/apply/${id}`, {});
  }

  get() {
    return this.http.get<IResponse<IJobs[]>>(`${ROUTES_PRIVATE.JOBS}`);
  }

  show(id: number) {
    return this.http.get<IResponse<IJobs>>(`${ROUTES_PRIVATE.JOBS}/${id}`);
  }

  save(data: IJobs) {
    delete data.id;
    return this.http.post<IResponse<IJobs>>(`${ROUTES_PRIVATE.JOBS}`, data);
  }

  update(data: IJobs) {
    const id = data.id;
    delete data.id;
    return this.http.put<IResponse<IJobs>>(`${ROUTES_PRIVATE.JOBS}/${id}`, data);
  }

  getWithApplicants() {
    return this.http.get<IResponse<IJobs[]>>(`${ROUTES_PRIVATE.JOBS}/list/with_applicants`);
  }

  getListJobOffersApplicants(jobId: IJobWitchApplicants) {
    return this.http.get<IResponse<IJobWitchApplicants[]>>(`${ROUTES_PRIVATE.JOBS}/list/applicants/${jobId}`);
  }

  getJobApplicant(id: number) {
    return this.http.get<IResponse<IJobs[]>>(`${ROUTES_PRIVATE.JOBS_APPLICANTS}/${id}`);
  }

  updateJobsApplicants(data: IJobs) {
    const id = data.id;
    delete data.id;
    return this.http.put<IResponse<IJobs>>(`${ROUTES_PRIVATE.JOBS_APPLICANTS}/${id}`, data);
  }

  getApplicantsSearch(data: IUserApplicationsAdd) {
    return this.http.post<IResponse<IApplicants[]>>(`${ROUTES_PRIVATE.JOBS_APPLICANTS_ALL}`, data);
  }

  getApplicantSelected(id: number) {
    return this.http.get<IResponse<IApplicants>>(`${ROUTES_PRIVATE.JOBS_APPLICANT_SELECTED}/${id}`);
  }

  getApplicantsList(
    data: IUserApplicationsAdd, url: string = null, page: string = null
  ) {
    const URL = url || `${ROUTES_PRIVATE.JOBS_APPLICANTS_LIST}`;
    const param = page ? { page } : null;
    return this.http.post<IPaginateResponse<IApplicants[]>>(URL, data, {params: param });
  }

  postApplicantsAdd(data: IUserApplicationsAdd) {
    return this.http.post<IResponse<IUserApplicationsAdd>>(`${ROUTES_PRIVATE.ADD_APPLICANTS}`, data);
  }

  postApplicantsCodeQr(id: number) {
    return this.http.get<IResponse<string>>(`${ROUTES_PRIVATE.GENERATE_CODE_QR}/${id}`);
  }

  postApplicantsAsignature(data: IAttachmentsSignature) {
    return this.http.post<IResponse<IAttachmentsSignature>>(`${ROUTES_PRIVATE.ASIGNATURE_APPLICANTS}`, data);
  }

  postApplicantsAsignatureRead(id: number) {
    return this.http.post<IResponse<string>>(`${ROUTES_PRIVATE.ASIGNATURE_APPLICANTS_READ}`, id);
  }

  getPendingJobs() {
    return this.http.get<IResponse<string>>(`${ROUTES_PRIVATE.PENDING_JOB}`);
  }
  saveMissingData(data: any) {
    return this.http.post<IResponse<IAttachmentsSignature>>(`${ROUTES_PUBLIC.MISSING_DATA}`, data);
  }
  getTemplatePDF(user: number, template: number): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${ROUTES_PRIVATE.DOWNLOAD_TEMPLATE}${user}/${template}`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

}
