import { ISalariesTypes } from '@interfaces/salaries-types.interface';

export class SalariesTypesGetAction {
  static readonly type = '[SalariesTypes] List';
}

export class SalariesTypesAddAction {
  static readonly type = '[SalariesTypes] Add';
  constructor(public data: ISalariesTypes) {}
}

export class SalariesTypesEditAction {
  static readonly type = '[SalariesTypes] Edit';
  constructor(public data: ISalariesTypes) {}
}

export class SalariesTypesUpdateAction {
  static readonly type = '[SalariesTypes] Update';
  constructor(public data: ISalariesTypes) {}
}

export class SalariesTypesRemoveAction {
  static readonly type = '[SalariesTypes] Remove';
  constructor(public id: number) {}
}

export class SalariesTypesSetShowDisabledAction {
  static readonly type = '[SalariesTypes] List disabled';
  constructor(public show: boolean) {}
}

export const ActionsToProcessSalariesTypes = {
  SalariesTypesGetAction,
  SalariesTypesAddAction,
  SalariesTypesUpdateAction,
  SalariesTypesRemoveAction
};
