import { ICompanies } from '@interfaces/companies.interface';

export class CompaniesGetAction {
  static readonly type = '[Companies] List';
}

export class CompaniesAddAction {
  static readonly type = '[Companies] Add';
  constructor(public data: ICompanies) {}
}

export class CompaniesEditAction {
  static readonly type = '[Companies] Edit';
  constructor(public data: ICompanies) {}
}

export class CompaniesUpdateAction {
  static readonly type = '[Companies] Update';
  constructor(public data: ICompanies) {}
}

export class CompaniesRemoveAction {
  static readonly type = '[Companies] Remove';
  constructor(public id: number) {}
}

export class CompaniesSetShowDisabledAction {
  static readonly type = '[Companies] List disabled';
  constructor(public show: boolean) {}
}

export const ActionsToProcessCompanies = {
  CompaniesGetAction,
  CompaniesAddAction,
  CompaniesEditAction,
  CompaniesUpdateAction,
  CompaniesRemoveAction
};
