import { IUsers } from '@interfaces/security/users.interface';

export class UsersGetAction {
  static readonly type = '[Users] List';
}

export class UsersAddAction {
  static readonly type = '[Users] Add';
  static readonly skipError = true;
  static readonly errorType = 'warning';
  static readonly errorTitle = 'Problems registering user!';
  static readonly errorBody = 'Please, verify the user you are trying to register';
  constructor(public data: IUsers) {}
}

export class UsersEditAction {
  static readonly type = '[Users] Edit';
  constructor(public data?: IUsers) {}
}

export class UsersUpdateAction {
  static readonly type = '[Users] Update';
  static readonly skipError = true;
  constructor(public data: IUsers) {}
}

export class UsersRemoveAction {
  static readonly type = '[Users] Remove';
  constructor(public id: number) {}
}

export class UsersSetShowDisabledAction {
  static readonly type = '[Users] List disabled';
  constructor(public show: boolean) {}
}

export class UsersSearchByEmailAction {
  static readonly type = '[Users] Search by email';
  constructor(public email: string) {}
}

export class UsersChangePasswordAction {
  static readonly type = '[Users] Change password';
  constructor(public id: number, public password: string) {}
}

export const ActionsToProcessUsers = {
  UsersGetAction,
  UsersEditAction,
  UsersAddAction,
  UsersUpdateAction,
  UsersRemoveAction,
  UsersSearchByEmailAction,
  UsersChangePasswordAction
};
