import { Injectable } from '@angular/core';
import { IResponse } from '@interfaces/api.interface';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IEmploymentEducation } from '@interfaces/employment/employment-education.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployeeEducationService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http.get<IResponse<IEmploymentEducation[]>>(`${ROUTES_PUBLIC.EDUCATION}`);
  }

  createEducation(data: IEmploymentEducation) {
    return this.http.post<IResponse<IEmploymentEducation>>(`${ROUTES_PUBLIC.EDUCATION}`, data);
  }

  updateEducation(data: IEmploymentEducation) {
    return this.http.put<IResponse<IEmploymentEducation>>(`${ROUTES_PUBLIC.EDUCATION}/${data.id}`, data);
  }

  remove(id: number) {
    return this.http.delete<IResponse<IEmploymentEducation>>(`${ROUTES_PUBLIC.EDUCATION}/${id}`);
  }
}
