import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { ICompanies } from '@interfaces/companies.interface';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  constructor(private httpClient: HttpClient) {}

  getInfoCurrentCompany() {
    return this.httpClient.get(`${ROUTES_PUBLIC.INFO_COMPANY}`);
  }

  get() {
    return this.httpClient.get<IResponse<ICompanies[]>>(`${ROUTES_PRIVATE.COMPANIES}`);
  }

  show(id: number) {
    return this.httpClient.get<IResponse<ICompanies>>(`${ROUTES_PRIVATE.COMPANIES}/${id}`);
  }

  save(data: ICompanies) {
    return this.httpClient.post<IResponse<ICompanies>>(`${ROUTES_PRIVATE.COMPANIES}`, data);
  }

  update(data: ICompanies) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<ICompanies>>(`${ROUTES_PRIVATE.COMPANIES}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<ICompanies>>(`${ROUTES_PRIVATE.COMPANIES}/${id}`);
  }
}
