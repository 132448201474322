import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  IncidentEditAction,
  IncidentGetAction,
  IncidentResendMail,
  IncidentSetShowDisabledAction,
  IncidentUpdateAction
} from './incident-action';
import { Injectable } from '@angular/core';
import { IncidentService } from '@services/incident.service';
import { IIncident } from '@interfaces/incident.interface';
import { Params } from '@angular/router';
import { IResponse } from '@interfaces/api.interface';

export interface IncidentStateModel {
  list: IIncident[];
  show_disable: boolean;
  edit: IIncident;
  list_search: IIncident[];
  response: IResponse<any>;
}

export const StateIncident = 'incident';

@State<IncidentStateModel>({
  name: StateIncident,
  defaults: {
    list: [],
    show_disable: false,
    edit: null,
    list_search: [],
    response: null
  }
})
@Injectable()
export class IncidentState {
  @Selector() static incidentSearch(state: IncidentStateModel) {
    return state.list_search;
  }
  @Selector() static listActive(state: IncidentStateModel) {
    return state.list.filter(i => i.IncidentId);
  }
  @Selector() static showDisabled(state: IncidentStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: IncidentStateModel) {
    return state.edit;
  }

  @Selector() static response(state: IncidentStateModel) {
    return state.response;
  }

  constructor(private incidentService: IncidentService) {}

  @Action(IncidentGetAction)
  CompaniesGet(ctx: StateContext<IncidentStateModel>, { params }: Params) {
    return this.incidentService.getIncidentSearch(params).pipe(
      tap((result: IResponse<IIncident[]>): void => {
        ctx.patchState({
          list_search: result.data
        });
      })
    );
  }

  @Action(IncidentEditAction)
  IncidentEdit(ctx: StateContext<IncidentStateModel>, { data }: IncidentEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(IncidentSetShowDisabledAction)
  PermissionsSetShowDisabled(ctx: StateContext<IncidentStateModel>, { show }: IncidentSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }

  @Action(IncidentUpdateAction)
  updateIncident(ctx: StateContext<IncidentStateModel>, { data }: IncidentUpdateAction) {
    return this.incidentService.putIncident(data).pipe(
      tap(() => {
        ctx.patchState({
          list_search: null
        });
      })
    );
  }

  @Action(IncidentResendMail)
  resendIncidentMail(ctx: StateContext<IncidentStateModel>, { incidentId }: IncidentResendMail) {
    return this.incidentService.resendIncidentEmail(incidentId)
      .pipe(tap(
        (response: IResponse<string>) => ctx.patchState({response})
      ));
  }
}
