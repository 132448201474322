import { IApplicantStatus, IApplicantStatusApply } from '@interfaces/applicant-status.interface';

export class ApplicantStatusGetAction {
  static readonly type = '[ApplicantStatus] List';
}

export class ApplicantStatusAddAction {
  static readonly type = '[ApplicantStatus] Add';
  constructor(public data: IApplicantStatus) {}
}

export class ApplicantStatusApplyEditAction {
  static readonly type = '[ApplicantStatusApply] Edit';
  constructor(public data: IApplicantStatusApply) {}
}

export class ApplicantStatusEditAction {
  static readonly type = '[ApplicantStatus] Edit';
  constructor(public data: IApplicantStatus) {}
}

export class ApplicantStatusUpdateAction {
  static readonly type = '[ApplicantStatus] Update';
  constructor(public data: IApplicantStatus) {}
}

export class ApplicantStatusRemoveAction {
  static readonly type = '[ApplicantStatus] Remove';
  constructor(public id: number) {}
}

export class ApplicantStatusSetShowDisabledAction {
  static readonly type = '[ApplicantStatus] List disabled';
  constructor(public show: boolean) {}
}

export class ApplicantStatusRequestSaveAction {
  static readonly type = '[ApplicantStatusRequestSaveAction] Request save';
}

export const ActionsToProcessApplicantStatus = {
  ApplicantStatusGetAction,
  ApplicantStatusAddAction,
  ApplicantStatusUpdateAction,
  ApplicantStatusRemoveAction
};
