import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PublicService } from '@services/public.service';
import { NationalityGetPublicAction } from './nacionality-action';
import { ICountries, INationality } from '@interfaces/type-citizen.interface';

export interface NationalityStateModel {
  list: INationality[];
}

export const NationalityModel = 'nationality';

@State<NationalityStateModel>({
  name: NationalityModel,
  defaults: {
    list: []
  }
})
@Injectable()
export class NationalityState {
  @Selector() static list(state: NationalityStateModel) {
    return state.list;
  }

  constructor(private publicService: PublicService) {}

  @Action(NationalityGetPublicAction)
  TypeIdentityGetPublic(ctx: StateContext<NationalityStateModel>) {
    return this.publicService.getNationality().pipe(
      tap(result => {
        const countries: INationality[] = result.map((item: ICountries) => ({
          code: item.name.common.trim().toUpperCase(),
          name: item.name.common.trim().toUpperCase()
        }));
        ctx.patchState({
          list: countries.sort((a, b) => (a.name > b.name) ? 1 : -1)
        });
      })
    );
  }
}
