import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { UserPersonalInfo } from '@interfaces/employment/employment-personal-info.interface';
import { ITypeCitizen } from '@interfaces/type-citizen.interface';


@Injectable({
  providedIn: 'root'
})
export class TypeCitizenService {

  constructor(private httpClient: HttpClient) {}

  getTypeCitizen() {
    return this.httpClient.get<IResponse<ITypeCitizen[]>>(`${ROUTES_PRIVATE.TYPE_CITIZEN}`);
  }

  updateTypeCitizen(employee: UserPersonalInfo) {
    return this.httpClient.post<IResponse<ITypeCitizen>>(`${ROUTES_PUBLIC.USERPERSONALINFO}`, employee);
  }
}