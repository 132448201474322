import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  UserSkillsGetAction,
  UserSkillsEditAction,
  UserSkillsAddAction,
  UserSkillsUpdateAction,
  UserSkillsRemoveAction
} from './user-skills-actions';
import { Injectable } from '@angular/core';
import { IUserSkills } from '@interfaces/user/user-skills.intercace';
import { UserSkillsService } from '@services/user/user-skills.service';

export interface UserSkillsStateModel {
  list: IUserSkills[];
  edit: IUserSkills;
}

export const StateUserSkills = 'user_skills';

@State<UserSkillsStateModel>({
  name: StateUserSkills,
  defaults: {
    list: [],
    edit: {}
  }
})
@Injectable()
export class UserSkillsState {
  @Selector() static listSkillsActive(state: UserSkillsStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static editSkill(state: UserSkillsStateModel) {
    return state.edit;
  }

  constructor(private userSkillsService: UserSkillsService) {}

  @Action(UserSkillsGetAction)
  UserSkillsGet(ctx: StateContext<UserSkillsStateModel>) {
    return this.userSkillsService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(UserSkillsEditAction)
  UserSkillsEdit(ctx: StateContext<UserSkillsStateModel>, { data }: UserSkillsEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(UserSkillsAddAction)
  UserSkillsAdd(ctx: StateContext<UserSkillsStateModel>, { data }: UserSkillsAddAction) {
    return this.userSkillsService.save(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(UserSkillsUpdateAction)
  UserSkillsUpdate(ctx: StateContext<UserSkillsStateModel>, { data }: UserSkillsUpdateAction) {
    return this.userSkillsService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: {}
        });
      })
    );
  }

  @Action(UserSkillsRemoveAction)
  UserSkillsRemove(ctx: StateContext<UserSkillsStateModel>, { id }: UserSkillsRemoveAction) {
    return this.userSkillsService.remove(id).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
