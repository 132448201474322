import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import {
  AuthStateModel,
  AutoLoginAction,
  GetInfoCompanyAction,
  GetInfoTokenAction,
  LoginAction,
  LoginEmail,
  LogoutAction,
  RedirectAfterAuthAction,
  RequestChangePasswordAction,
  ResendEmailVerifyAction,
  SignUpAction,
  TokenLogin,
  UnsubscribeAction,
  VerifyEmailAction
} from './auth-actions';
import { tap } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { AppService } from '@services/app.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SCOPES } from '@data/app.constants';
import { ICompanies } from '@interfaces/companies.interface';
import { IUsers } from '@interfaces/security/users.interface';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    scope: null,
    dataToken: {},
    infoCompany: {},
    permission_code: [],
    redirectAuth: {
      path: null
    }
  }
})
@Injectable()
export class AuthState {
  @Selector() static token(state: AuthStateModel): string | null {
    return state.token;
  }
  @Selector() static scope(state: AuthStateModel): string | null {
    return state.scope;
  }
  @Selector() static dataToken(state: AuthStateModel): IUsers | null {
    return state.dataToken;
  }
  @Selector() static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }
  @Selector() static infoCompany(state: AuthStateModel): ICompanies | null {
    return state.infoCompany;
  }
  @Selector() static permissionUser(state: AuthStateModel) {
    return state.permission_code;
  }

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private router: Router
  ) {}

  @Action(GetInfoTokenAction)
  GetInfoToken(ctx: StateContext<AuthStateModel>) {
    return this.authService.info().pipe(
      tap(
        result => {

          const uniqueRolCode = result.permissions?.reduce((acc, permission) => {
            if (permission.rol_permission?.permission?.code) {
              acc.add(permission.rol_permission?.permission?.code);
            }
            return acc;
          }, new Set<string>());
          ctx.patchState({
            dataToken: result,
            permission_code: Array.from(uniqueRolCode)
          });
        },
        () => {
          // tslint:disable-next-line:no-unused-expression
          LoginAction;
          ctx.patchState({
            dataToken: null
          });
        }
      )
    );
  }

  @Action(GetInfoCompanyAction)
  GetInfoCompany(ctx: StateContext<AuthStateModel>) {
    return this.appService.infoCompany().pipe(
      tap(
        result => {
          ctx.patchState({
            infoCompany: result.data
          });
        },
        () => {
          ctx.patchState({
            infoCompany: null
          });
        }
      )
    );
  }

  @Action(LoginAction)
  login(ctx: StateContext<AuthStateModel>, { payload }: LoginAction) {
    return this.authService.login(payload).pipe(
      tap(
        result => {
          ctx.patchState({
            token: result.data.access_token,
            scope: result.data.scope,
            dataToken: null
          });
          if (ctx.getState().redirectAuth?.path) {
            this.router.navigateByUrl(ctx.getState().redirectAuth?.path);
            ctx.dispatch(new RedirectAfterAuthAction(null));
          }
        },
        () => {
          ctx.patchState({
            token: null,
            scope: null,
            dataToken: null
          });
        }
      )
    );
  }

  @Action(SignUpAction)
  SignUp(ctx: StateContext<AuthStateModel>, { payload }: SignUpAction) {
    return this.authService.signup(payload).pipe(
      tap(
        result => {
          const token = result.token;
          const scope = result.scope;
          delete result.token;
          ctx.patchState({
            token: token,
            scope: scope,
            dataToken: result
          });
          if (ctx.getState().redirectAuth?.path) {
            this.router.navigateByUrl(ctx.getState().redirectAuth?.path);
            ctx.dispatch(new RedirectAfterAuthAction(null));
          }
        },
        error => {
          Swal.fire(error.error.message, '', 'error');
          ctx.patchState({
            token: null,
            scope: null,
            dataToken: null
          });
        }
      )
    );
  }

  @Action(LogoutAction)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      token: null,
      scope: null,
      dataToken: null
    });
  }

  @Action(RedirectAfterAuthAction)
  RedirectAfterAuth(ctx: StateContext<AuthStateModel>, { path }: RedirectAfterAuthAction) {
    ctx.patchState({
      redirectAuth: {
        path
      }
    });
  }

  @Action(UnsubscribeAction)
  Unsubscribe(ctx: StateContext<AuthStateModel>, { token }: UnsubscribeAction) {
    return this.authService.unsubscribe(token);
  }

  @Action(ResendEmailVerifyAction)
  ResendEmailVerify() {
    return this.authService.send_email_verify();
  }

  @Action(VerifyEmailAction)
  VerifyEmail(ctx: StateContext<AuthStateModel>, { token }: VerifyEmailAction) {
    return this.authService.verify_email(token);
  }

  @Action(AutoLoginAction)
  AutoLogin(ctx: StateContext<AuthStateModel>, { token }: AutoLoginAction) {
    ctx.patchState({
      token: token,
      scope: SCOPES.PUBLIC
    });
  }

  @Action(RequestChangePasswordAction)
  RequestChangePassword(ctx: StateContext<AuthStateModel>, { email }: RequestChangePasswordAction) {
    return this.authService.request_change_password(email);
  }

  @Action(LoginEmail)
  LoginEmail(ctx: StateContext<AuthStateModel>, { email }: LoginEmail) {
    return this.authService.login_email(email);
  }

  @Action(TokenLogin)
  EmailLogin(ctx: StateContext<AuthStateModel>, { token }: TokenLogin) {
    return this.authService.auth_token_login(token).pipe(
      tap(
        result => {
          ctx.patchState({
            token: result.data.access_token,
            scope: result.data.scope,
            dataToken: null
          });
          if (ctx.getState().redirectAuth?.path) {
            this.router.navigateByUrl(ctx.getState().redirectAuth?.path + 'employee');
            ctx.dispatch(new RedirectAfterAuthAction(null));
          }
        },
        () => {
          ctx.patchState({
            token: null,
            scope: null,
            dataToken: null
          });
        }
      )
    );
  }
}
