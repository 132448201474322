export const extensionsFiles = {
  'application/pdf': 'pdf',
  'text/plain': 'txt',

  'image/png': 'png',
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpg',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',

  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.ms-word.document.macroEnabled.12': 'docm',
  'application/rtf': 'rtf',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
  'application/vnd.ms-powerpoint': 'ppt',
  'text/csv': 'csv'
};

export const acceptedMimesUpload = Object.entries(extensionsFiles)
  .map(([key]) => key)
  .join();
export const acceptedExtensionUpload = Object.entries(extensionsFiles)
  .map(([value]) => value)
  .join(', ');
