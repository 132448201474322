import { LoginData, RegisterData } from '@interfaces/auth.interface';
import { ICompanies } from '@interfaces/companies.interface';
import { IUsers } from '@interfaces/security/users.interface';

export interface AuthStateModel {
  token: string | null;
  scope: string | null;
  dataToken: IUsers | null;
  infoCompany: ICompanies | null;
  permission_code: string[];
  redirectAuth: {
    path: string;
  };
}

export class LoginAction {
  static readonly type = '[Auth] Login';
  static readonly errorType = 'warning';
  static readonly errorTitle = 'Invalid email or password';
  static readonly errorBody = 'Please check the data entered';
  constructor(public payload: LoginData) {}
}

export class SignUpAction {
  static readonly type = '[Auth] SignUp';
  static readonly skipError = true;
  constructor(public payload: RegisterData) {}
}

export class LogoutAction {
  static readonly type = '[Auth] Logout';
}

export class GetInfoTokenAction {
  static readonly type = '[Auth] Info Token';
}

export class GetInfoCompanyAction {
  static readonly type = '[Auth] Info Company';
}

export class RedirectAfterAuthAction {
  static readonly type = '[Auth] Redirect after out';
  constructor(
    public path: string
  ) {}
}

export class RequestLoginAction {
  static readonly type = '[Auth] Request Login Form';
}

export class RequestSigupAction {
  static readonly type = '[Auth] Request Signup Form';
}

export class UnsubscribeAction {
  static readonly type = '[Auth] Unsubscribe';
  static readonly skipError = true;
  constructor(public token: string) {}
}

export class ResendEmailVerifyAction {
  static readonly type = '[Auth] Send email verify';
}

export class VerifyEmailAction {
  static readonly type = '[Auth] Verify email';
  constructor(public token: string) {}
}

export class AutoLoginAction {
  static readonly type = '[Auth] Auto login';
  constructor(public token: string) {}
}

export class RequestChangePasswordAction {
  static readonly type = '[Auth] Request change password';
  constructor(public email: string) {}
}

export class LoginEmail {
  static readonly type = '[Auth] Login Email';
  constructor(public email: string) {}
}

export class TokenLogin {
  static readonly type = '[Auth] Token Login';
  constructor(public token: string) {}
}

export const ActionsToProcessAuth = {
  LoginAction,
  SignUpAction,
  LogoutAction,
  GetInfoTokenAction,
  GetInfoCompanyAction,
  UnsubscribeAction,
  ResendEmailVerifyAction,
  AutoLoginAction,
  RequestChangePasswordAction,
  LoginEmail,
  TokenLogin
};
