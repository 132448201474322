import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IApplicantStatus } from '@interfaces/applicant-status.interface';
import {
  ApplicantStatusAddAction,
  ApplicantStatusApplyEditAction,
  ApplicantStatusEditAction,
  ApplicantStatusGetAction,
  ApplicantStatusRemoveAction,
  ApplicantStatusSetShowDisabledAction,
  ApplicantStatusUpdateAction
} from './applicant-status.actions';
import { ApplicantStatusService } from '@services/applicant-status.service';

export interface ApplicantStatusStateModel {
  list: IApplicantStatus[];
  show_disable: boolean;
  edit: IApplicantStatus;
}

export const StateApplicantStatus = 'applicant_status';

@State<ApplicantStatusStateModel>({
  name: StateApplicantStatus,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class ApplicantStatusState {
  @Selector() static list(state: ApplicantStatusStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: ApplicantStatusStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: ApplicantStatusStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: ApplicantStatusStateModel) {
    return state.edit;
  }

  constructor(private applicantStatusService: ApplicantStatusService) {}

  @Action(ApplicantStatusGetAction)
  ApplicantStatusGet(ctx: StateContext<ApplicantStatusStateModel>) {
    return this.applicantStatusService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(ApplicantStatusEditAction)
  ApplicantStatusEdit(ctx: StateContext<ApplicantStatusStateModel>, { data }: ApplicantStatusEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(ApplicantStatusAddAction)
  ApplicantStatusAdd(ctx: StateContext<ApplicantStatusStateModel>, { data }: ApplicantStatusAddAction) {
    return this.applicantStatusService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(ApplicantStatusUpdateAction)
  ApplicantStatusUpdate(ctx: StateContext<ApplicantStatusStateModel>, { data }: ApplicantStatusUpdateAction) {
    return this.applicantStatusService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(ApplicantStatusRemoveAction)
  ApplicantStatusRemove(ctx: StateContext<ApplicantStatusStateModel>, { id }: ApplicantStatusRemoveAction) {
    return this.applicantStatusService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(ApplicantStatusSetShowDisabledAction)
  ApplicantStatusSetShowDisabled(
    ctx: StateContext<ApplicantStatusStateModel>,
    { show }: ApplicantStatusSetShowDisabledAction
  ) {
    ctx.patchState({
      show_disable: show
    });
  }

  @Action(ApplicantStatusApplyEditAction)
  ApplicantStatusApplyUpdate(ctx: StateContext<ApplicantStatusStateModel>, { data }: ApplicantStatusApplyEditAction) {
    return this.applicantStatusService.updateStateApply(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }
}
