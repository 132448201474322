import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IIncident } from '@interfaces/incident.interface';
import { IResponse } from '@interfaces/api.interface';
import { Params } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  constructor(private httpClient: HttpClient) {}

  getIncidentSearch(params: Params): Observable<IResponse<IIncident[]>> {
    const url: string = ROUTES_PUBLIC.INCIDENT_DATA;
    return this.httpClient.get<IResponse<IIncident[]>>(url, {params});
  }

  putIncident(data: IIncident) {
    const id = data.IncidentId;
    delete data.IncidentId;
    return this.httpClient.put<IResponse<IIncident>>(`${ROUTES_PUBLIC.INCIDENT_DATA}/${id}`, data);
  }

  resendIncidentEmail(incidentId: number): Observable<IResponse<string>> {
    const url = `${ROUTES_PUBLIC.INCIDENT}/resend_email/${incidentId}`;
    return this.httpClient.get<IResponse<string>>(url);
  }
}
