import { IMedicalReportStatus } from '@interfaces/medical-report/medical-report.interface';

export class MedicalReportStatusGetAction {
  static readonly type = '[MedicalReportStatus] List';
}

export class MedicalPossibleStatusGetAction {
  static readonly type = '[MedicalPossibleStatus] List';
  constructor(public id: number) {}
}

export class MedicalPossibleStatusSetShowDisabledAction {
  static readonly type = '[MedicalPossibleStatus] List disabled';
  constructor(public show: boolean) {}
}

export class MedicalReportStatusEditAction {
  static readonly type = '[MedicalReportStatus] Edit';
  constructor(public data: IMedicalReportStatus) {}
}

export class MedicalReportStatusUpdateAction {
  static readonly type = '[MedicalReportStatus] Update';
  constructor(public data: IMedicalReportStatus) {}
}

export class MedicalReportStatusAddAction {
  static readonly type = '[MedicalReportStatus] Add';
  constructor(public data: IMedicalReportStatus) {}
}

export class MedicalReportStatusDeleteAction {
  static readonly type = '[MedicalReportStatus] Delete';
  constructor(public data: number) {}
}

export const ActionsToProcessMedicalStatus = {
  MedicalReportStatusGetAction,
  MedicalPossibleStatusGetAction,
  MedicalReportStatusAddAction,
  MedicalReportStatusUpdateAction,
  MedicalReportStatusDeleteAction
};
