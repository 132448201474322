import { Injectable } from '@angular/core';
import { IResponse } from '@interfaces/api.interface';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IEmploymentPersonalInfo, UserPersonalInfo } from '@interfaces/employment/employment-personal-info.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployeePersonalInfoService {
  constructor(private http: HttpClient) {}

  getEmployeePersonalInfo() {
    return this.http.get<IResponse<UserPersonalInfo>>(`${ROUTES_PUBLIC.EMPLOYE_PERSONAL_INTO}`);
  }

  updateEmployePersonalInfo(employe: IEmploymentPersonalInfo) {
    return this.http.put<IResponse<UserPersonalInfo>>(`${ROUTES_PUBLIC.USERPERSONALINFO}/${employe.user_id}`, employe);
  }

  createEmployePersonalInfo(employe: IEmploymentPersonalInfo) {
    return this.http.post<IResponse<UserPersonalInfo>>(`${ROUTES_PUBLIC.USERPERSONALINFO}`, employe);
  }
}
