import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { MedicalReportCompaniesState
} from '@redux/medical-report/medical-report-companies/medical-report-companies.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ticket-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderTicketComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  readonly TA_LOGO: string = '/assets/images/ta-logo.jpg';
  logoDefault: string;

  constructor(
     private store: Store
  ) { }

  ngOnInit(): void {
    this.store
      .select(MedicalReportCompaniesState.infoMedicalCompanies)
      .pipe(takeUntil(this.destroy))
      .subscribe({
          next: (data) => this.logoDefault = data?.logo ?? this.TA_LOGO
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
