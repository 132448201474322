import { IMedicalCompanies } from '@interfaces/medical-report/medical-report-companies.interface';

export class MedicalReportCompaniesAddAction {
  static readonly type = '[MedicalReportCompanies] Add';
  constructor(public data: IMedicalCompanies) {}
}

export class MedicalReportCompaniesGetAction {
  static readonly type = '[MedicalReportCompanies] List';
}

export class MedicalReportCompaniesEditAction {
  static readonly type = '[MedicalReportCompanies] Edit';
  constructor(public data: IMedicalCompanies) {}
}

export class MedicalReportCompaniesUpdateAction {
  static readonly type = '[MedicalReportCompanies] Update';
  constructor(public data: IMedicalCompanies) {}
}

export class MedicalReportCompaniesRemoveAction {
  static readonly type = '[MedicalReportCompanies] Remove';
  constructor(public id: number) {}
}

export class MedicalReportCompaniesSetShowDisabledAction {
  static readonly type = '[MedicalReportCompanies] List disabled';
  constructor(public show: boolean) {}
}

export class MedicalReportCompaniesGetIdAction {
  static readonly type = '[MedicalReportCompaniesGetId] Get';
  constructor(public id: number) {}
}

export class MedicalReportCompaniesGetCodeAction {
  static readonly skipError = true;
  static readonly type = '[MedicalReportCompaniesGetCode] Get';
  constructor(public code: string) {}
}

export class MedicalReportCompaniesClearFormAction {
  static readonly type = '[MedicalReportCompanies] ClearCompany';
}

export const ActionsToProcessMedicalCompaniesReport = {
  MedicalReportCompaniesAddAction,
  MedicalReportCompaniesGetAction,
  MedicalReportCompaniesUpdateAction,
  MedicalReportCompaniesRemoveAction,
  MedicalReportCompaniesGetIdAction,
  MedicalReportCompaniesGetCodeAction,
  MedicalReportCompaniesClearFormAction
};
