import { IResponse } from '@interfaces/api.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IUserPersonalInfo } from '@interfaces/user/user-personal-info.interface';

@Injectable({
  providedIn: 'root'
})
export class UserPersonalInfoService {
  constructor(private http: HttpClient) {}

  getUserPersonalInfo() {
    return this.http.get<IResponse<IUserPersonalInfo>>(`${ROUTES_PUBLIC.USERPERSONALINFO}`);
  }

  updateUserPersonalInfo(user: IUserPersonalInfo) {
    return this.http.post<IResponse<IUserPersonalInfo>>(`${ROUTES_PUBLIC.USERPERSONALINFO}`, user);
  }
}
