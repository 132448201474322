import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IPermissionsTypes } from '@interfaces/security/permissions-types.interface';

@Injectable({ providedIn: 'root' })
export class PermissionsTypesService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IPermissionsTypes[]>>(`${ROUTES_PRIVATE.PERMISSIONS_TYPES}`);
  }

  save(data: IPermissionsTypes) {
    return this.httpClient.post<IResponse<IPermissionsTypes>>(`${ROUTES_PRIVATE.PERMISSIONS_TYPES}`, data);
  }

  update(data: IPermissionsTypes) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IPermissionsTypes>>(`${ROUTES_PRIVATE.PERMISSIONS_TYPES}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IPermissionsTypes>>(`${ROUTES_PRIVATE.PERMISSIONS_TYPES}/${id}`);
  }
}
