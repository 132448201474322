import { IParameterPublic, IParameterValues } from '@interfaces/general-parameters/general-parameter.interface';

export abstract class ParameterValueAction {
  readonly type = '[] Get';
  constructor(
    public reference: string,
    public code: string
  ) {}
}

export class GetMedicalReportTypes extends ParameterValueAction {
  static readonly type = '[GetMedicalReportTypes] Get';
  constructor(public reference: string, public code: string) {
    super(reference, code);
  }
}

export class GetMedicalReportShippingMethods extends ParameterValueAction {
  static readonly type = '[GetMedicalReportShippingMethods] Get';
  constructor(public reference: string, public code: string) {
    super(reference, code);
  }
}

export class GetMedicalReportAttachmentTypes extends ParameterValueAction {
  static readonly type = '[GetMedicalReportAttachmentTypes] Get';
  constructor(public reference: string, public code: string) {
    super(reference, code);
  }
}

export class GetParameters {
  static readonly type = '[GetParameters] Get';
}

export class ParametersSetShowDisabledAction {
  static readonly type = '[Parameters] List disabled';
  constructor(public show: boolean) {}
}

export class ParametersEditAction {
  static readonly type = '[Parameters] Edit';
  constructor(public data: IParameterPublic) {}
}

export class ParametersUpdateAction {
  static readonly skipError = true;
  static readonly type = '[Parameters] Update';
  constructor(public data: IParameterPublic) {}
}

export class ParametersAddAction {
  static readonly type = '[Parameters] Add';
  static readonly skipError = true;
  constructor(public data: IParameterPublic) {}
}

export class ParametersValuesUpdateAction {
  static readonly skipError = true;
  static readonly type = '[ParametersValues] Update';
  constructor(public data: IParameterValues) {}
}

export class ParametersValuesAddAction {
  static readonly skipError = true;
  static readonly type = '[ParametersValues] Add';
  constructor(public data: IParameterValues) {}
}

export class ParametersValuesGetAction {
  static readonly type = '[ParametersValues] Get';
  constructor(public data: IParameterValues) {}
}

export class ParametersValuesEditAction {
  static readonly type = '[ParametersValues] Edit';
  constructor(public data: IParameterValues) {}
}

export const ActionsToProcessGeneralParameter = {
  GetMedicalReportTypes,
  GetMedicalReportShippingMethods,
  GetMedicalReportAttachmentTypes,
  GetParameters,
  ParametersUpdateAction,
  ParametersAddAction,
  ParametersValuesUpdateAction,
  ParametersValuesAddAction
};
