import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  ClearStateAction,
  CreateEmployePersonalInfoAction,
  GetEmployeePersonalInfoAction,
  UpdateEmployePersonalInfoAction,
  ValidFormAction
} from './employee-personal-info-actions';
import { EmployeePersonalInfoService } from '@services/employee/employee-personal-info.service';
import { UserPersonalInfo } from '@interfaces/employment/employment-personal-info.interface';
import { IEmploymentEducation } from '@interfaces/employment/employment-education.interface';
import { IWorkInfo } from '@interfaces/employment/employment-works.interface';

export interface EmployeeInfoStateModel {
  employeePersonalInfo: UserPersonalInfo;
  listEducation: IEmploymentEducation[];
  listWork: IWorkInfo[];
  validFrom: boolean;
}

export const StateEmployeePersonal = 'employee_personal_info';

const defaults = {
  employeePersonalInfo: {},
  listEducation: [],
  listWork: [],
  validFrom: null
};

@State<EmployeeInfoStateModel>({
  name: StateEmployeePersonal,
  defaults
})
@Injectable()
export class EmployeePersonalState {
  @Selector() static employeePersonalInfo(state: EmployeeInfoStateModel) {
    return state.employeePersonalInfo;
  }
  @Selector() static listEducationEmploye(state: EmployeeInfoStateModel) {
    return state.listEducation.filter(i => i.active);
  }
  @Selector() static listWorkEmploye(state: EmployeeInfoStateModel) {
    return state.listWork.filter(i => i.active);
  }
  @Selector() static validForm(state: EmployeeInfoStateModel) {
    return state.validFrom;
  }

  constructor(private employeeService: EmployeePersonalInfoService) {}

  @Action(ClearStateAction)
  StateClear(ctx: StateContext<EmployeeInfoStateModel>) {
    ctx.setState(defaults);
  }

  @Action(GetEmployeePersonalInfoAction)
  EmployeeGet(ctx: StateContext<EmployeeInfoStateModel>) {
    return this.employeeService.getEmployeePersonalInfo().pipe(
      tap(result => {
        if (result.data && result.data['user']) {
          const { educations, works } = result.data['user'];
          const patchStateObj = {
            employeePersonalInfo: result.data,
            listEducation: educations || [],
            listWork: works || []
          };
          ctx.patchState(patchStateObj);
        }
      })
    );
  }

  @Action(UpdateEmployePersonalInfoAction)
  updateUserPersonalInfo(ctx: StateContext<EmployeeInfoStateModel>, { payload }: UpdateEmployePersonalInfoAction) {
    return this.employeeService.updateEmployePersonalInfo(payload).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(CreateEmployePersonalInfoAction)
  createUserPersonalInfo(ctx: StateContext<EmployeeInfoStateModel>, { payload }: CreateEmployePersonalInfoAction) {
    return this.employeeService.createEmployePersonalInfo(payload).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(ValidFormAction)
  validateForm(ctx: StateContext<EmployeeInfoStateModel>, { payload }: ValidFormAction) {
    ctx.patchState({ validFrom: payload });
  }
}
