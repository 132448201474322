import { IEmploymentPersonalInfo } from '@interfaces/employment/employment-personal-info.interface';

export class GetEmployeePersonalInfoAction {
  static readonly type = '[EmployeePersonalInfo] Get';
}

export class UpdateEmployePersonalInfoAction {
  static readonly type = '[EmploymentPersonalInfo] Update';
  constructor(public payload: IEmploymentPersonalInfo) {}
}

export class CreateEmployePersonalInfoAction {
  static readonly type = '[EmploymentPersonalInfo] Create';
  constructor(public payload: IEmploymentPersonalInfo) {}
}

export class ClearStateAction {
  static readonly type = '[EmployeePersonalInfo] clear';
}

export class ValidFormAction {
  static readonly type = '[ValidFormAction] ValidForm';
  constructor(public payload: boolean) {}
}

export class PersonalInfoRequestSaveAction {
  static readonly type = '[PersonalInfo] Request save';
}

export const ActionsToEmployePersonalInfo = {
  GetEmployeePersonalInfoAction,
  UpdateEmployePersonalInfoAction,
  CreateEmployePersonalInfoAction,
  ClearStateAction,
  ValidFormAction
};
