import { IUserLanguages } from '@interfaces/user/user-languages.interface';

export class UserLanguagesGetAction {
  static readonly type = '[UserLanguages] List';
}

export class UserLanguagesAddAction {
  static readonly type = '[UserLanguages] Add';
  constructor(public data: IUserLanguages) {}
}

export class UserLanguagesEditAction {
  static readonly type = '[UserLanguages] Edit';
  constructor(public data: IUserLanguages) {}
}

export class UserLanguagesUpdateAction {
  static readonly type = '[UserLanguages] Update';
  constructor(public data: IUserLanguages) {}
}

export class UserLanguagesRemoveAction {
  static readonly type = '[UserLanguages] Remove';
  constructor(public id: number) {}
}

export class UserLanguagesRequestSaveAction {
  static readonly type = '[UserLanguages] Request save';
}

export const ActionsToProcessUserLanguages = {
  UserLanguagesGetAction,
  UserLanguagesAddAction,
  UserLanguagesUpdateAction,
  UserLanguagesRemoveAction
};
