import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IPermissionsModules } from '@interfaces/security/permissions-modules.interface';

@Injectable({ providedIn: 'root' })
export class PermissionsModulesService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IPermissionsModules[]>>(`${ROUTES_PRIVATE.PERMISSIONS_MODULES}`);
  }

  getTree() {
    return this.httpClient.get<IResponse<IPermissionsModules[]>>(`${ROUTES_PRIVATE.PERMISSIONS_MODULES}/tree/list`);
  }

  getTreeActions() {
    return this.httpClient
    .get<IResponse<IPermissionsModules[]>>(`${ROUTES_PRIVATE.PERMISSIONS_MODULES}/tree/list_actions`);
  }

  save(data: IPermissionsModules) {
    return this.httpClient.post<IResponse<IPermissionsModules>>(`${ROUTES_PRIVATE.PERMISSIONS_MODULES}`, data);
  }

  update(data: IPermissionsModules) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IPermissionsModules>>(`${ROUTES_PRIVATE.PERMISSIONS_MODULES}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IPermissionsModules>>(`${ROUTES_PRIVATE.PERMISSIONS_MODULES}/${id}`);
  }
}
