import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_AUTH } from '@data/app.constants';
import { Store } from '@ngxs/store';
import { GetInfoCompanyAction, GetInfoTokenAction } from '@redux/auth/auth-actions';
import { AuthState } from '@redux/auth/auth-state';
import { NgxPermissionsService } from 'ngx-permissions';
import { IResponse } from '@interfaces/api.interface';
import { ICompanies } from '@interfaces/companies.interface';

@Injectable({ providedIn: 'root' })
export class AppService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private permissionsService: NgxPermissionsService
  ) {}

  async onLoad() {
    this.getInfoCompany();
    const token: string = this.store.selectSnapshot(AuthState.token);
    if (token) {
      return this.verifyTokenValid()
        .then(async value => {
          if (value) {
            await this.getInfoUser();
          }
        })
        .catch(() => true);
    }
  }

  async verifyTokenValid() {
    return new Promise(resolve => {
      return this.httpClient
        .get<IResponse<string>>(`${ROUTES_AUTH.VERIFY}`)
        .toPromise()
        .then(() => resolve(true))
        .catch(() => resolve(false));
    });
  }

  async getInfoCompany() {
    this.store.dispatch(new GetInfoCompanyAction());
  }

  async getInfoUser() {
    return new Promise(resolve => {
      this.store.dispatch(new GetInfoTokenAction()).subscribe(async state => {
        if (state.auth.dataToken) {
          await this.loadPermissions();
          resolve(true);
        }
      });
    });
  }

  async getInfoUserToken() {
    return new Promise(resolve => {
      this.store.dispatch(new GetInfoTokenAction()).subscribe(async state => {
        if (state.auth.dataToken) {
          await this.loadPermissions();
          resolve(true);
        }
      });
    });
  }

  async loadPermissions() {
    const roles = await this.store.selectSnapshot(AuthState.dataToken)?.permissions;
    const perm = roles.map(i => i.rol_permission.permission.code);
    await this.permissionsService.loadPermissions(perm);
  }

  infoCompany() {
    return this.httpClient.get<IResponse<ICompanies>>(`${ROUTES_AUTH.INFO_COMPANY}`);
  }

  async token_login(token: string) {
    return this.httpClient.get<IResponse<string>>(`${ROUTES_AUTH.TOKEN_LOGIN}/${token}`);
  }
}
