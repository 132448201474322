import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { TypeEducationGetPublicAction } from './type-education-actions';
import { Injectable } from '@angular/core';
import { PublicService } from '@services/public.service';
import { IEmploymentTypeEducation } from '@interfaces/employment/employment-education.interface';

export interface TypeEducationStateModel {
  list: IEmploymentTypeEducation[];
}

export const StateTypeEducation = 'type_education';

@State<TypeEducationStateModel>({
  name: StateTypeEducation,
  defaults: {
    list: []
  }
})
@Injectable()
export class TypeEducationState {
  @Selector() static list(state: TypeEducationStateModel) {
    return state.list;
  }

  constructor(private publicService: PublicService) {}

  @Action(TypeEducationGetPublicAction)
  typeEducacionGetPublic(ctx: StateContext<TypeEducationStateModel>) {
    return this.publicService.getTypeEducation().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data.slice().sort((a, b) => a.id - b.id)
        });
      })
    );
  }
}
