import { Injectable } from '@angular/core';
import { QuestionStateModel } from '@interfaces/questions-types.interface';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ClearCurrentQuestion, QuestionSetDataAction, QuestionTempAdd, QuestionTempGetAll,
  QuestionTempSetShowDisabledAction, QuestionTempUpdate } from '@redux/questions/questions.actions';
import { QuestionsService } from '@services/questions.service';

export const StateQuestions = 'questions';

@State <QuestionStateModel>({
  name: StateQuestions,
  defaults: {
    list: [],
    currentQuestion: null,
    show_disabled: false
  }
})

@Injectable()
export class QuestionsState {
  constructor(private questionsService: QuestionsService) {}
  @Selector() static listQuestionsActive(state: QuestionStateModel) {
    return state.list.filter((i) => Number(i.active));
  }
  @Selector() static listQuestionsInactive(state: QuestionStateModel) {
    return state.list.filter((i) => !Number(i.active));
  }
  @Selector() static currentQuestion(state: QuestionStateModel) {
    return state.currentQuestion;
  }
  @Selector() static showDisabled(state: QuestionStateModel): boolean {
    return state.show_disabled;
  }
  @Action(QuestionTempGetAll)
  QuestionsGetAll(ctx: StateContext<QuestionStateModel>, {category}: QuestionTempGetAll) {
    return this.questionsService.getByCategory(category).pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }
  @Action(QuestionTempSetShowDisabledAction)
  QuestionTempSetShowDisabled(ctx: StateContext<QuestionStateModel>,
                              { show }: QuestionTempSetShowDisabledAction) {
    ctx.patchState({
      show_disabled: show
    });
  }
  @Action(ClearCurrentQuestion)
  clearCurrentQuestion(ctx: StateContext<QuestionStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentQuestion: null
    });
  }
  @Action(QuestionSetDataAction)
  getParameterTypeById(ctx: StateContext<QuestionStateModel>, { data }: QuestionSetDataAction) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentQuestion: data
    });
  }
  @Action(QuestionTempAdd)
  ParameterAdd(ctx: StateContext<QuestionStateModel>, { data }: QuestionTempAdd) {
    return this.questionsService.save(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
  @Action(QuestionTempUpdate)
  ParameterUpdate(ctx: StateContext<QuestionStateModel>, { data }: QuestionTempUpdate) {
    return this.questionsService.update(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
