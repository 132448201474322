import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  ParametersTempAddAction,
  ParametersGetAllAction,
  ParametersTempSetShowDisabledAction,
  ParametersTypeGetAllAction, ParametersTempUpdateAction,
  ParametersGetByIdAction,
  ClearCurrentParameter,
  ParametersTypeSetShowDisabledAction,
  ParametersTypeSetDataAction,
  ParametersTypeAddAction,
  ParametersTypeUpdateAction,
  ParametersTypeGetTreeAction, ParametersKeysAllAction
} from './parameters-actions';
import { Injectable } from '@angular/core';
import { ParameterStateModel } from '@interfaces/parameters.interface';
import { ParameterService } from '@services/dynamic-docs/parameter.service';

export const StateParameters = 'parameters';

@State<ParameterStateModel>({
  name: StateParameters,
  defaults: {
    list: [],
    currentParameter: null,
    currentParameterType: null,
    list_types: null,
    list_types_tree: null,
    show_disabled: false,
    show_type_disabled: false,
    list_parameters_keys: null
  }
})
@Injectable()
export class ParameterState {
  constructor(private parameterService: ParameterService) {}


  @Selector() static listParametersKeysAll(state: ParameterStateModel) {
    return state.list_parameters_keys;
  }
  @Selector() static listParametersTypeTree(state: ParameterStateModel) {
    return state.list_types_tree;
  }
  @Selector() static listParametersActive(state: ParameterStateModel) {
    return state.list.filter((i) => i.active);
  }
  @Selector() static listParametersInactive(state: ParameterStateModel) {
    return state.list.filter((i) => !i.active);
  }
  @Selector() static listParametersTypesActive(state: ParameterStateModel) {
    return state.list_types.filter((i) => i.active);
  }
  @Selector() static listParametersTypesInactive(state: ParameterStateModel) {
    return state.list_types.filter((i) => !i.active);
  }
  @Selector() static showDisabled(state: ParameterStateModel): boolean {
    return state.show_disabled;
  }
  @Selector() static showTypesDisabled(state: ParameterStateModel): boolean {
    return state.show_type_disabled;
  }
  @Selector() static currentParameter(state: ParameterStateModel) {
    return state.currentParameter;
  }
  @Selector() static currentParameterType(state: ParameterStateModel) {
    return state.currentParameterType;
  }
  @Action(ParametersGetAllAction)
  ParametersGetAll(ctx: StateContext<ParameterStateModel>) {
    return this.parameterService.getAll().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }
  @Action(ParametersTypeGetAllAction)
  ParameterTypesGetAll(ctx: StateContext<ParameterStateModel>) {
    return this.parameterService.getTypesAll().pipe(
      tap(result => {
        ctx.patchState({
          list_types: result.data
        });
      })
    );
  }
  @Action(ParametersKeysAllAction)
  ParametersKeysAll(ctx: StateContext<ParameterStateModel>) {
    return this.parameterService.getParametersKeysAll().pipe(
      tap(result => {
        ctx.patchState({
          list_parameters_keys: result.data
        });
      })
    );
  }
  @Action(ParametersGetByIdAction)
  getParameterById(ctx: StateContext<ParameterStateModel>, { id }: ParametersGetByIdAction) {
    return this.parameterService.getById(id).pipe(
      tap(result => {
        ctx.patchState({
          currentParameter: result.data
        });
      })
    );
  }
  @Action(ParametersTypeSetDataAction)
  getParameterTypeById(ctx: StateContext<ParameterStateModel>, { data }: ParametersTypeSetDataAction) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentParameterType: data
    });
  }
  @Action(ParametersTempSetShowDisabledAction)
  ParametersTempSetShowDisabled(ctx: StateContext<ParameterStateModel>,
                                { show }: ParametersTempSetShowDisabledAction) {
    ctx.patchState({
      show_disabled: show
    });
  }
  @Action(ParametersTypeSetShowDisabledAction)
  ParametersTypesSetShowDisabled(ctx: StateContext<ParameterStateModel>,
                                 { show }: ParametersTypeSetShowDisabledAction) {
    ctx.patchState({
      show_type_disabled: show
    });
  }
  @Action(ParametersTempAddAction)
  ParameterAdd(ctx: StateContext<ParameterStateModel>, { data }: ParametersTempAddAction) {
    return this.parameterService.save(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
  @Action(ParametersTempUpdateAction)
  ParameterUpdate(ctx: StateContext<ParameterStateModel>, { data }: ParametersTempUpdateAction) {
    return this.parameterService.update(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
  @Action(ParametersTypeAddAction)
  ParameterTypeAdd(ctx: StateContext<ParameterStateModel>, { data }: ParametersTypeAddAction) {
    return this.parameterService.saveType(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
  @Action(ParametersTypeUpdateAction)
  ParameterTypeUpdate(ctx: StateContext<ParameterStateModel>, { data }: ParametersTypeUpdateAction) {
    return this.parameterService.updateType(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
  @Action(ClearCurrentParameter)
  clearCurrentParameter(ctx: StateContext<ParameterStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentParameter: null
    });
  }
  @Action(ParametersTypeGetTreeAction)
  getTreeTemplateTypes(ctx: StateContext<ParameterStateModel>) {
    return this.parameterService.getTree().pipe(
      tap(result => {
        ctx.patchState({
          list_types_tree: result.data
        });
      })
    );
  }
}
