import { UserPersonalInfo } from "@interfaces/employment/employment-personal-info.interface";


export class MaritalStatusGetPublicAction {
  static readonly type = '[PublicMaritalStatus] List';
}

export class MaritalStatusRequestSaveAction {
  static readonly type = '[MaritalStatusRequestSaveAction] Request save';
}

export class MaritalStatusAddAction {
  static readonly type = '[MaritalStatusAddAction] Add';
  constructor(public payload: UserPersonalInfo) {}
}

export const ActionsToProcessStates = {
  MaritalStatusGetPublicAction,
  MaritalStatusAddAction
};
