export class CronJobsExecutionGetAction {
  static readonly type = '[CronJobsExecution] List';
}

export class CronJobsExecutionClearAction {
  static readonly type = '[CronJobsExecution] Clear';
}

export const ActionsToProcessCronJobsExecution = {
  CronJobsExecutionGetAction,
  CronJobsExecutionClearAction
};
