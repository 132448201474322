import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  CompaniesGetAction,
  CompaniesEditAction,
  CompaniesAddAction,
  CompaniesUpdateAction,
  CompaniesRemoveAction,
  CompaniesSetShowDisabledAction
} from './companies-actions';
import { Injectable } from '@angular/core';
import { CompaniesService } from '@services/companies.service';
import { ICompanies } from '@interfaces/companies.interface';

export interface CompaniesStateModel {
  list: ICompanies[];
  show_disable: boolean;
  edit: ICompanies;
}

export const StateCompanies = 'companies';

@State<CompaniesStateModel>({
  name: StateCompanies,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class CompaniesState {
  @Selector() static list(state: CompaniesStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: CompaniesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: CompaniesStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: CompaniesStateModel) {
    return state.edit;
  }

  constructor(private companiesService: CompaniesService) {}

  @Action(CompaniesGetAction)
  CompaniesGet(ctx: StateContext<CompaniesStateModel>) {
    return this.companiesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(CompaniesEditAction)
  CompaniesEdit(ctx: StateContext<CompaniesStateModel>, { data }: CompaniesEditAction) {
    if (data) {
      if (data?.id) {
        return this.companiesService.show(data.id).pipe(
          tap(result => {
            ctx.patchState({
              edit: result.data
            });
          })
        );
      } else {
        ctx.patchState({
          edit: data
        });
      }
    } else {
      ctx.patchState({
        edit: data
      });
    }
  }

  @Action(CompaniesAddAction)
  CompaniesAdd(ctx: StateContext<CompaniesStateModel>, { data }: CompaniesAddAction) {
    return this.companiesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(CompaniesUpdateAction)
  CompaniesUpdate(ctx: StateContext<CompaniesStateModel>, { data }: CompaniesUpdateAction) {
    return this.companiesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(CompaniesRemoveAction)
  CompaniesRemove(ctx: StateContext<CompaniesStateModel>, { id }: CompaniesRemoveAction) {
    return this.companiesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(CompaniesSetShowDisabledAction)
  CompaniesSetShowDisabled(ctx: StateContext<CompaniesStateModel>, { show }: CompaniesSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }
}
