import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ICronJobsExecution } from '@interfaces/cron-jobs-execution.interface';
import { JobsExecutionService } from '@services/cron-jobs-execution.service';
import { CronJobsExecutionClearAction, CronJobsExecutionGetAction } from './cron-jobs-execution-actions';

export interface CronJobsExecutionStateModel {
  list: ICronJobsExecution[];
}

export const StateCronJobsExecution = 'cron_jobs_execution';

const defaults = {
  list: []
};

@State<CronJobsExecutionStateModel>({
  name: StateCronJobsExecution,
  defaults
})
@Injectable()
export class CronJobsExecutionState {
  @Selector() static list(state: CronJobsExecutionStateModel) {
    return state.list;
  }

  constructor(private cronJobsExecution: JobsExecutionService) {}

  @Action(CronJobsExecutionGetAction)
  CronJobsExecutioGet(ctx: StateContext<CronJobsExecutionStateModel>) {
    return this.cronJobsExecution.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(CronJobsExecutionClearAction)
  CronJobsExecutionClear(ctx: StateContext<CronJobsExecutionStateModel>) {
    ctx.setState(
      defaults
    );
  }
}
