import { IJobsTypes } from '@interfaces/jobs_types.interface';

export class JobsTypesGetAction {
  static readonly type = '[JobsTypes] List';
}

export class JobsTypesAddAction {
  static readonly type = '[JobsTypes] Add';
  constructor(public data: IJobsTypes) {}
}

export class JobsTypesEditAction {
  static readonly type = '[JobsTypes] Edit';
  constructor(public data?: IJobsTypes) {}
}

export class JobsTypesUpdateAction {
  static readonly type = '[JobsTypes] Update';
  constructor(public data: IJobsTypes) {}
}

export class JobsTypesRemoveAction {
  static readonly type = '[JobsTypes] Remove';
  constructor(public id: number) {}
}

export class JobsTypesSetShowDisabledAction {
  static readonly type = '[JobsTypes] List disabled';
  constructor(public show: boolean) {}
}

export const ActionsToProcessJobsTypes = {
  JobsTypesGetAction,
  JobsTypesAddAction,
  JobsTypesUpdateAction,
  JobsTypesRemoveAction,
  JobsTypesEditAction
};
