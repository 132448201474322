import { IEmploymentEducation } from '@interfaces/employment/employment-education.interface';

export class EmployeEducationAddAction {
  static readonly type = '[AddEducationEmploye] Add';
  constructor(public data: IEmploymentEducation) {}
}

export class UserEducationGetAction {
  static readonly type = '[GetEmployeEducation] List';
}
export class EmployeEducationEditAction {
  static readonly type = '[EditEmployeEducation] Edit';
  constructor(public data: IEmploymentEducation) {}
}

export class EmployeEducationUpdateAction {
  static readonly type = '[UpdateEmployeEducation] Update';
  constructor(public data: IEmploymentEducation) {}
}

export class EmployeEducationRequestSaveAction {
  static readonly type = '[EmployeEducation] Request save';
}

export class EmployeEducationRemoveAction {
  static readonly type = '[EmployeEducation] Remove';
  constructor(public id: number) {}
}

export const ActionsToProcessEmploymentEducation = {
  EmployeEducationUpdateAction,
  EmployeEducationAddAction,
  UserEducationGetAction,
  EmployeEducationRemoveAction
};
