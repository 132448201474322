import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MedicalReportService } from '@services/medical-report/medical-report.service';
import { MedicalReportAddAction } from './medical-report-form.action';
import { IMedicalReport } from '@interfaces/medical-report/medical-report.interface';


export interface MedicalReportFormStateModel {
  list: IMedicalReport[];
  edit: IMedicalReport;
}

export const MedicalReportFormStates = 'medical_report_form';

@State<MedicalReportFormStateModel>({
  name: MedicalReportFormStates,
  defaults: {
    list: [],
    edit: null
  }
})
@Injectable()
export class MedicalReportFormState {


  constructor(private medicalReportService: MedicalReportService) {}

  @Action(MedicalReportAddAction)
  PermissionsTypesAdd(ctx: StateContext<MedicalReportFormStateModel>, { data }: MedicalReportAddAction) {
    return this.medicalReportService.saveMedicalReport(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

}
