import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  PermissionsTypesGetAction,
  PermissionsTypesEditAction,
  PermissionsTypesAddAction,
  PermissionsTypesUpdateAction,
  PermissionsTypesRemoveAction,
  PermissionsTypesSetShowDisabledAction
} from './permissions-types-actions';
import { Injectable } from '@angular/core';
import { PermissionsTypesService } from '@services/security/permissions-types.service';
import { IPermissionsTypes } from '@interfaces/security/permissions-types.interface';

export interface PermissionsTypesStateModel {
  list: IPermissionsTypes[];
  show_disable: boolean;
  edit: IPermissionsTypes;
}

export const StatePermissionsTypes = 'permissions_types';

@State<PermissionsTypesStateModel>({
  name: StatePermissionsTypes,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class PermissionsTypesState {
  @Selector() static list(state: PermissionsTypesStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: PermissionsTypesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: PermissionsTypesStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: PermissionsTypesStateModel) {
    return state.edit;
  }

  constructor(private permissionsTypesService: PermissionsTypesService) {}

  @Action(PermissionsTypesGetAction)
  PermissionsTypesGet(ctx: StateContext<PermissionsTypesStateModel>) {
    return this.permissionsTypesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(PermissionsTypesEditAction)
  PermissionsTypesEdit(ctx: StateContext<PermissionsTypesStateModel>, { data }: PermissionsTypesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(PermissionsTypesAddAction)
  PermissionsTypesAdd(ctx: StateContext<PermissionsTypesStateModel>, { data }: PermissionsTypesAddAction) {
    return this.permissionsTypesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(PermissionsTypesUpdateAction)
  PermissionsTypesUpdate(ctx: StateContext<PermissionsTypesStateModel>, { data }: PermissionsTypesUpdateAction) {
    return this.permissionsTypesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(PermissionsTypesRemoveAction)
  PermissionsTypesRemove(ctx: StateContext<PermissionsTypesStateModel>, { id }: PermissionsTypesRemoveAction) {
    return this.permissionsTypesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(PermissionsTypesSetShowDisabledAction)
  PermissionsTypesSetShowDisabled(
    ctx: StateContext<PermissionsTypesStateModel>,
    { show }: PermissionsTypesSetShowDisabledAction
  ) {
    ctx.patchState({
      show_disable: show
    });
  }
}
