import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ITypeCitizen } from '@interfaces/type-citizen.interface';
import { TypeCitizenAddAction, TypeCitizenGetPublicAction } from './type-citizen-action';
import { TypeCitizenService } from '@services/type-citizen.service';

export interface TypeCitizenStateModel {
  list: ITypeCitizen[];
}

export const TypeCitizenModel = 'type_citizen';

@State<TypeCitizenStateModel>({
  name: TypeCitizenModel,
  defaults: {
    list: []
  }
})
@Injectable()
export class TypeCitizenState {
  @Selector() static list(state: TypeCitizenStateModel) {
    return state.list;
  }

  constructor(private typeCitizenService: TypeCitizenService) {}

  @Action(TypeCitizenGetPublicAction)
  TypeCitizenGetPublic(ctx: StateContext<TypeCitizenStateModel>) {
    return this.typeCitizenService.getTypeCitizen().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data.filter(i => i.active)
        });
      })
    );
  }

  @Action(TypeCitizenAddAction)
  updateTypeCitize(ctx: StateContext<TypeCitizenStateModel>, { payload }: TypeCitizenAddAction) {
    return this.typeCitizenService.updateTypeCitizen(payload).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
