import { Injectable } from '@angular/core';
import {
  Action,
  Actions,
  ofActionCompleted,
  ofActionDispatched,
  ofActionErrored,
  State,
  StateContext,
  Store
} from '@ngxs/store';
import { ActionsToProcessCategories } from '@redux/categories/categories-actions';
import { ActionsToProcessJobs } from '@redux/jobs/jobs-actions';
import { ActionsToProcessUserLanguages } from '@redux/user/user-languages/user-languages-actions';
import { ActionsToProcessUserSkills } from '@redux/user/user-skills/user-skills-actions';
import { ActionsToUserPersonalInfo } from '@redux/user/user-personal-info/user-personal-info-actions';
import { ToastrService } from 'ngx-toastr';
import { ActionsToProcessAuth } from './../auth/auth-actions';
import { EndProcessAction, NewProcessAction } from './process-actions';
import { ActionsToProcessUserAttachments } from '@redux/user/user-attachments/user-attachments-actions';
import { ActionsToProcessAttachmentsTypes } from '@redux/attachments-types/attachments-types-actions';
import { ActionsToProcessUserReferences } from '@redux/user/user-references/user-references-actions';
import { ActionsToProcessUserWorks } from '@redux/user/user-works/user-works-actions';
import {
  ActionsToProcessJobsQuestionnaries
} from '@redux/jobs/jobs-questionnaries/jobs-questionnaries.actions';
import { ActionsToProcessStates } from '@redux/states/states-actions';
import { ActionsToProcessLicencesClass } from '@redux/licences-class/licences-class-actions';
import { ActionsToProcessPermissionsTypes } from '@redux/permissions-types/permissions-types-actions';
import { ActionsToProcessPermissionsModules } from '@redux/permissions-modules/permissions-modules-actions';
import { ActionsToProcessPermissions } from '@redux/permissions/permissions-actions';
import { ActionsToProcessRoles } from '@redux/roles/roles-actions';
import { ActionsToProcessCompanies } from '@redux/companies/companies-actions';
import { ActionsToProcessUsers } from '@redux/users/users-actions';
import { ActionsToProcessJobsTypes } from '@redux/jobs-types/jobs-types.actions';
import { ActionsToProcessQuestionsTypes } from '@redux/questions-types/questions-types.actions';
import { ActionsToProcessSalariesTypes } from '@redux/salaries-types/salaries-types.actions';
import { ActionsToProcessApplicantStatus } from '@redux/applicant-status/applicant-status.actions';
import { ActionsToProcessUserApplications } from '@redux/user/user-applications/user-applications-actions';
import { ActionsToProcessDashboard } from '@redux/dashboard/dashboard-actions';
import { ActionsToProcessEmployees } from '@redux/employees/employees-actions';
import { ActionsToProcessIncident } from '@redux/incident/incident-action';
import { ActionsToProcessGeneralParameter } from '@redux/general-parameters/general-parameters.actions';
import {
  ActionsToProcessMedicalCompaniesReport
} from '@redux/medical-report/medical-report-companies/medical-report-companies.action';
import {
  ActionsToProcessMedicalFormReport
} from '@redux/medical-report/medical-report-form/medical-report-form.action';
import {
  ActionsToProcessMedicalStatus
} from '@redux/medical-report/medical-report-status/medical-report-status.action';
import {
  ActionsToProcessMedicalReportAdmin
} from '@redux/medical-report/medial-report-admin/medical-report-admin.action';
import { ActionsToProcessMedicalRecordConsulting } from '@redux/medical-report/medical-report-consulting';
import {
  ActionsToEmployePersonalInfo
} from '@redux/employment-application/employee-personal-info/employee-personal-info-actions';
import { ActionsToProcessTemplates } from '@redux/dynamic-docs/templates/templates-actions';
import { ActionsToProcessParameters } from '@redux/dynamic-docs/parameters/parameters-actions';
import { ActionsToProcessQuestionsTemp } from '@redux/questions/questions.actions';


export const actionsLoading = {
  ...ActionsToProcessAuth,
  ...ActionsToProcessCategories,
  ...ActionsToProcessJobs,
  ...ActionsToProcessUserLanguages,
  ...ActionsToProcessUserSkills,
  ...ActionsToUserPersonalInfo,
  ...ActionsToProcessUserAttachments,
  ...ActionsToProcessAttachmentsTypes,
  ...ActionsToProcessUserReferences,
  ...ActionsToProcessUserWorks,
  ...ActionsToProcessJobsQuestionnaries,
  ...ActionsToProcessStates,
  ...ActionsToProcessLicencesClass,
  ...ActionsToProcessPermissionsTypes,
  ...ActionsToProcessPermissionsModules,
  ...ActionsToProcessPermissions,
  ...ActionsToProcessRoles,
  ...ActionsToProcessCompanies,
  ...ActionsToProcessUsers,
  ...ActionsToProcessJobsTypes,
  ...ActionsToProcessQuestionsTypes,
  ...ActionsToProcessSalariesTypes,
  ...ActionsToProcessApplicantStatus,
  ...ActionsToProcessUserApplications,
  ...ActionsToProcessDashboard,
  ...ActionsToProcessIncident,
  ...ActionsToProcessEmployees,
  ...ActionsToProcessGeneralParameter,
  ...ActionsToProcessMedicalCompaniesReport,
  ...ActionsToProcessMedicalFormReport,
  ...ActionsToProcessMedicalStatus,
  ...ActionsToProcessMedicalReportAdmin,
  ...ActionsToProcessMedicalRecordConsulting,
  ...ActionsToEmployePersonalInfo,
  ...ActionsToProcessTemplates,
  ...ActionsToProcessParameters,
  ...ActionsToProcessQuestionsTemp
};

export interface ProcessStateModel {
  list: Process[];
}

interface Process {
  code: number;
}

@State<ProcessStateModel>({
  name: 'process',
  defaults: {
    list: []
  }
})
@Injectable()
export class ProcessState {
  constructor(
    private actions$: Actions,
    private store: Store,
    private toastr: ToastrService
  ) {
    const actions = Object.keys(actionsLoading).map(a => actionsLoading[a]);
    this.actions$.pipe(ofActionDispatched(...actions)).subscribe(actionPayload => {
      const actionName = Object.getPrototypeOf(actionPayload).constructor.name;
      this.store.dispatch(new NewProcessAction(actionName));
    });

    this.actions$.pipe(ofActionCompleted(...actions)).subscribe(actionPayload => {
      const actionName = Object.getPrototypeOf(actionPayload.action).constructor.name;
      this.store.dispatch(new EndProcessAction(actionName, true));
    });

    this.actions$.pipe(ofActionErrored(...actions)).subscribe(actionPayload => {
      const actionData = Object.getPrototypeOf(actionPayload).constructor;
      const defaultBody = `
        Please check the data entered and try again,
        If the error persists, please contact support.
      `;
      const defaultTitle = `Upps!, Something unexpected happened`;
      if (!actionData.skipError) {
        this.toastr[actionData.errorType ? actionData.errorType : 'error'](
          actionData.errorBody === null || actionData.errorBody === undefined ? defaultBody : actionData.errorBody,
          actionData.errorTitle === null || actionData.errorTitle === undefined
            ? defaultTitle
            : actionData.errorTitle
        );
      }
    });
  }

  @Action(NewProcessAction)
  newProcess(ctx: StateContext<ProcessStateModel>, data: NewProcessAction) {
    const state = ctx.getState();
    const process = state.list ? Object.assign([], state.list) : [];
    process.push(data);
    ctx.patchState({
      list: process
    });
  }

  @Action(EndProcessAction)
  endProcess(ctx: StateContext<ProcessStateModel>, data: EndProcessAction) {
    const state = ctx.getState();
    const process = state.list ? Object.assign([], state.list) : [];
    const idx = process.map(p => p.code).indexOf(data.code);
    if (idx >= 0) {
      process.splice(idx, 1);
    }
    ctx.patchState({
      list: process
    });
  }
}
