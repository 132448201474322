import { IPermissions } from '@interfaces/security/permissions.interface';

export class PermissionsGetAction {
  static readonly type = '[Permissions] List';
}

export class PermissionsAddAction {
  static readonly type = '[Permissions] Add';
  constructor(public data: IPermissions) {}
}

export class PermissionsEditAction {
  static readonly type = '[Permissions] Edit';
  constructor(public data: IPermissions) {}
}

export class PermissionsUpdateAction {
  static readonly type = '[Permissions] Update';
  constructor(public data: IPermissions) {}
}

export class PermissionsRemoveAction {
  static readonly type = '[Permissions] Remove';
  constructor(public id: number) {}
}

export class PermissionsSetShowDisabledAction {
  static readonly type = '[Permissions] List disabled';
  constructor(public show: boolean) {}
}

export const ActionsToProcessPermissions = {
  PermissionsGetAction,
  PermissionsAddAction,
  PermissionsUpdateAction,
  PermissionsRemoveAction
};
