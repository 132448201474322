import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IUsers } from '@interfaces/security/users.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IUsers[]>>(`${ROUTES_PRIVATE.USERS}`);
  }

  show(id: number) {
    return this.httpClient.get<IResponse<IUsers>>(`${ROUTES_PRIVATE.USERS}/${id}`);
  }

  showByEmail(email: string) {
    return this.httpClient.get<IResponse<IUsers>>(`${ROUTES_PRIVATE.USERS}/by_email/${email}`);
  }

  save(data: IUsers) {
    return this.httpClient.post<IResponse<IUsers>>(`${ROUTES_PRIVATE.USERS}`, data);
  }

  update(data: IUsers) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IUsers>>(`${ROUTES_PRIVATE.USERS}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IUsers>>(`${ROUTES_PRIVATE.USERS}/${id}`);
  }

  changePassword(id: number, password: string): Observable<IResponse<IUsers>> {
    const URL = `${ROUTES_PRIVATE.USERS}/change_password/${id}`;
    return this.httpClient.patch<IResponse<IUsers>>(URL, { password });
  }
}
