import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ISalariesTypes } from '@interfaces/salaries-types.interface';
import {
  SalariesTypesAddAction,
  SalariesTypesEditAction,
  SalariesTypesGetAction,
  SalariesTypesRemoveAction,
  SalariesTypesSetShowDisabledAction,
  SalariesTypesUpdateAction
} from './salaries-types.actions';
import { SalariesTypesService } from '@services/salaries-types.service';

export interface SalariesTypesStateModel {
  list: ISalariesTypes[];
  show_disable: boolean;
  edit: ISalariesTypes;
}

export const StateSalariesTypes = 'salaries_types';

@State<SalariesTypesStateModel>({
  name: StateSalariesTypes,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class SalariesTypesState {
  @Selector() static list(state: SalariesTypesStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: SalariesTypesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: SalariesTypesStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: SalariesTypesStateModel) {
    return state.edit;
  }

  constructor(private salariesTypesService: SalariesTypesService) {}

  @Action(SalariesTypesGetAction)
  SalariesTypesGet(ctx: StateContext<SalariesTypesStateModel>) {
    return this.salariesTypesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(SalariesTypesEditAction)
  SalariesTypesEdit(ctx: StateContext<SalariesTypesStateModel>, { data }: SalariesTypesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(SalariesTypesAddAction)
  SalariesTypesAdd(ctx: StateContext<SalariesTypesStateModel>, { data }: SalariesTypesAddAction) {
    return this.salariesTypesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(SalariesTypesUpdateAction)
  SalariesTypesUpdate(ctx: StateContext<SalariesTypesStateModel>, { data }: SalariesTypesUpdateAction) {
    return this.salariesTypesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(SalariesTypesRemoveAction)
  SalariesTypesRemove(ctx: StateContext<SalariesTypesStateModel>, { id }: SalariesTypesRemoveAction) {
    return this.salariesTypesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(SalariesTypesSetShowDisabledAction)
  SalariesTypesSetShowDisabled(
    ctx: StateContext<SalariesTypesStateModel>,
    { show }: SalariesTypesSetShowDisabledAction
  ) {
    ctx.patchState({
      show_disable: show
    });
  }
}
