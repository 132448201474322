import { IAssignTemplates, ITemplates, ITemplatesTypes } from '@interfaces/templates.interface';

export class TemplatesAddAction {
  static readonly type = '[Templates] Add';
  constructor(public data: ITemplates) {}
}
export class TemplatesUpdateAction {
  static readonly type = '[Templates] Update';
  constructor(public data: ITemplates) {}
}
export class TemplatesGetAllAction {
  static readonly type = '[Templates] List';
}
export class TemplateGetByIdAction {
  static readonly type = '[Templates] Detail';
  constructor(public id: string) {}
}

export class TemplatesTypeGetAllAction {
  static readonly type = '[TemplatesType] List Types';
}

export class SetCurrentTemplate {
  static readonly type = '[Templates] set Current Template';
}
export class TemplatesTypeSetShowDisabledAction {
  static readonly type = '[ShowDisabledTType] Show';
  constructor(public show: boolean) {}
}
export class TemplatesTypeSetDataAction {
  static readonly type = '[TemplatesType] Set Type Data';
  constructor(public data: ITemplatesTypes) {}
}
export class TemplatesTypeAddAction {
  static readonly type = '[TemplatesType] Add';
  constructor(public data: ITemplatesTypes) {}
}
export class TemplatesTypeUpdateAction {
  static readonly type = '[TemplatesType] Update';
  constructor(public data: ITemplatesTypes) {}
}
export class AssignTemplatesAction {
  static readonly type = '[Templates] Assign';
  constructor(public data: IAssignTemplates) {}
}
export class TemplateCompanyGetAction {
  static readonly type = '[CompanyTemplates] List CT';
  constructor(public companyId: number) {}
}
export class TemplateCompanyDeleteAction {
  static readonly type = '[CompanyTemplates] Delete CT';
  constructor(public id: number) {}
}
export class TemplateDeleteAction {
  static readonly type = '[Templates] Delete';
  constructor(public id: number, public data: ITemplates) {}
}

export class SetContentDocumentEditor {
  static readonly type = '[DocumentEditor] Set Content';
  constructor(public data: string) {}
}

export class ApplicantTemplateAction {
  static readonly type = '[ApplicantTemplate] get Content';
  constructor(public templateId: number) {}
}
export class TemplatesGetByApplicantAction {
  static readonly type = '[TemplatesApplicant] Content';
  constructor(public id: number) {}
}
export class SetSignatureAction {
  static readonly type = '[SignatureApplicant] Set Signature';
  constructor(public data: string) {}
}
export class GetTemplatesByType {
  static readonly type = '[TemplatesByType] Get Templates By Type';
  constructor(public type: number) {}
}
export const ActionsToProcessTemplates = {
  TemplatesAddAction,
  TemplatesUpdateAction,
  TemplatesGetAllAction,
  TemplateGetByIdAction,
  TemplatesTypeGetAllAction,
  TemplatesTypeSetShowDisabledAction,
  TemplatesTypeSetDataAction,
  TemplatesTypeAddAction,
  TemplatesTypeUpdateAction,
  AssignTemplatesAction,
  TemplateCompanyGetAction,
  TemplateCompanyDeleteAction,
  TemplateDeleteAction,
  ApplicantTemplateAction,
  TemplatesGetByApplicantAction
};
