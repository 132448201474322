import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MaritalStatusAddAction, MaritalStatusGetPublicAction } from './marital-status-action';
import { IMaritalStatus } from '@interfaces/marital-status.interface';
import { MaritalStatusService } from '@services/marital-status.service';

export interface MaritalStatusStateModel {
  list: IMaritalStatus[];
}

export const MaritalStatusModel = 'marital_status';

@State<MaritalStatusStateModel>({
  name: MaritalStatusModel,
  defaults: {
    list: []
  }
})
@Injectable()
export class MaritalStatusState {
  @Selector() static list(state: MaritalStatusStateModel) {
    return state.list;
  }

  constructor(private privateService: MaritalStatusService) {}

  @Action(MaritalStatusGetPublicAction)
  MaritalStatusGetPublic(ctx: StateContext<MaritalStatusStateModel>) {
    return this.privateService.getmaritalStatus().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(MaritalStatusAddAction)
  updateMaritalStatus(ctx: StateContext<MaritalStatusStateModel>, { payload }: MaritalStatusAddAction) {
    return this.privateService.updateMaritalStatus(payload).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
