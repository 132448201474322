import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LicencesClassGetPublicAction } from './licences-class-actions';
import { Injectable } from '@angular/core';
import { PublicService } from '@services/public.service';

export interface LicencesClassStateModel {
  list: string[];
}

export const StateLicencesClass = 'licences_class';

@State<LicencesClassStateModel>({
  name: StateLicencesClass,
  defaults: {
    list: []
  }
})
@Injectable()
export class LicencesClassState {
  @Selector() static list(state: LicencesClassStateModel) {
    return state.list;
  }

  constructor(private publicService: PublicService) {}

  @Action(LicencesClassGetPublicAction)
  LicencesClassGetPublic(ctx: StateContext<LicencesClassStateModel>) {
    return this.publicService.getLicencesClass().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data.map(i => i.class_name)
        });
      })
    );
  }
}
