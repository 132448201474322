import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { ITypeIdentity } from '@interfaces/type-citizen.interface';


@Injectable({
  providedIn: 'root'
})
export class TypeIdentityService {

  constructor(private httpClient: HttpClient) {}

  gettypeIdentity() {
    return this.httpClient.get<IResponse<ITypeIdentity[]>>(`${ROUTES_PRIVATE.TYPE_IDENTITY}`);
  }
}