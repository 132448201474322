import { IUserSkills } from '@interfaces/user/user-skills.intercace';

export class UserSkillsGetAction {
  static readonly type = '[UserSkills] List';
}

export class UserSkillsAddAction {
  static readonly type = '[UserSkills] Add';
  constructor(public data: IUserSkills) {}
}

export class UserSkillsEditAction {
  static readonly type = '[UserSkills] Edit';
  constructor(public data: IUserSkills) {}
}

export class UserSkillsUpdateAction {
  static readonly type = '[UserSkills] Update';
  constructor(public data: IUserSkills) {}
}

export class UserSkillsRemoveAction {
  static readonly type = '[UserSkills] Remove';
  constructor(public id: number) {}
}

export class UserSkillsRequestSaveAction {
  static readonly type = '[UserSkills] Request save';
}

export const ActionsToProcessUserSkills = {
  UserSkillsGetAction,
  UserSkillsAddAction,
  UserSkillsUpdateAction,
  UserSkillsRemoveAction
};
