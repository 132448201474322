import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  PermissionsModulesGetAction,
  PermissionsModulesEditAction,
  PermissionsModulesAddAction,
  PermissionsModulesUpdateAction,
  PermissionsModulesRemoveAction,
  PermissionsModulesSetShowDisabledAction,
  PermissionsModulesGetTreeAction
} from './permissions-modules-actions';
import { Injectable } from '@angular/core';
import { PermissionsModulesService } from '@services/security/permissions-modules.service';
import { IPermissionsModules } from '@interfaces/security/permissions-modules.interface';

export interface PermissionsModulesStateModel {
  list: IPermissionsModules[];
  list_tree: IPermissionsModules[];
  show_disable: boolean;
  edit: IPermissionsModules;
}

export const StatePermissionsModules = 'permissions_modules';

@State<PermissionsModulesStateModel>({
  name: StatePermissionsModules,
  defaults: {
    list: [],
    list_tree: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class PermissionsModulesState {
  @Selector() static list(state: PermissionsModulesStateModel) {
    return state.list;
  }
  @Selector() static listTree(state: PermissionsModulesStateModel) {
    return state.list_tree;
  }
  @Selector() static listActive(state: PermissionsModulesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: PermissionsModulesStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: PermissionsModulesStateModel) {
    return state.edit;
  }

  constructor(private permissionsModulesService: PermissionsModulesService) {}

  @Action(PermissionsModulesGetAction)
  PermissionsModulesGet(ctx: StateContext<PermissionsModulesStateModel>) {
    return this.permissionsModulesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(PermissionsModulesGetTreeAction)
  PermissionsModulesGetTree(
    ctx: StateContext<PermissionsModulesStateModel>,
    { actions }: PermissionsModulesGetTreeAction
  ) {
    return (actions ? this.permissionsModulesService.getTreeActions() : this.permissionsModulesService.getTree()).pipe(
      tap(result => {
        ctx.patchState({
          list_tree: result.data
        });
      })
    );
  }

  @Action(PermissionsModulesEditAction)
  PermissionsModulesEdit(ctx: StateContext<PermissionsModulesStateModel>, { data }: PermissionsModulesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(PermissionsModulesAddAction)
  PermissionsModulesAdd(ctx: StateContext<PermissionsModulesStateModel>, { data }: PermissionsModulesAddAction) {
    return this.permissionsModulesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(PermissionsModulesUpdateAction)
  PermissionsModulesUpdate(ctx: StateContext<PermissionsModulesStateModel>, { data }: PermissionsModulesUpdateAction) {
    return this.permissionsModulesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(PermissionsModulesRemoveAction)
  PermissionsModulesRemove(ctx: StateContext<PermissionsModulesStateModel>, { id }: PermissionsModulesRemoveAction) {
    return this.permissionsModulesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(PermissionsModulesSetShowDisabledAction)
  PermissionsModulesSetShowDisabled(
    ctx: StateContext<PermissionsModulesStateModel>,
    { show }: PermissionsModulesSetShowDisabledAction
  ) {
    ctx.patchState({
      show_disable: show
    });
  }
}
