import { IAttachmentsTypes } from '@interfaces/attachments-types.interface';

export class AttachmentsTypesGetPublicAction {
  static readonly type = '[AttachmentsTypes] List Public';
}

export class AttachmentsTypesGetAction {
  static readonly type = '[AttachmentsTypes] List';
}

export class AttachmentsTypesAddAction {
  static readonly type = '[AttachmentsTypes] Add';
  constructor(public data: IAttachmentsTypes) {}
}

export class AttachmentsTypesEditAction {
  static readonly type = '[AttachmentsTypes] Edit';
  constructor(public data: IAttachmentsTypes) {}
}

export class AttachmentsTypesUpdateAction {
  static readonly type = '[AttachmentsTypes] Update';
  constructor(public data: IAttachmentsTypes) {}
}

export class AttachmentsTypesRemoveAction {
  static readonly type = '[AttachmentsTypes] Remove';
  constructor(public id: number) {}
}

export class AttachmentsTypesSetShowDisabledAction {
  static readonly type = '[AttachmentsTypes] List disabled';
  constructor(public show: boolean) {}
}

export class AttachmentsTypesGetOfferAction {
  static readonly type = '[AttachmentsTypesGetOffer] List';
  constructor(public code: string) {}
}

export const ActionsToProcessAttachmentsTypes = {
  AttachmentsTypesGetPublicAction,
  AttachmentsTypesGetAction,
  AttachmentsTypesAddAction,
  AttachmentsTypesUpdateAction,
  AttachmentsTypesRemoveAction,
  AttachmentsTypesGetOfferAction
};
