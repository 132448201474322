import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  UserReferencesAddAction,
  UserReferencesEditAction,
  UserReferencesGetAction,
  UserReferencesRemoveAction,
  UserReferencesUpdateAction
} from './user-references-actions';
import { Injectable } from '@angular/core';
import { UserReferencesService } from '@services/user/user-references';
import { IUserReferences } from '@interfaces/user/user-references';

export interface UserReferencesStateModel {
  list: IUserReferences[];
  edit: IUserReferences;
}

export const StateUserReferences = 'user_references';

@State<UserReferencesStateModel>({
  name: StateUserReferences,
  defaults: {
    list: [],
    edit: {}
  }
})
@Injectable()
export class UserReferencesState {
  @Selector() static listReferencesActive(state: UserReferencesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static editReference(state: UserReferencesStateModel) {
    return state.edit;
  }

  constructor(private userReferencesService: UserReferencesService) {}

  @Action(UserReferencesGetAction)
  UserReferencesGet(ctx: StateContext<UserReferencesStateModel>) {
    return this.userReferencesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(UserReferencesEditAction)
  UserReferencesEdit(ctx: StateContext<UserReferencesStateModel>, { data }: UserReferencesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(UserReferencesAddAction)
  UserReferencesAdd(ctx: StateContext<UserReferencesStateModel>, { data }: UserReferencesAddAction) {
    return this.userReferencesService.save(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(UserReferencesUpdateAction)
  UserReferencesUpdate(ctx: StateContext<UserReferencesStateModel>, { data }: UserReferencesUpdateAction) {
    return this.userReferencesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: {}
        });
      })
    );
  }

  @Action(UserReferencesRemoveAction)
  UserReferencesRemove(ctx: StateContext<UserReferencesStateModel>, { id }: UserReferencesRemoveAction) {
    return this.userReferencesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
