import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IQuestionsTypes } from '@interfaces/questions-types.interface';
import { Question } from '@interfaces/apply-job-questionnaries.interface';

@Injectable({ providedIn: 'root' })
export class QuestionsService {
  constructor(private httpClient: HttpClient) {}

  getByCategory(category) {
    return this.httpClient.get<IResponse<Question[]>>(`${ROUTES_PRIVATE.QUESTIONS_BY_CATEGORY}${category}`);
  }

  save(data: IQuestionsTypes) {
    return this.httpClient.post<IResponse<Question>>(`${ROUTES_PRIVATE.QUESTIONS}`, data);
  }

  update(data: IQuestionsTypes) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<Question>>(`${ROUTES_PRIVATE.QUESTIONS_UPDATE}/${id}`, data);
  }
}
