import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { IMedicalReport } from '@interfaces/medical-report/medical-report.interface';
import { MedicalReportService } from '@services/medical-report/medical-report.service';
import { tap } from 'rxjs/operators';
import { IResponse } from '@interfaces/api.interface';
import {
  ClearMedicalReportByCodeAction,
  GetMedicalReportByCodeAction
} from '@redux/medical-report/medical-report-consulting/medical-report-consulting.action';


interface MedicalRecordConsultingModel {
  show: boolean;
  medicalReport: IMedicalReport;
}

const defaults: MedicalRecordConsultingModel = {
  show: false,
  medicalReport: null
};

@State<MedicalRecordConsultingModel>({
  name: 'medical_record_consulting',
  defaults
})

@Injectable()
export class MedicalReportConsultingState {
  constructor(private medicalReportService: MedicalReportService) {}

  @Selector()
  static actualState(state: MedicalRecordConsultingModel): number {
    return state.medicalReport.status.id;
  }

  @Selector()
  static medicalReport(state: MedicalRecordConsultingModel): IMedicalReport {
    return state.medicalReport;
  }

  @Action(GetMedicalReportByCodeAction)
  getMedicalReportByCodeAction(
    cxt: StateContext<MedicalRecordConsultingModel>,
    {code}: GetMedicalReportByCodeAction
  ) {
    return this.medicalReportService
      .getMedicalReportByCode(code)
      .pipe(tap(({data: medicalReport, success}: IResponse<IMedicalReport>): void => {
        if (success) {
          cxt.setState({medicalReport, show: true});
        }
      }));
  }

  @Action(ClearMedicalReportByCodeAction)
  clearMedicalReportByCodeAction(cxt: StateContext<MedicalRecordConsultingModel>): void {
    cxt.patchState({show: false, medicalReport: null});
  }
}
