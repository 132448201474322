import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { ICompanyIncident } from '@interfaces/companies-incident';
import { IDamageIncident } from '@interfaces/damage-incident.interface';
import { IEmergencyIncident } from '@interfaces/emergency-incident.interface';


@Injectable({ providedIn: 'root' })
export class CompaniesIncidentService {
  constructor(private httpClient: HttpClient) {}

  getListCompany() {
    return this.httpClient.get<IResponse<ICompanyIncident[]>>(`${ROUTES_PUBLIC.COMPANIES_INCIDENT}`);
  }

  getListEmergency() {
    return this.httpClient.get<IResponse<IEmergencyIncident[]>>(`${ROUTES_PUBLIC.EMERGENCY_INCIDENT}`);
  }

  getListDamages() {
    return this.httpClient.get<IResponse<IDamageIncident[]>>(`${ROUTES_PUBLIC.DAMAGES_INCIDENT}`);
  }

  save(data: ICompanyIncident) {
    delete data.CompanyId;
    return this.httpClient.post<IResponse<ICompanyIncident>>(`${ROUTES_PUBLIC.COMPANIES_INCIDENT}`, data);
  }

  update(data: ICompanyIncident) {
    const id = data.CompanyId;
    delete data.CompanyId;
    return this.httpClient.put<IResponse<ICompanyIncident>>(`${ROUTES_PUBLIC.COMPANIES_INCIDENT}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<ICompanyIncident>>(`${ROUTES_PUBLIC.COMPANIES_INCIDENT}/${id}`);
  }
}
