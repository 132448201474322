import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MedicalReportService } from '@services/medical-report/medical-report.service';
import {
  MedicalReportCompaniesAddAction,
  MedicalReportCompaniesEditAction,
  MedicalReportCompaniesGetAction,
  MedicalReportCompaniesGetCodeAction,
  MedicalReportCompaniesGetIdAction,
  MedicalReportCompaniesRemoveAction,
  MedicalReportCompaniesSetShowDisabledAction,
  MedicalReportCompaniesUpdateAction,
  MedicalReportCompaniesClearFormAction
} from '@redux/medical-report';
import { IMedicalReportStatus } from '@interfaces/medical-report/medical-report.interface';
import { IMedicalCompanies } from '@interfaces/medical-report/medical-report-companies.interface';


export interface MedicalReportCompaniesStateModel {
  list_report_companies: IMedicalCompanies[];
  edit_report_companies: IMedicalCompanies;
  form_report_companies: IMedicalCompanies;
  list_medical_report_status: IMedicalReportStatus[];
  show_disable: boolean;
}

export const MedicalReportCompaniesStates = 'medical_report_companies';

@State<MedicalReportCompaniesStateModel>({
  name: MedicalReportCompaniesStates,
  defaults: {
    list_report_companies: [],
    edit_report_companies: null,
    form_report_companies: null,
    list_medical_report_status: null,
    show_disable: false,
  }
})
@Injectable()
export class MedicalReportCompaniesState {

  @Selector() static listMedicalCompanies(state: MedicalReportCompaniesStateModel) {
    return state.list_report_companies;
  }
  @Selector() static listMedicalCompaniesActive(state: MedicalReportCompaniesStateModel) {
    return state.list_report_companies.filter(i => i.active);
  }
  @Selector() static showDisabled(state: MedicalReportCompaniesStateModel) {
    return state.show_disable;
  }
  @Selector() static editMedicalCompanies(state: MedicalReportCompaniesStateModel) {
    return state.edit_report_companies;
  }
  @Selector() static infoMedicalCompanies(state: MedicalReportCompaniesStateModel) {
    return state.form_report_companies;
  }


  constructor(private medicalReportService: MedicalReportService) {}

  @Action(MedicalReportCompaniesGetAction)
  MedicalReportCompaniesGet(ctx: StateContext<MedicalReportCompaniesStateModel>) {
    return this.medicalReportService.getMedicalCompanies().pipe(
      tap(result => {
        ctx.patchState({
          list_report_companies: result.data
        });
      })
    );
  }

  @Action(MedicalReportCompaniesEditAction)
  MedicalReportCompaniesEdit(
    ctx: StateContext<MedicalReportCompaniesStateModel>,
    { data }: MedicalReportCompaniesEditAction) {
    ctx.patchState({
      edit_report_companies: data
    });
  }

  @Action(MedicalReportCompaniesAddAction)
  MedicalReportCompaniesAddAction(
    ctx: StateContext<MedicalReportCompaniesStateModel>,
    { data }: MedicalReportCompaniesAddAction) {
    return this.medicalReportService.saveMedicalCompanies(data).pipe(
      tap(() => {
        ctx.patchState({
          edit_report_companies: null
        });
      })
    );
  }

  @Action(MedicalReportCompaniesUpdateAction)
  MedicalReportCompaniesUpdateAction(
    ctx: StateContext<MedicalReportCompaniesStateModel>,
    { data }: MedicalReportCompaniesUpdateAction) {
    return this.medicalReportService.updateMedicalCompanies(data).pipe(
      tap(() => {
        ctx.patchState({
          edit_report_companies: null
        });
      })
    );
  }

  @Action(MedicalReportCompaniesRemoveAction)
  MedicalReportCompaniesRemoveAction(
    ctx: StateContext<MedicalReportCompaniesStateModel>,
    { id }: MedicalReportCompaniesRemoveAction) {
    return this.medicalReportService.removeMedicalCompanies(id).pipe(
      tap(() => {
        ctx.patchState({
          edit_report_companies: null
        });
      })
    );
  }

  @Action(MedicalReportCompaniesSetShowDisabledAction)
  MedicalReportCompaniesSetShowDisabledAction(
    ctx: StateContext<MedicalReportCompaniesStateModel>,
    { show }: MedicalReportCompaniesSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }

  @Action(MedicalReportCompaniesGetIdAction)
  MedicalReportCompaniesGetIdAction(
    ctx: StateContext<MedicalReportCompaniesStateModel>,
    { id }: MedicalReportCompaniesGetIdAction) {
    return this.medicalReportService.getMedicalCompaniesId(id).pipe(
      tap(result => {
        ctx.patchState({
          edit_report_companies: result.data
        });
      })
    );
  }

  @Action(MedicalReportCompaniesGetCodeAction)
  MedicalReportCompaniesGetCodeAction(
    ctx: StateContext<MedicalReportCompaniesStateModel>,
    { code }: MedicalReportCompaniesGetCodeAction) {
    return this.medicalReportService.getMedicalCompaniesCode(code).pipe(
      tap(result => {
        ctx.patchState({
          form_report_companies: result.data
        });
      })
    );
  }

  @Action(MedicalReportCompaniesClearFormAction)
  MedicalReportCompaniesClearFormAction(
    ctx: StateContext<MedicalReportCompaniesStateModel>
  ): void {
    ctx.patchState({ form_report_companies: null });
  }
}
