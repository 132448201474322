import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IRoles } from '@interfaces/security/roles.interface';

@Injectable({ providedIn: 'root' })
export class RolesService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IRoles[]>>(`${ROUTES_PRIVATE.ROLES}`);
  }

  show(id: number) {
    return this.httpClient.get<IResponse<IRoles>>(`${ROUTES_PRIVATE.ROLES}/${id}`);
  }

  save(data: IRoles) {
    return this.httpClient.post<IResponse<IRoles>>(`${ROUTES_PRIVATE.ROLES}`, data);
  }

  update(data: IRoles) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IRoles>>(`${ROUTES_PRIVATE.ROLES}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IRoles>>(`${ROUTES_PRIVATE.ROLES}/${id}`);
  }
}
