import { Routes } from '@angular/router';
import { LayoutTicketComponent } from '../pages/medical-record/layout/layout.component';
import {
  MedicalRecordSelectCompanyComponent
} from '../pages/medical-record/medical-record-select-company/medical-record-select-company.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutTicketComponent,
    children: [
      {
        path: '',
        component: MedicalRecordSelectCompanyComponent
      }
    ]
  },
  {
    path: 'medical-record',
    loadChildren: () => import('../pages/medical-record/medical-record.module').then(m => m.TicketReportModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];
