export class GetMedicalReportByCodeAction {
  static readonly skipError = true;
  static readonly type = '[MedicalReport] Get Medical Report By Code';

  constructor(public code: number) {
  }
}

export class ClearMedicalReportByCodeAction {
  static readonly skipError = true;
  static readonly type = '[MedicalReport] Clear Medical Report By Code Action';
}

export const ActionsToProcessMedicalRecordConsulting = {
  GetMedicalReportByCodeAction,
  ClearMedicalReportByCodeAction
};
