import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MedicalReportService } from '@services/medical-report/medical-report.service';
import {
  MedicalPossibleStatusGetAction,
  MedicalPossibleStatusSetShowDisabledAction,
  MedicalReportStatusAddAction, MedicalReportStatusDeleteAction, MedicalReportStatusEditAction,
  MedicalReportStatusGetAction, MedicalReportStatusUpdateAction } from './medical-report-status.action';
import { IMedicalReportStatus } from '@interfaces/medical-report/medical-report.interface';



export interface MedicalReportStatusStateModel {
  list: IMedicalReportStatus[];
  edit: IMedicalReportStatus;
  possible_states: IMedicalReportStatus[];
  show_disable: boolean;
}

export const MedicalReportStatusState = 'medical_report_status';

@State<MedicalReportStatusStateModel>({
  name: MedicalReportStatusState,
  defaults: {
    list: [],
    edit: null,
    possible_states: [],
    show_disable: false
  }
})
@Injectable()
export class MedicalStatusReportState {

  @Selector() static list(state: MedicalReportStatusStateModel) {
    return state.list;
  }

  @Selector() static listActive(state: MedicalReportStatusStateModel) {
    return state.list.filter((i) => i.active);
  }

  @Selector() static listPossibleStates(state: MedicalReportStatusStateModel) {
    return state.possible_states;
  }

  @Selector() static showDisabled(state: MedicalReportStatusStateModel) {
    return state.show_disable;
  }

  @Selector() static edit(state: MedicalReportStatusStateModel) {
    return state.edit;
  }

  constructor(private medicalReportService: MedicalReportService) {}

  @Action(MedicalReportStatusGetAction)
  MedicalReportStatusGetAction(ctx: StateContext<MedicalReportStatusStateModel>) {
    return this.medicalReportService.getMedicalRecordStatus().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(MedicalPossibleStatusGetAction)
  MedicalPossibleStatusGetAction(
    ctx: StateContext<MedicalReportStatusStateModel>,
    { id }: MedicalPossibleStatusGetAction) {
    return this.medicalReportService.getPossibleStatesIdMedicalReport(id).pipe(
      tap(result => {
        ctx.patchState({
          possible_states: result.data
        });
      })
    );
  }

  @Action(MedicalPossibleStatusSetShowDisabledAction)
  MedicalPossibleStatusSetShowDisabledAction(
    ctx: StateContext<MedicalReportStatusStateModel>,
    { show }: MedicalPossibleStatusSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }

  @Action(MedicalReportStatusEditAction)
  MedicalReportStatusEditAction(
    ctx: StateContext<MedicalReportStatusStateModel>,
    { data }: MedicalReportStatusEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(MedicalReportStatusAddAction)
  MedicalReportStatusAddAction(
    ctx: StateContext<MedicalReportStatusStateModel>,
    { data }: MedicalReportStatusAddAction) {
    return this.medicalReportService.saveMedicalRecordStatus(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(MedicalReportStatusUpdateAction)
  MedicalReportStatusUpdateAction(
    ctx: StateContext<MedicalReportStatusStateModel>,
    { data }: MedicalReportStatusUpdateAction) {
    return this.medicalReportService.updateMedicalStatus(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(MedicalReportStatusDeleteAction)
  MedicalReportStatusDeleteAction(
    ctx: StateContext<MedicalReportStatusStateModel>,
    { data }: MedicalReportStatusDeleteAction) {
    return this.medicalReportService.deleteMedicalStatus(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }
}
