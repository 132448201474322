import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IParameterPublic, IParameterValues } from '@interfaces/general-parameters/general-parameter.interface';


@Injectable({ providedIn: 'root' })
export class GeneralParameterService {
  constructor(private httpClient: HttpClient) {}

  getTicketParameters(reference: string, code: string) {
    return this.httpClient.get<IResponse<IParameterPublic>>(
      `${ROUTES_PUBLIC.PARAMETERS}/get_with_values?reference=${reference}&code=${code}`);
  }

  getParameters() {
    return this.httpClient.get<IResponse<IParameterPublic[]>>(`${ROUTES_PRIVATE.PARAMETERS}`);
  }

  saveParameters(data: IParameterPublic) {
    delete data.id;
    return this.httpClient.post<IResponse<IParameterPublic>>(`${ROUTES_PRIVATE.PARAMETERS}`, data);
  }

  updateParameters(data: IParameterPublic) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IParameterPublic>>(`${ROUTES_PRIVATE.PARAMETERS}/${id}`, data);
  }

  saveParametersValues(data: IParameterValues) {
    delete data.id;
    return this.httpClient.post<IResponse<IParameterValues>>(`${ROUTES_PRIVATE.PARAMETERS_VALUES}`, data);
  }

  updateParametersValues(data: IParameterValues) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IParameterValues>>(`${ROUTES_PRIVATE.PARAMETERS_VALUES}/${id}`, data);
  }

}
