import { IRoles } from '@interfaces/security/roles.interface';

export class RolesGetAction {
  static readonly type = '[Roles] List';
}

export class RolesAddAction {
  static readonly type = '[Roles] Add';
  constructor(public data: IRoles) {}
}

export class RolesEditAction {
  static readonly type = '[Roles] Edit';
  constructor(public data: IRoles) {}
}

export class RolesUpdateAction {
  static readonly type = '[Roles] Update';
  constructor(public data: IRoles) {}
}

export class RolesRemoveAction {
  static readonly type = '[Roles] Remove';
  constructor(public id: number) {}
}

export class RolesSetShowDisabledAction {
  static readonly type = '[Roles] List disabled';
  constructor(public show: boolean) {}
}

export const ActionsToProcessRoles = {
  RolesGetAction,
  RolesEditAction,
  RolesAddAction,
  RolesUpdateAction,
  RolesRemoveAction
};
