import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IAttachmentsTypes } from '@interfaces/attachments-types.interface';

@Injectable({ providedIn: 'root' })
export class AttachmentsTypesService {
  constructor(private httpClient: HttpClient) {}

  getPublic() {
    return this.httpClient.get<IResponse<IAttachmentsTypes[]>>(`${ROUTES_PUBLIC.ATTACHMENTS_TYPES}/true`);
  }

  get() {
    return this.httpClient.get<IResponse<IAttachmentsTypes[]>>(`${ROUTES_PRIVATE.ATTACHMENTS_TYPES}`);
  }

  getOffer(code: string) {
    return this.httpClient.get<IResponse<IAttachmentsTypes[]>>(`${ROUTES_PUBLIC.ATTACHMENTS_OFFER}/${code}`);
  }

  save(data: IAttachmentsTypes) {
    return this.httpClient.post<IResponse<IAttachmentsTypes>>(`${ROUTES_PRIVATE.ATTACHMENTS_TYPES}`, data);
  }

  update(data: IAttachmentsTypes) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IAttachmentsTypes>>(`${ROUTES_PRIVATE.ATTACHMENTS_TYPES}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IAttachmentsTypes>>(`${ROUTES_PRIVATE.ATTACHMENTS_TYPES}/${id}`);
  }
}
