import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ITypeIdentity } from '@interfaces/type-citizen.interface';
import { TypeIdentityGetPublicAction } from './type-identity-action';
import { TypeIdentityService } from '@services/type-identity.service';

export interface TypeIdentityStateModel {
  list: ITypeIdentity[];
}

export const TypeIdentityModel = 'type_identity';

@State<TypeIdentityStateModel>({
  name: TypeIdentityModel,
  defaults: {
    list: []
  }
})
@Injectable()
export class TypeIdentityState {
  @Selector() static list(state: TypeIdentityStateModel) {
    return state.list;
  }

  constructor(private typeIdentityService: TypeIdentityService) {}

  @Action(TypeIdentityGetPublicAction)
  TypeIdentityGetPublic(ctx: StateContext<TypeIdentityStateModel>) {
    return this.typeIdentityService.gettypeIdentity().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }
}
