import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { SetOptionMenuViewAction, SetPageTitleAction } from '@redux/layout/layout-actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly defaultMsg = 'Cargando...';
  processCurrent = [];
  textCurrent;

  @Select(state => state.process.list) listProcess$: Observable<object>;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private store: Store
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.titleService.setTitle(event['title']);
        this.store.dispatch(new SetPageTitleAction(event['title']));
        this.store.dispatch(new SetOptionMenuViewAction(event?.option ?? ''));
      });
    this.listProcess$.subscribe(async(process: object[]) => {
      if (this.processCurrent.length === 0 && process.length > 0) {
        this.spinner.show();
      } else if (this.processCurrent.length > 0 && process.length === 0) {
        this.spinner.hide();
      }
      this.setCurrent(process ? process : []);
    });
  }

  setCurrent(process: object[]) {
    this.processCurrent = process;
    this.textCurrent = process.length > 0 ?
    (process[0]['text'] ? process[0]['text'] : this.defaultMsg) : this.defaultMsg;
  }
}
