export class NewProcessAction {
  static readonly type = '[Process] New Process';
  constructor(public code: string) {}
}

export class EndProcessAction {
  static readonly type = '[Process] End Process';
  constructor(
    public code: string,
    public success: boolean
  ) {}
}
