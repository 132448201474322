import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  UserWorksAddAction,
  UserWorksEditAction,
  UserWorksGetAction,
  UserWorksRemoveAction,
  UserWorksUpdateAction
} from './user-works-actions';
import { Injectable } from '@angular/core';
import { UserWorksService } from '@services/user/user-works.service';
import { IUserWorks } from '@interfaces/user/user-works';

export interface UserWorksStateModel {
  list: IUserWorks[];
  edit: IUserWorks;
}

export const StateUserWorks = 'user_works';

@State<UserWorksStateModel>({
  name: StateUserWorks,
  defaults: {
    list: [],
    edit: {}
  }
})
@Injectable()
export class UserWorksState {
  @Selector() static listWorksActive(state: UserWorksStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static editWork(state: UserWorksStateModel) {
    return state.edit;
  }

  constructor(private userWorksService: UserWorksService) {}

  @Action(UserWorksGetAction)
  UserWorksGet(ctx: StateContext<UserWorksStateModel>) {
    return this.userWorksService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(UserWorksEditAction)
  UserWorksEdit(ctx: StateContext<UserWorksStateModel>, { data }: UserWorksEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(UserWorksAddAction)
  UserWorksAdd(ctx: StateContext<UserWorksStateModel>, { data }: UserWorksAddAction) {
    return this.userWorksService.save(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(UserWorksUpdateAction)
  UserWorksUpdate(ctx: StateContext<UserWorksStateModel>, { data }: UserWorksUpdateAction) {
    return this.userWorksService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: {}
        });
      })
    );
  }

  @Action(UserWorksRemoveAction)
  UserWorksRemove(ctx: StateContext<UserWorksStateModel>, { id }: UserWorksRemoveAction) {
    return this.userWorksService.remove(id).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
