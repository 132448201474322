import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  ClearEmployeesGetAction,
  EmployeesAddTermedAction,
  EmployeesEditTermedAction,
  EmployeesGetAction,
  EmployeesUpdateTermedAction,
  UploadFileAddAction,
  UploadFileClearMessageAction
} from './employees-actions';
import { Injectable } from '@angular/core';
import { EmployeesService } from '@services/employees.service';
import { IEmployeesData } from '@interfaces/employees.interface';
import { IPaginateData, IPaginateResponse } from '@interfaces/api-paginate.interface';

export interface EmployeeStateModel {
  edit: IEmployeesData;
  list_search: IPaginateData<IEmployeesData[]>;
  messageUpload: string;
}

export const StateEmployees = 'employees';

const defaults = {
  list: [],
  show_disable: false,
  edit: null,
  list_search: null,
  messageUpload: null
};

@State<EmployeeStateModel>({
  name: StateEmployees,
  defaults
})
@Injectable()
export class EmployeesState {
  @Selector() static employeesSearch(state: EmployeeStateModel): IPaginateData<IEmployeesData[]> {
    return state.list_search;
  }

  @Selector() static messageUpload(state: EmployeeStateModel) {
    return state.messageUpload;
  }

  @Selector()
  static edit(state: EmployeeStateModel) {
    return state.edit;
  }

  constructor(private employeesService: EmployeesService) {}

  @Action(EmployeesGetAction)
  EmployeesGetAction(ctx: StateContext<EmployeeStateModel>, {data, url, page}) {
    return this.employeesService
      .getEmployeesSearch(data, url, page)
      .pipe(
        tap((result: IPaginateResponse<IEmployeesData[]>): void => {
          const {data: paginateData}: { data: IPaginateData<IEmployeesData[]> } = result;
          ctx.patchState({list_search: paginateData});
        })
      );
  }

  @Action(EmployeesEditTermedAction)
  EmployeesEditTermedAction(ctx: StateContext<EmployeeStateModel>, { data }: EmployeesEditTermedAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(EmployeesAddTermedAction)
  EmployeesAddTermedAction(ctx: StateContext<EmployeeStateModel>, { data }: EmployeesAddTermedAction) {
    return this.employeesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(EmployeesUpdateTermedAction)
  ParametersUpdateAction(ctx: StateContext<EmployeeStateModel>, { data }: EmployeesUpdateTermedAction) {
    return this.employeesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(ClearEmployeesGetAction)
  StateClear(ctx: StateContext<ClearEmployeesGetAction>) {
    ctx.setState(defaults);
  }

  @Action(UploadFileAddAction)
  UploadFileAddAction(ctx: StateContext<EmployeeStateModel>, { data }) {
    return this.employeesService.postUploadFile(data).pipe(
      tap(result => {
        ctx.patchState({
          messageUpload: result.message
        });
      })
    );
  }

  @Action(UploadFileClearMessageAction)
  UploadFileClearMessageAction(ctx: StateContext<EmployeeStateModel>) {
    ctx.patchState({
      messageUpload: null
    });
  }
}
