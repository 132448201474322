<ng-container *ngIf="(medicalCompanies$ | async).length">
  <section class="d-flex flex-column justify-content-between align-items-center h-100">
    <div class="container d-flex flex-wrap justify-content-center gap-3 p-2" >
      <div class="card shadow-sm custom-wrapper-card"  *ngFor="let company of medicalCompanies$ | async "
      [routerLink]="[generateLink(company.code)]">
        <div class="card-title ps-4 pe-4 pt-3 fw-bolder text-primary fs-5 custom-title-card">{{ company.name }}</div>
        <div class="wrapper-image ps-4 pe-4">
          <img [src]="company.logo" class="card-img-top card-image" alt="...">
        </div>
        <div class="card-body wrapper-description-card">
          <div class="card-subtitle mb-2 text-body-secondary p-2">{{ company.address }}.</div>
          <!--        <p class="card-text" style="height: 3rem">{{ company.address }}.</p>-->
        </div>
      </div>
    </div>

    <a
      class="text-muted mb-3"
      [routerLink]="['medical-record/consulting']">
      Do you have an assigned number to consult your medical report? Click
      here.
    </a>
  </section>
</ng-container>
