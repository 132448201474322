import { IUserWorks } from '@interfaces/user/user-works';

export class UserWorksGetAction {
  static readonly type = '[UserWorks] List';
}

export class UserWorksAddAction {
  static readonly type = '[UserWorks] Add';
  constructor(public data: IUserWorks) {}
}

export class UserWorksEditAction {
  static readonly type = '[UserWorks] Edit';
  constructor(public data: IUserWorks) {}
}

export class UserWorksUpdateAction {
  static readonly type = '[UserWorks] Update';
  constructor(public data: IUserWorks) {}
}

export class UserWorksRemoveAction {
  static readonly type = '[UserWorks] Remove';
  constructor(public id: number) {}
}

export class UserWorksRequestSaveAction {
  static readonly type = '[UserWorks] Request save';
}

export const ActionsToProcessUserWorks = {
  UserWorksGetAction,
  UserWorksAddAction,
  UserWorksUpdateAction,
  UserWorksRemoveAction
};
