import { Routes } from '@angular/router';
import { routes as appRoutes } from '@routes/app.routes';
import { routes as medicalRoutes } from '@routes/medical-report.routes';
import { getSubdomain } from '@helpers/helper';
import { Subdomain } from '@data/subdomains';

export class AppRoutes {
  static loadRoutes(): Routes {
    return getSubdomain() === Subdomain.MedicalReport ? medicalRoutes : appRoutes;
  }
}
