import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  PermissionsGetAction,
  PermissionsEditAction,
  PermissionsAddAction,
  PermissionsUpdateAction,
  PermissionsRemoveAction,
  PermissionsSetShowDisabledAction
} from './permissions-actions';
import { Injectable } from '@angular/core';
import { PermissionsService } from '@services/security/permissions.service';
import { IPermissions } from '@interfaces/security/permissions.interface';

export interface PermissionsStateModel {
  list: IPermissions[];
  show_disable: boolean;
  edit: IPermissions;
}

export const StatePermissions = 'permissions';

@State<PermissionsStateModel>({
  name: StatePermissions,
  defaults: {
    list: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class PermissionsState {
  @Selector() static list(state: PermissionsStateModel) {
    return state.list;
  }
  @Selector() static listActive(state: PermissionsStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static showDisabled(state: PermissionsStateModel) {
    return state.show_disable;
  }
  @Selector() static edit(state: PermissionsStateModel) {
    return state.edit;
  }

  constructor(private permissionsService: PermissionsService) {}

  @Action(PermissionsGetAction)
  PermissionsGet(ctx: StateContext<PermissionsStateModel>) {
    return this.permissionsService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(PermissionsEditAction)
  PermissionsEdit(ctx: StateContext<PermissionsStateModel>, { data }: PermissionsEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(PermissionsAddAction)
  PermissionsAdd(ctx: StateContext<PermissionsStateModel>, { data }: PermissionsAddAction) {
    return this.permissionsService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(PermissionsUpdateAction)
  PermissionsUpdate(ctx: StateContext<PermissionsStateModel>, { data }: PermissionsUpdateAction) {
    return this.permissionsService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(PermissionsRemoveAction)
  PermissionsRemove(ctx: StateContext<PermissionsStateModel>, { id }: PermissionsRemoveAction) {
    return this.permissionsService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(PermissionsSetShowDisabledAction)
  PermissionsSetShowDisabled(ctx: StateContext<PermissionsStateModel>, { show }: PermissionsSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }
}
