import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IUserWorks } from '@interfaces/user/user-works';

@Injectable({ providedIn: 'root' })
export class UserWorksService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IUserWorks[]>>(`${ROUTES_PUBLIC.USER_WORKS}`);
  }

  save(data: IUserWorks) {
    return this.httpClient.post<IResponse<IUserWorks>>(`${ROUTES_PUBLIC.USER_WORKS}`, data);
  }

  update(data: IUserWorks) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IUserWorks>>(`${ROUTES_PUBLIC.USER_WORKS}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IUserWorks>>(`${ROUTES_PUBLIC.USER_WORKS}/${id}`);
  }
}
