export const StatesLicenses = [
  {
    code: 'AL',
    state_name: 'Alabama'
  },
  {
    code: 'MI',
    state_name: 'Miami'
  },
  {
    code: 'FL',
    state_name: 'Florida'
  },
  {
    code: 'WI',
    state_name: 'Washington'
  },
  {
    code: 'TX',
    state_name: 'Texas'
  },
  {
    code: 'NY',
    state_name: 'NewYork'
  },
  {
    code: 'NV',
    state_name: 'Nevada'
  },
  {
    code: 'CO',
    state_name: 'Colorado'
  },
  {
    code: 'NY',
    state_name: 'NewYork'
  },
  {
    code: 'NV',
    state_name: 'Nevada'
  },
  {
    code: 'CO',
    state_name: 'Colorado'
  }
];
export function GetNameStatesLicenses(code) {
  return StatesLicenses.filter(i => i.code == code)[0]?.state_name ?? '';
}
