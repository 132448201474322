import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  UserLanguagesGetAction,
  UserLanguagesEditAction,
  UserLanguagesAddAction,
  UserLanguagesUpdateAction,
  UserLanguagesRemoveAction
} from './user-languages-actions';
import { Injectable } from '@angular/core';
import { UserLanguagesService } from '@services/user/user-languages.service';
import { IUserLanguages } from '@interfaces/user/user-languages.interface';

export interface UserLanguagesStateModel {
  list: IUserLanguages[];
  edit: IUserLanguages;
}

export const StateUserLanguages = 'user_languages';

@State<UserLanguagesStateModel>({
  name: StateUserLanguages,
  defaults: {
    list: [],
    edit: {}
  }
})
@Injectable()
export class UserLanguagesState {
  @Selector() static listLanguagesActive(state: UserLanguagesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static editLanguage(state: UserLanguagesStateModel) {
    return state.edit;
  }

  constructor(private userLanguagesService: UserLanguagesService) {}

  @Action(UserLanguagesGetAction)
  UserLanguagesGet(ctx: StateContext<UserLanguagesStateModel>) {
    return this.userLanguagesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(UserLanguagesEditAction)
  UserLanguagesEdit(ctx: StateContext<UserLanguagesStateModel>, { data }: UserLanguagesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(UserLanguagesAddAction)
  UserLanguagesAdd(ctx: StateContext<UserLanguagesStateModel>, { data }: UserLanguagesAddAction) {
    return this.userLanguagesService.save(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(UserLanguagesRemoveAction)
  UserLanguagesRemove(ctx: StateContext<UserLanguagesStateModel>, { id }: UserLanguagesRemoveAction) {
    return this.userLanguagesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(UserLanguagesUpdateAction)
  UserLanguagesUpdate(ctx: StateContext<UserLanguagesStateModel>, { data }: UserLanguagesUpdateAction) {
    return this.userLanguagesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: {}
        });
      })
    );
  }
}
