import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IApplicantStatus, IApplicantStatusApply } from '@interfaces/applicant-status.interface';

@Injectable({ providedIn: 'root' })
export class ApplicantStatusService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IApplicantStatus[]>>(`${ROUTES_PRIVATE.APPLICANT_STATUS}`);
  }

  save(data: IApplicantStatus) {
    return this.httpClient.post<IResponse<IApplicantStatus>>(`${ROUTES_PRIVATE.APPLICANT_STATUS}`, data);
  }

  update(data: IApplicantStatus) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IApplicantStatus>>(`${ROUTES_PRIVATE.APPLICANT_STATUS}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IApplicantStatus>>(`${ROUTES_PRIVATE.APPLICANT_STATUS}/${id}`);
  }

  updateStateApply(data: IApplicantStatusApply) {
    return this.httpClient.
    post<IResponse<IApplicantStatus>>(`${ROUTES_PRIVATE.JOBS_APPLICANTS}/updateStatusApplicant`, data);
  }
}
