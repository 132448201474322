import { IPermissionsTypes } from '@interfaces/security/permissions-types.interface';

export class PermissionsTypesGetAction {
  static readonly type = '[PermissionsTypes] List';
}

export class PermissionsTypesAddAction {
  static readonly type = '[PermissionsTypes] Add';
  constructor(public data: IPermissionsTypes) {}
}

export class PermissionsTypesEditAction {
  static readonly type = '[PermissionsTypes] Edit';
  constructor(public data: IPermissionsTypes) {}
}

export class PermissionsTypesUpdateAction {
  static readonly type = '[PermissionsTypes] Update';
  constructor(public data: IPermissionsTypes) {}
}

export class PermissionsTypesRemoveAction {
  static readonly type = '[PermissionsTypes] Remove';
  constructor(public id: number) {}
}

export class PermissionsTypesSetShowDisabledAction {
  static readonly type = '[PermissionsTypes] List disabled';
  constructor(public show: boolean) {}
}

export const ActionsToProcessPermissionsTypes = {
  PermissionsTypesGetAction,
  PermissionsTypesAddAction,
  PermissionsTypesUpdateAction,
  PermissionsTypesRemoveAction
};
