import { IUserPersonalInfo } from '../../../interfaces/user/user-personal-info.interface';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserPersonalInfoService } from '../../../services/user/user-personal-info.service';
import { GetUserPersonalInfoAction, UpdateUserPersonalInfoAction } from './user-personal-info-actions';
import { tap } from 'rxjs/operators';

export interface UserPersonalInfoStateModel {
  userPersonalInfo: IUserPersonalInfo;
}

export const StateUserPersonalInfo = 'user_personal_info';

@State<UserPersonalInfoStateModel>({
  name: StateUserPersonalInfo,
  defaults: {
    userPersonalInfo: {}
  }
})
@Injectable()
export class UserPersonalInfoState {
  constructor(private userPersonalInfoServ: UserPersonalInfoService) {}

  @Selector() static userPersonalInfo(state: UserPersonalInfoStateModel) {
    return state.userPersonalInfo;
  }
  @Action(UpdateUserPersonalInfoAction)
  updateUserPersonalInfo(ctx: StateContext<UserPersonalInfoStateModel>, { payload }: UpdateUserPersonalInfoAction) {
    return this.userPersonalInfoServ.updateUserPersonalInfo(payload).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(GetUserPersonalInfoAction)
  getUserPersonalInfo(ctx: StateContext<UserPersonalInfoStateModel>) {
    return this.userPersonalInfoServ.getUserPersonalInfo().pipe(
      tap(result => {
        ctx.patchState({
          userPersonalInfo: result.data
        });
      })
    );
  }
}
