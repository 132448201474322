import { IApplicants } from '@interfaces/admin-applicants.interface';
import { IAttachmentsSignature } from '@interfaces/attachments-types.interface';
import { IJobs, IJobsSearch, IJobWitchApplicants } from '@interfaces/jobs.interface';
import { IUserApplicationsAdd } from '@interfaces/user/user-applications.interface';

export class JobsGetPublicAction {
  static readonly type = '[PublicJobs] List';
}

export class JobsGetPublicDetailAction {
  static readonly type = '[PublicJobs] Detail';
  constructor(public code: string) {}
}

export class JobsGetPublicApplicationInfoAction {
  static readonly type = '[PublicJobs] Detail Application';
  constructor(public code: string) {}
}

export class JobsGetPublicSearchAction {
  static readonly type = '[PublicJobs] Search';
  constructor(public filters: IJobsSearch) {}
}

export class JobsSendPublicApplyAction {
  static readonly type = '[PublicJobs] Apply';
  constructor(public id: number) {}
}

export class JobsApplyRequestSaveAction {
  static readonly type = '[PublicJobs] Request apply';
}

export class JobsGetAction {
  static readonly type = '[Jobs] List';
}

export class JobsAddAction {
  static readonly type = '[Jobs] Add';
  constructor(public data: IJobs) {}
}

export class JobsEditAction {
  static readonly type = '[Jobs] Edit';
  constructor(public data: IJobs) {}
}

export class JobsUpdateAction {
  static readonly type = '[Jobs] Update';
  constructor(public data: IJobs) {}
}

export class JobsSetFiltersAction {
  static readonly type = '[Jobs] Set Filters Search';
  constructor(public filters: IJobsSearch) {}
}

export class JobsResetApplyAction {
  static readonly type = '[Jobs] Set reset apply';
}

export class JobsGetWitchApplicantsAction {
  static readonly type = '[JobsWitchApplicants] List';
}

export class JobsGetListJobOffersApplicantsAction {
  static readonly type = '[JobListOffersApplicants] Detail Application';
  constructor(public jobId: IJobWitchApplicants) {}
}

export class JobsApplicantsUpdateAction {
  static readonly type = '[JobApplicants] Update';
  constructor(public data: IJobs) {}
}

export class JobsApplicantGetAction {
  static readonly type = '[JobsApplicant] List';
  constructor(public id: number) {}
}

export class JobsApplicantAllGetAction {
  static readonly type = '[JobsApplicantAll] List';
  constructor(public data?: IApplicants) {}
}

export class JobsApplicantsListAction {
  static readonly type = '[JobsApplicantList] List';
  constructor(public data?: IApplicants, public url?: string, public page?: number) {}
}

export class JobsApplicantEditAction {
  static readonly type = '[JobsApplicant] Edit';
  constructor(public data?: IApplicants) {}
}

export class JobsApplicantEditFilterIdAction {
  static readonly type = '[JobsApplicant] Edit id';
  constructor(public userId?: number) {}
}

export class JobsApplicantsAddAction {
  static readonly type = '[JobsApplicabts] Add';
  constructor(public data: IUserApplicationsAdd) {}
}

export class JobsApplicantCodeQrGetAction {
  static readonly type = '[JobsApplicantCodeQr] List';
  constructor(public id: number) {}
}

export class JobsApplicantAsignatureAddAction {
  static readonly type = '[JobsApplicantAsignature] Add';
  constructor(public data: IAttachmentsSignature) {}
}

export class JobsApplicantAsignatureRequestSaveAction {
  static readonly type = '[JobsApplicantAsignature] Request save';
}

export class JobsApplicantAsignatureTemplateRequestSaveAction {
  static readonly type = '[JobsApplicantAsignatureTemplate] Request save';
}

export class JobsApplicantAsignatureReadGetAction {
  static readonly type = '[JobsApplicantAsignatureRead] List';
  constructor(public id: number) {}
}

export class JobsApplicantStatusOfferGetAction {
  static readonly type = '[JobsApplicantStatusOffer] Code';
  constructor(public code: string) {}
}

export class ToggleModalAction {
  static readonly type = '[CloseModal] CloseModal';
  constructor(public openModal: boolean) {}
}
export class JobsGetPendingGetAction {
  static readonly type = '[PendingJobs] List';
}

export class SaveMissingDataAction {
  static readonly type = '[Jobs] Missing Data';
  constructor(public data: any) {}
}

export class JobsApplicantGetTemplatePdfAction {
  static readonly type = '[Jobs TemplatePDF] PDF';
  constructor(public user: number, public template: number) {}
}
export const ActionsToProcessJobs = {
  JobsGetPublicAction,
  JobsGetPublicDetailAction,
  JobsGetPublicApplicationInfoAction,
  JobsGetPublicSearchAction,
  JobsSendPublicApplyAction,
  JobsGetAction,
  JobsAddAction,
  JobsEditAction,
  JobsUpdateAction,
  JobsSetFiltersAction,
  JobsGetListJobOffersApplicantsAction,
  JobsGetWitchApplicantsAction,
  JobsApplicantsUpdateAction,
  JobsApplicantGetAction,
  JobsApplicantAllGetAction,
  JobsApplicantEditAction,
  JobsApplicantsAddAction,
  JobsApplicantCodeQrGetAction,
  JobsApplicantAsignatureAddAction,
  JobsApplicantAsignatureReadGetAction,
  JobsApplicantEditFilterIdAction,
  JobsApplicantsListAction,
  JobsApplicantGetTemplatePdfAction,
  SaveMissingDataAction
};
