import { IMedicalReport } from '@interfaces/medical-report/medical-report.interface';

export class MedicalReportAddAction {
  static readonly type = '[MedicalReportAdd] Add';
  constructor(public data: IMedicalReport) {}
}

export const ActionsToProcessMedicalFormReport = {
  MedicalReportAddAction
};
