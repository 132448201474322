import { IUserReferences } from '@interfaces/user/user-references';

export class UserReferencesGetAction {
  static readonly type = '[UserReferences] List';
}

export class UserReferencesAddAction {
  static readonly type = '[UserReferences] Add';
  constructor(public data: IUserReferences) {}
}

export class UserReferencesEditAction {
  static readonly type = '[UserReferences] Edit';
  constructor(public data: IUserReferences) {}
}

export class UserReferencesUpdateAction {
  static readonly type = '[UserReferences] Update';
  constructor(public data: IUserReferences) {}
}

export class UserReferencesRemoveAction {
  static readonly type = '[UserReferences] Remove';
  constructor(public id: number) {}
}

export class UserReferencesRequestSaveAction {
  static readonly type = '[UserReferences] Request save';
}

export const ActionsToProcessUserReferences = {
  UserReferencesGetAction,
  UserReferencesAddAction,
  UserReferencesUpdateAction,
  UserReferencesRemoveAction
};
