import { Injectable } from '@angular/core';
import { State, StateContext, Action } from '@ngxs/store';
import { LayoutStateModel, SetOptionMenuViewAction, SetPageTitleAction, SidebarView } from './layout-actions';

@State<LayoutStateModel>({
  name: 'layout',
  defaults: {
    sidebar: true,
    option_menu: null,
    name_page: null
  }
})
@Injectable()
export class LayoutState {

  @Action(SidebarView)
  sidebarView(ctx: StateContext<LayoutStateModel>, action: SidebarView) {
    ctx.patchState({
      sidebar: action.view
    });
  }

  @Action(SetOptionMenuViewAction)
  SetOptionMenuView(ctx: StateContext<LayoutStateModel>, { option }: SetOptionMenuViewAction) {
    ctx.patchState({
      option_menu: option
    });
  }

  @Action(SetPageTitleAction)
  SetPageTitle(ctx: StateContext<LayoutStateModel>, { name }: SetPageTitleAction) {
    ctx.patchState({
      name_page: name
    });
  }
}
