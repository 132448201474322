<div class="d-flex flex-column h-100">
  <div>
    <app-ticket-header class="mb-auto"></app-ticket-header>
  </div>
  <div class="flex-fill">
    <router-outlet></router-outlet>
  </div>
  <div>
    <app-ticket-footer></app-ticket-footer>
  </div>
</div>
