import { IPermissionsModules } from '@interfaces/security/permissions-modules.interface';

export class PermissionsModulesGetAction {
  static readonly type = '[PermissionsModules] List';
}

export class PermissionsModulesGetTreeAction {
  static readonly type = '[PermissionsModules] Get Tree';
  constructor(public actions = false) {}
}

export class PermissionsModulesAddAction {
  static readonly type = '[PermissionsModules] Add';
  constructor(public data: IPermissionsModules) {}
}

export class PermissionsModulesEditAction {
  static readonly type = '[PermissionsModules] Edit';
  constructor(public data: IPermissionsModules) {}
}

export class PermissionsModulesUpdateAction {
  static readonly type = '[PermissionsModules] Update';
  constructor(public data: IPermissionsModules) {}
}

export class PermissionsModulesRemoveAction {
  static readonly type = '[PermissionsModules] Remove';
  constructor(public id: number) {}
}

export class PermissionsModulesSetShowDisabledAction {
  static readonly type = '[PermissionsModules] List disabled';
  constructor(public show: boolean) {}
}

export const ActionsToProcessPermissionsModules = {
  PermissionsModulesGetTreeAction,
  PermissionsModulesGetAction,
  PermissionsModulesAddAction,
  PermissionsModulesUpdateAction,
  PermissionsModulesRemoveAction
};
