import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  ApplicantTemplateAction,
  AssignTemplatesAction,
  GetTemplatesByType,
  SetContentDocumentEditor,
  SetCurrentTemplate,
  SetSignatureAction,
  TemplateCompanyDeleteAction,
  TemplateCompanyGetAction,
  TemplateDeleteAction,
  TemplateGetByIdAction,
  TemplatesAddAction,
  TemplatesGetAllAction,
  TemplatesGetByApplicantAction,
  TemplatesTypeAddAction,
  TemplatesTypeGetAllAction,
  TemplatesTypeSetDataAction,
  TemplatesTypeSetShowDisabledAction,
  TemplatesTypeUpdateAction,
  TemplatesUpdateAction
} from './templates-actions';
import { Injectable } from '@angular/core';
import { TemplatesStateModel } from '@interfaces/templates.interface';
import { TemplatesService } from '@services/dynamic-docs/templates.service';

export const StateTemplates = 'templates';

@State<TemplatesStateModel>({
  name: StateTemplates,
  defaults: {
    list: [],
    currentTemplate: null,
    list_types: null,
    show_type_disabled: false,
    currentTemplateType: null,
    list_company_template: null,
    content_document_editor: '',
    template_applicant: null,
    signature: null
  }
})
@Injectable()
export class TemplateState {
  constructor(private templateService: TemplatesService) {}
  @Selector() static listTemplatesActive(state: TemplatesStateModel) {
    return state.list.filter((i) => Number(i.active));
  }
  @Selector() static listTemplatesInactive(state: TemplatesStateModel) {
    return state.list.filter((i) => !Number(i.active));
  }

  @Selector() static currentTemplate(state: TemplatesStateModel) {
    return state.currentTemplate;
  }
  @Selector() static listTemplatesTypesActive(state: TemplatesStateModel) {
    return state.list_types.filter((i) => Number(i.active));
  }
  @Selector() static listTemplatesTypesInactive(state: TemplatesStateModel) {
    return state.list_types.filter((i) => !Number(i.active));
  }
  @Selector() static showTypesDisabled(state: TemplatesStateModel): boolean {
    return state.show_type_disabled;
  }

  @Selector() static currentTemplateType(state: TemplatesStateModel) {
    return state.currentTemplateType;
  }
  @Selector() static listCompanyTemplateActive(state: TemplatesStateModel) {
    return state.list_company_template.filter((i) => Number(i.active));
  }
  @Selector() static getContentDocumentEditor(state: TemplatesStateModel) {
    return state.content_document_editor;
  }
  @Selector() static getApplicantTemplate(state: TemplatesStateModel) {
    return state.template_applicant;
  }
  @Selector() static getSignature(state: TemplatesStateModel) {
    return state.signature;
  }
  @Action(TemplatesAddAction)
  TemplatesAdd(ctx: StateContext<TemplatesStateModel>, { data }: TemplatesAddAction) {
    return this.templateService.save(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
  @Action(TemplatesUpdateAction)
  TemplatesUpdate(ctx: StateContext<TemplatesStateModel>, { data }: TemplatesUpdateAction) {
    return this.templateService.update(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(TemplatesGetAllAction)
  getAllTemplates(ctx: StateContext<TemplatesStateModel>) {
    return this.templateService.getAll().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(TemplateGetByIdAction)
  getTemplateById(ctx: StateContext<TemplatesStateModel>, { id }: TemplateGetByIdAction) {
    return this.templateService.getById(id).pipe(
      tap(result => {
        ctx.patchState({
          currentTemplate: result.data
        });
      })
    );
  }
  @Action(SetCurrentTemplate)
  setTemplateCurrent(ctx: StateContext<TemplatesStateModel>) {
    ctx.patchState({
      currentTemplate: null
    });
  }
  @Action(TemplatesTypeGetAllAction)
  getAllTemplateTypes(ctx: StateContext<TemplatesStateModel>) {
    return this.templateService.getTypesAll().pipe(
      tap(result => {
        ctx.patchState({
          list_types: result.data
        });
      })
    );
  }
  @Action(TemplatesTypeSetShowDisabledAction)
  ParametersTypesSetShowDisabled(ctx: StateContext<TemplatesStateModel>, { show }: TemplatesTypeSetShowDisabledAction) {
    ctx.patchState({
      show_type_disabled: show
    });
  }
  @Action(TemplatesTypeSetDataAction)
  getParameterTypeById(ctx: StateContext<TemplatesStateModel>, { data }: TemplatesTypeSetDataAction) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentTemplateType: data
    });
  }
  @Action(TemplatesTypeAddAction)
  ParameterTypeAdd(ctx: StateContext<TemplatesStateModel>, { data }: TemplatesTypeAddAction) {
    return this.templateService.saveType(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
  @Action(TemplatesTypeUpdateAction)
  ParameterTypeUpdate(ctx: StateContext<TemplatesStateModel>, { data }: TemplatesTypeUpdateAction) {
    return this.templateService.updateType(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(AssignTemplatesAction)
  CompanyAssignTemplates(ctx: StateContext<TemplatesStateModel>, { data }: AssignTemplatesAction) {
    return this.templateService.assignTemplate(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(TemplateCompanyGetAction)
  getAllCompanyTemplates(ctx: StateContext<TemplatesStateModel>, { companyId }: TemplateCompanyGetAction) {
    return this.templateService.getCompanyTemplates(companyId).pipe(
      tap(result => {
        ctx.patchState({
          list_company_template: result.data
        });
      })
    );
  }
  @Action(TemplateCompanyDeleteAction)
  DeleteCompanyTemplates(ctx: StateContext<TemplatesStateModel>, { id }: TemplateCompanyDeleteAction) {
    return this.templateService.deleteCompanyTemplates(id).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
  @Action(TemplateDeleteAction)
  DeleteTemplates(ctx: StateContext<TemplatesStateModel>, { id, data }: TemplateDeleteAction) {
    return this.templateService.deleteTemplates(id, data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
  @Action(SetContentDocumentEditor)
  contentDocumentEditor(ctx: StateContext<TemplatesStateModel>, { data }: SetContentDocumentEditor) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      content_document_editor: data
    });
  }
  @Action(ApplicantTemplateAction)
  contentTemplateApplicant(ctx: StateContext<TemplatesStateModel>, { templateId }: ApplicantTemplateAction) {
    return this.templateService.getApplicantTemplates(templateId).pipe(
      tap(result => {
        ctx.patchState({
          template_applicant: result.data
        });
      })
    );
  }
  @Action(TemplatesGetByApplicantAction)
  getTemplatesByApplicant(ctx: StateContext<TemplatesStateModel>, { id }: TemplatesGetByApplicantAction) {
    return this.templateService.getTemplatesByApplicant(id).pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }
  @Action(SetSignatureAction)
  setSignature(ctx: StateContext<TemplatesStateModel>, { data }: SetSignatureAction) {
    return ctx.patchState({
      signature: data
    });
  }

  @Action(GetTemplatesByType)
  getTemplatesByType(ctx: StateContext<TemplatesStateModel>, { type }: GetTemplatesByType) {
    return this.templateService.getByType(type).pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }
}
