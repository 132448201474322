import { IAttachmentMedicalReport, IMedicalReport,
  INextStatusMedicalReport,
  INoteMedicalReport } from '@interfaces/medical-report/medical-report.interface';

export class MedicalReportAdminGetAction {
  static readonly type = '[MedicalReportAdmin] List';
}

export class MedicalReportAdminEditAction {
  static readonly type = '[MedicalReportAdmin] Edit';
  constructor(public data: IMedicalReport) {}
}

export class MedicalReportIdEditAction {
  static readonly type = '[MedicalReportIdEditAction] Edit';
  constructor(public id: number) {}
}

export class MedicalReportAdminGetIdAction {
  static readonly type = '[MedicalReportAdminId] Get';
  constructor(public id: number) {}
}

export class MedicalReportAdminAttachmentGetIdAction {
  static readonly type = '[MedicalReportAdminAttachmentGetId] Get';
  constructor(public id: number) {}
}

export class MedicalReportAdminUpdateStatusAction {
  static readonly skipError = true;
  static readonly type = '[MedicalReportAdminUpdateStatus] Update';
  constructor(public data: INextStatusMedicalReport) {}
}

export class MedicalReportAdminHistoryAction {
  static readonly type = '[MedicalReportAdminHistory] Get';
  constructor(public id: number) {}
}

export class MedicalReportAdminNoteAddAction {
  static readonly skipError = true;
  static readonly type = '[MedicalReportAdminNote] Add';
  constructor(public data: INoteMedicalReport) {}
}

export class MedicalReportAdminNoteUpdateAction {
  static readonly skipError = true;
  static readonly type = '[MedicalReportAdminNoteUpdateAction] Update';
  constructor(public data: INoteMedicalReport) {}
}

export class MedicalReportAdminNoteDeleteAction {
  static readonly skipError = true;
  static readonly type = '[MedicalReportAdminNoteDeleteAction] Delete';
  constructor(public id: number) {}
}

export class MedicalReportAdminNoteListAction {
  static readonly type = '[MedicalReportAdminNoteListAction] List';
  constructor(public id: number) {}
}

export class MedicalReportAdminUpdateAttachmentIdAction {
  static readonly type = '[MedicalReportAdminUpdateAttachmentId] Update';
  constructor(public data: IAttachmentMedicalReport) {}
}

export class AttachmentReviewByQaIdAction {
  static readonly type = '[AttachmentReviewByQaIdAction] Update';
  constructor(public id: number, public reviewByQa: boolean) {}
}

export class AttachmentReviewReadyToSendIdAction {
  static readonly type = '[AttachmentReviewReadyToSendIdAction] Update';
  constructor(public id: number, public selectedToSend: boolean) {}
}

export class AttachmentToReviewByQaIdAction {
  static readonly type = '[AttachmentToReviewByQaIdAction] Update';
  constructor(public id: number, public toReviewByQa: boolean) {}
}

export class MedicalReportAdminNotarizeAttachmentAction {
  static readonly type = '[MedicalReportAdminNotarizeAttachmentAction] Create';
  constructor(public data: IAttachmentMedicalReport) {}
}

export class MedicalReportAdminCreateAttachmentAction {
  static readonly type = '[MedicalReportAdminCreateAttachmentIdAction] Create';
  constructor(public data: IAttachmentMedicalReport) {}
}

export class MedicalReportAdminCreateMultipleAttachmentAction {
  static readonly skipError = true;
  static readonly type = '[MedicalReportAdminCreateMultipleAttachmentAction] Create';
  constructor(public data: IAttachmentMedicalReport) {}
}

export class MedicalReportAdminDeleteAttachmentAction {
  static readonly type = '[MedicalReportAdminDeleteAttachment] Delete';
  constructor(public id: number) {}
}

export class AttachmentIsNotarizeIdAction {
  static readonly type = '[AttachmentIsNotarizeIdAction] Update';
  constructor(public id: number, public notarize: boolean) {}
}

export class MedicalReportUpdateAction {
  static readonly type = '[MedicalReport] Update';
  constructor(public id: number, public payload: Partial<IMedicalReport>) { }
}

export const ActionsToProcessMedicalReportAdmin = {
  MedicalReportAdminCreateAttachmentAction,
  MedicalReportAdminGetAction,
  MedicalReportAdminUpdateStatusAction,
  MedicalReportAdminHistoryAction,
  MedicalReportAdminDeleteAttachmentAction,
  AttachmentReviewReadyToSendIdAction,
  AttachmentToReviewByQaIdAction,
  AttachmentReviewByQaIdAction,
  MedicalReportAdminCreateMultipleAttachmentAction,
  MedicalReportAdminNoteAddAction,
  MedicalReportUpdateAction,
  AttachmentIsNotarizeIdAction
};
