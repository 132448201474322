import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { ICategories } from '@interfaces/categories.interface';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  constructor(private http: HttpClient) {}

  getPublic() {
    return this.http.get<IResponse<ICategories[]>>(`${ROUTES_PUBLIC.CATEGORIES}`);
  }

  get() {
    return this.http.get<IResponse<ICategories[]>>(`${ROUTES_PRIVATE.CATEGORIES}`);
  }

  save(data: ICategories) {
    delete data.id;
    return this.http.post<IResponse<ICategories>>(`${ROUTES_PRIVATE.CATEGORIES}`, data);
  }

  update(data: ICategories) {
    const id = data.id;
    delete data.id;
    return this.http.put<IResponse<ICategories>>(`${ROUTES_PRIVATE.CATEGORIES}/${id}`, data);
  }
}
