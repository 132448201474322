import { Injectable } from '@angular/core';
import { IResponse } from '@interfaces/api.interface';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IWorkInfo } from '@interfaces/employment/employment-works.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployeeWorkService {
  constructor(private http: HttpClient) {}

  createWork(data: IWorkInfo) {
    return this.http.post<IResponse<IWorkInfo>>(`${ROUTES_PUBLIC.EMPLOYEE_WORK}`, data);
  }

  updateWork(data: IWorkInfo) {
    return this.http.put<IResponse<IWorkInfo>>(`${ROUTES_PUBLIC.EMPLOYEE_WORK}/${data.id}`, data);
  }

  remove(id: number) {
    return this.http.delete<IResponse<IWorkInfo>>(`${ROUTES_PUBLIC.EMPLOYEE_WORK}/${id}`);
  }
}
