import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@redux/auth/auth-state';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';
import { LogoutAction } from '@redux/auth/auth-actions';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    const token: string = this.store.selectSnapshot(AuthState.token);
    let headers = req.clone({
      headers: req.headers
        .set('Content-Type', 'application/json')
        .append('Accept', 'application/json')
        .append('Access-Control-Allow-Origin', '*')
    });
    if (environment?.origin_dev) {
      headers = headers.clone({
        headers: headers.headers.append('origin_dev', environment.origin_dev)
      });
    }
    if (token) {
      headers = headers.clone({
        headers: headers.headers.append('Authorization', 'Bearer ' + token)
      });
    }
    return next.handle(headers).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.store.dispatch(new LogoutAction());
            this.router.navigateByUrl('/');
          }
          return throwError(err);
        }
      })
    );
  }
}
