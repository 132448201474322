import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IPermissions } from '@interfaces/security/permissions.interface';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IPermissions[]>>(`${ROUTES_PRIVATE.PERMISSIONS}`);
  }

  save(data: IPermissions) {
    return this.httpClient.post<IResponse<IPermissions>>(`${ROUTES_PRIVATE.PERMISSIONS}`, data);
  }

  update(data: IPermissions) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IPermissions>>(`${ROUTES_PRIVATE.PERMISSIONS}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IPermissions>>(`${ROUTES_PRIVATE.PERMISSIONS}/${id}`);
  }
}
