import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { UserPersonalInfo }
from '@interfaces/employment/employment-personal-info.interface';
import { IMaritalStatus } from '@interfaces/marital-status.interface';

@Injectable({
  providedIn: 'root'
})
export class MaritalStatusService {

  constructor(private httpClient: HttpClient) {}

  getmaritalStatus() {
    return this.httpClient.get<IResponse<IMaritalStatus[]>>(`${ROUTES_PRIVATE.MARITAL_STATUS}`);
  }

  updateMaritalStatus(employee: UserPersonalInfo) {
    return this.httpClient.post<IResponse<UserPersonalInfo>>(`${ROUTES_PUBLIC.USERPERSONALINFO}`, employee);
  }

}
