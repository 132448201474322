import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IParameterPublic, IParameterValues } from '@interfaces/general-parameters/general-parameter.interface';
import { GeneralParameterService } from '@services/general-parameters/general-parameter.service';
import { GetMedicalReportAttachmentTypes,
  GetMedicalReportShippingMethods,
  GetMedicalReportTypes,
  GetParameters,
  ParametersAddAction,
  ParametersEditAction,
  ParametersSetShowDisabledAction,
  ParametersUpdateAction,
  ParametersValuesAddAction,
  ParametersValuesEditAction,
  ParametersValuesUpdateAction} from './general-parameters.actions';

export interface ParameterPublicModel {
  medicalReportTypes: IParameterPublic;
  medicalReportShippingMethods: IParameterPublic;
  medicalReportAttachmentTypes: IParameterPublic;
  listParameters: IParameterPublic[];
  listParametersValues: IParameterValues[];
  show_disable: boolean;
  editParameterPublic: IParameterPublic;
  editParameterValues: IParameterValues;
}

export const GeneralParameter = 'general_parameter';

@State<ParameterPublicModel>({
  name: GeneralParameter,
  defaults: {
    medicalReportTypes: null,
    medicalReportShippingMethods: null,
    medicalReportAttachmentTypes: null,
    listParameters: [],
    listParametersValues: [],
    show_disable: false,
    editParameterPublic: null,
    editParameterValues: null
  }
})
@Injectable()
export class ParameterPublicState {

  @Selector()
  static edit(state: ParameterPublicModel) {
    return state.editParameterPublic;
  }

  @Selector()
  static editParameterValues(state: ParameterPublicModel) {
    return state.editParameterValues;
  }

  @Selector()
  static generalParameters(state: ParameterPublicModel) {
    return state.listParameters;
  }

  @Selector()
  static generalParameterActive(state: ParameterPublicModel) {
    return state.listParameters.filter(i => i.active);
  }

  @Selector()
  static showDisabled(state: ParameterPublicModel) {
    return state.show_disable;
  }

  @Selector()
  static medicalReportTypes(state: ParameterPublicModel) {
    return state.medicalReportTypes.values.filter((i) => i.active);
  }

  @Selector()
  static medicalReportShippingMethods(state: ParameterPublicModel) {
    return state.medicalReportShippingMethods.values.filter((i) => i.active);
  }

  @Selector()
  static medicalReportAttachmentTypes(state: ParameterPublicModel) {
    return state.medicalReportAttachmentTypes.values.filter((i) => i.active);
  }

  @Selector()
  static generalParametersValues(state: ParameterPublicModel) {
    return state.listParametersValues;
  }

  constructor(private generalParameterService: GeneralParameterService) {}

  @Action(GetMedicalReportTypes)
  getMedicalReportTypes(ctx: StateContext<ParameterPublicModel>, { code, reference}: GetMedicalReportTypes) {
    return this.generalParameterService.getTicketParameters(reference, code).pipe(
      tap(result => {
      ctx.patchState({
        medicalReportTypes: result.data
      });
    }));
  }


  @Action(GetMedicalReportShippingMethods)
  getMedicalReportShippingMethods(
    ctx: StateContext<ParameterPublicModel>,
    {code, reference}: GetMedicalReportShippingMethods) {
    return this.generalParameterService.getTicketParameters(reference, code).pipe(
      tap(result => {
        ctx.patchState({
          medicalReportShippingMethods: result.data
        });
    }));
  }

  @Action(GetMedicalReportAttachmentTypes)
  getMedicalReportAttachmentTypes(
    ctx: StateContext<ParameterPublicModel>,
    {code, reference}: GetMedicalReportAttachmentTypes) {
    return this.generalParameterService.getTicketParameters(reference, code).pipe(
      tap(result => {
        ctx.patchState({
          medicalReportAttachmentTypes: result.data
        });
    }));
  }

  @Action(GetParameters)
  getParameters(
    ctx: StateContext<ParameterPublicModel>) {
    return this.generalParameterService.getParameters().pipe(
      tap(result => {
        ctx.patchState({
          listParameters: result.data
        });
    }));
  }

  @Action(ParametersSetShowDisabledAction)
  ParametersSetShowDisabled(ctx: StateContext<ParameterPublicModel>, { show }: ParametersSetShowDisabledAction) {
    ctx.patchState({
      show_disable: show
    });
  }

  @Action(ParametersEditAction)
  ParametersEdit(ctx: StateContext<ParameterPublicModel>, { data }: ParametersEditAction) {
    ctx.patchState({
      editParameterPublic: data
    });
  }

  @Action(ParametersAddAction)
  SaveParameters(ctx: StateContext<ParameterPublicModel>, { data }: ParametersAddAction) {
    return this.generalParameterService.saveParameters(data).pipe(
      tap(() => {
        ctx.patchState({
          editParameterPublic: null
        });
      })
    );
  }

  @Action(ParametersUpdateAction)
  UpdateParameters(ctx: StateContext<ParameterPublicModel>, { data }: ParametersUpdateAction) {
    return this.generalParameterService.updateParameters(data).pipe(
      tap(() => {
        ctx.patchState({
          editParameterPublic: null
        });
      })
    );
  }

  @Action(ParametersValuesEditAction)
  ParametersValuesEdit(ctx: StateContext<ParameterPublicModel>, { data }: ParametersValuesEditAction) {
    ctx.patchState({
      editParameterValues: data
    });
  }

  @Action(ParametersValuesAddAction)
  SaveParametersValues(ctx: StateContext<ParameterPublicModel>, { data }: ParametersValuesAddAction) {
    return this.generalParameterService.saveParametersValues(data).pipe(
      tap(() => {
        ctx.patchState({
          editParameterPublic: null
        });
      })
    );
  }

  @Action(ParametersValuesUpdateAction)
  UpdateParametersValues(ctx: StateContext<ParameterPublicModel>, { data }: ParametersValuesUpdateAction) {
    return this.generalParameterService.updateParametersValues(data).pipe(
      tap(() => {
        ctx.patchState({
          editParameterPublic: null
        });
      })
    );
  }
}
