import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { IMedicalCompanies } from '@interfaces/medical-report/medical-report-companies.interface';
import { Observable } from 'rxjs/internal/Observable';
import {
  MedicalReportCompaniesGetAction,
  MedicalReportCompaniesState,
  MedicalReportCompaniesClearFormAction
} from '@redux/medical-report';

@Component({
  selector: 'app-medical-record-select-company',
  templateUrl: './medical-record-select-company.component.html',
  styleUrls: ['./medical-record-select-company.component.scss']
})
export class MedicalRecordSelectCompanyComponent implements OnInit {
  @Select(MedicalReportCompaniesState.listMedicalCompaniesActive) medicalCompanies$: Observable<IMedicalCompanies[]>;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new MedicalReportCompaniesGetAction());
    this.store.dispatch(new MedicalReportCompaniesClearFormAction());
  }

  generateLink(companyCode: string) {
    return `medical-record/request/${companyCode}`;
  }
}
