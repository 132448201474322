import { IWorkInfo } from '@interfaces/employment/employment-works.interface';

export class EmployeWorkAddAction {
  static readonly type = '[AddWorkEmploye] Add';
  constructor(public data: IWorkInfo) {}
}

export class EmployeWorkEditAction {
  static readonly type = '[EditEmployeWork] Edit';
  constructor(public data: IWorkInfo) {}
}

export class EmployeWorkUpdateAction {
  static readonly type = '[UpdateEmployeWork] Update';
  constructor(public data: IWorkInfo) {}
}

export class EmployeWorkRequestSaveAction {
  static readonly type = '[EmployeWork] Request save';
}

export class EmployeWorkRemoveAction {
  static readonly type = '[EmployeWork] Remove';
  constructor(public id: number) {}
}

export const ActionsToProcessEmploymentWork = {
  EmployeWorkAddAction,
  EmployeWorkUpdateAction,
  EmployeWorkRemoveAction
};
