import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginData, RegisterData, User } from '@interfaces/auth.interface';
import { Observable } from 'rxjs';
import { ROUTES_AUTH } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IUsers, IUsersAuth } from '@interfaces/security/users.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(data: LoginData): Observable<IResponse<IUsersAuth>> {
    const user: User = {
      email: data.username,
      password: data.password,
      scope: data.scope
    };
    const body = JSON.stringify(user);
    return this.http.post<IResponse<IUsersAuth>>(`${ROUTES_AUTH.LOGIN}`, body);
  }

  signup(data: RegisterData) {
    return this.http.post<IUsers>(`${ROUTES_AUTH.SIGNUP}`, data);
  }

  info() {
    return this.http.get<IUsers>(`${ROUTES_AUTH.INFO}`);
  }

  unsubscribe(token) {
    return this.http.get<IResponse<string>>(`${ROUTES_AUTH.UNSUBSCRIBE}/${token}`);
  }

  send_email_verify() {
    return this.http.get<IResponse<string>>(`${ROUTES_AUTH.SEND_EMAIL_VERIFY}`);
  }

  verify_email(token) {
    return this.http.get<IResponse<string>>(`${ROUTES_AUTH.VERIFY_EMAIL}/${token}`);
  }

  request_change_password(email) {
    return this.http.get<IResponse<string>>(`${ROUTES_AUTH.REQUEST_CHANGE_PASSWORD}/${email}`);
  }

  change_password(data, token) {
    return this.http.post<IResponse<string>>(`${ROUTES_AUTH.CHANGE_PASSWORD_PUBLIC}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + token
      }
    });
  }

  login_email(loginEmail: string) {
    return this.http.get<IResponse<string>>(`${ROUTES_AUTH.LOGIN_EMAIL}/${loginEmail}`);
  }

  auth_token_login(token: string): Observable<IResponse<IUsersAuth>> {
    return this.http.post<IResponse<IUsersAuth>>(`${ROUTES_AUTH.TOKEN_LOGIN}`, { token });
  }
}
